import React from 'react'
import { activeCompanyContext, ActiveCompanyContextValue } from '../context/activeCompanyContext'

export const useActiveCompanyIdOrNull = () => {
  const contextValue = React.useContext(activeCompanyContext)
  if (!contextValue) {
    throw new Error('Must be called within a descendant of ActiveCompanyProvider')
  }
  return contextValue
}

export const useActiveCompanyId = () => {
  const value = useActiveCompanyIdOrNull()
  if (value.activeCompanyId == null) {
    throw new Error('Active company is not set')
  }
  return value as ActiveCompanyContextValue & { activeCompanyId: string }
}
