import { useAuth0 } from '@auth0/auth0-react'
import { ConfiguredApolloProvider, ConfiguredApolloProviderProps } from '@mm/graphql-helpers'
import React, { useCallback } from 'react'

export const AuthenticatedApolloProvider = (props: Omit<ConfiguredApolloProviderProps, 'getAccessToken'>) => {
  const { getAccessTokenSilently } = useAuth0()
  const getAccessToken = useCallback(
    () =>
      getAccessTokenSilently({
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
      }),
    [getAccessTokenSilently],
  )
  return <ConfiguredApolloProvider getAccessToken={getAccessToken} {...props} />
}
