import { useFloatingTree } from '@floating-ui/react'
import { useCallback } from 'react'

export type UseFloatingTreeNodeProps = {
  onOpenChange: (open: boolean) => void
}

export const useFloatingTreeNode = ({ onOpenChange }: UseFloatingTreeNodeProps) => {
  const tree = useFloatingTree()
  if (tree == null) {
    throw new Error('must be rendered inside FloatingTree')
  }

  // Stopped bubbing of close events for now. See T-2718
  // useEffect(() => {
  //   const hideThis = () => {
  //     onOpenChange(false)
  //   }
  //   tree.events.on('hideAll', hideThis)
  //   return () => {
  //     tree.events.off('hideAll', hideThis)
  //   }
  // }, [tree, onOpenChange])

  const hide = useCallback(() => {
    // tree.events.emit('hideAll')
    onOpenChange(false)
  }, [onOpenChange])

  return { tree, hide }
}
