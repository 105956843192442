import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const SingleUserIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="single-user" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.87364 3.55519C7.42832 3.91464 7.17066 4.3639 7.02761 4.71186C6.93067 4.94763 6.90001 5.24239 6.90001 5.66211V7.83334C6.90001 8.59925 7.26061 9.32046 7.87334 9.78001L8.03025 9.89769C8.57777 10.3083 8.90001 10.9528 8.90001 11.6372C8.90001 12.5263 8.35869 13.3259 7.53316 13.6561L4.59148 14.8327C4.17385 14.9998 3.90001 15.4043 3.90001 15.8541V18C3.90001 18.4971 3.49706 18.9 3.00001 18.9C2.50295 18.9 2.10001 18.4971 2.10001 18V15.8541C2.10001 14.6682 2.82196 13.6019 3.92297 13.1615L6.86466 11.9848C7.0068 11.928 7.10001 11.7903 7.10001 11.6372C7.10001 11.5194 7.04452 11.4084 6.95025 11.3377L6.79334 11.22C5.72736 10.4205 5.10001 9.16581 5.10001 7.83334V5.66211C5.10001 5.20053 5.12464 4.60674 5.36281 4.02742C5.5815 3.49549 5.99304 2.75994 6.74309 2.15453C7.5076 1.53745 8.56997 1.10001 10 1.10001C11.43 1.10001 12.4924 1.53745 13.2569 2.15453C14.007 2.75994 14.4185 3.49549 14.6372 4.02742C14.8754 4.60674 14.9 5.20053 14.9 5.66211V7.83334C14.9 9.16581 14.2727 10.4205 13.2067 11.22L13.0498 11.3377C12.9555 11.4084 12.9 11.5194 12.9 11.6372C12.9 11.7903 12.9932 11.928 13.1354 11.9848L16.077 13.1615C17.178 13.6019 17.9 14.6682 17.9 15.8541V18C17.9 18.4971 17.4971 18.9 17 18.9C16.5029 18.9 16.1 18.4971 16.1 18V15.8541C16.1 15.4043 15.8262 14.9998 15.4085 14.8327L12.4668 13.6561C11.6413 13.3259 11.1 12.5263 11.1 11.6372C11.1 10.9528 11.4222 10.3083 11.9698 9.89769L12.1267 9.78001C12.7394 9.32046 13.1 8.59925 13.1 7.83334V5.66211C13.1 5.24239 13.0693 4.94763 12.9724 4.71186C12.8294 4.3639 12.5717 3.91464 12.1264 3.55519C11.6955 3.20744 11.0364 2.90001 10 2.90001C8.96365 2.90001 8.30448 3.20744 7.87364 3.55519Z"
    />
  </IconBase>
)
