import { EditablePlugin, EditableProps } from '@mm/company-ui'
import React from 'react'
import { useMentionsConfig } from './useMentionsConfig'

export const EditableMentionsPlugin: EditablePlugin = (render) => {
  return <EditableMentionsPluginComponent render={render} />
}

type EditableMentionsPluginComponentProps = {
  render: (props: EditableProps) => React.ReactElement
}
const EditableMentionsPluginComponent = ({ render }: EditableMentionsPluginComponentProps) => {
  const mentions = useMentionsConfig()
  return render({ mentions })
}
