import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'

type Booleanish = boolean | 'true' | 'false'

export type OptionalIconBaseProps = {
  className?: string
  onClick?: React.MouseEventHandler<SVGSVGElement>
  style?: React.CSSProperties
  color?: string
  children?: React.ReactNode
  width?: string | number
  height?: string | number
  display?: string
  focusable?: Booleanish | 'auto'
  sx?: ThemeUIStyleObject
}

export type IconBaseProps = OptionalIconBaseProps & {
  name: string
  viewBox: string
}

export const IconBase = ({
  name,
  children,
  color = 'currentColor',
  width = '16px',
  height = '16px',
  display,
  ...restProps
}: IconBaseProps) => {
  return (
    <svg
      sx={{
        fill: color,
        stroke: color,
        strokeWidth: 0,
        width,
        minWidth: width, // so that it is not affected by flex shrink and similar mechanics
        height,
        minHeight: height, // so that it is not affected by flex shrink and similar mechanics
        display,
        ...restProps.sx,
      }}
      role="img"
      data-icon={name}
      {...restProps}
    >
      {children}
    </svg>
  )
}
