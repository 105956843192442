import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { SingleUserIcon } from '@mm/company-ui-icons'
import React from 'react'
import { css } from 'theme-ui'
import { variant } from '../../helpers'
import { Flex } from '../Flex'
import { Tooltip } from '../Tooltip'
import { generateBackgroundTextColor, getInitialsFromName } from './helpers'

export type AvatarProps = {
  name: string
  mediaUrl?: string
  icon?: React.ReactElement
  backgroundColor?: string
  size?: 'small' | 'medium' | 'large' | 'adaptive'
  className?: string
  'data-testid'?: string
  style?: React.CSSProperties
  containerProps?: React.ComponentPropsWithoutRef<'div'>
  status?: 'invalid' | 'empty'
  outlineOnHover?: boolean
  haveTooltip?: boolean
}

type AvatarBaseProps = {
  textColor?: string
  backgroundColor: string
  sizeVariant: AvatarProps['size']
  statusVariant?: AvatarProps['status']
  outlineOnHover?: boolean
}

export const Initials = styled.span(
  css({
    fontWeight: 'body',
    fontStyle: 'initial',
    fontFamily: 'body',
    lineHeight: 'body',
    fontSize: 0,
  }),
)

export const AvatarBase = styled('div', { shouldForwardProp: isPropValid })<AvatarBaseProps>(
  ({ backgroundColor, textColor, outlineOnHover }) =>
    css({
      display: 'flex',
      flex: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      position: 'relative',
      textAlign: 'center',
      cursor: 'pointer',
      backgroundColor: backgroundColor,
      borderRadius: 'full',
      color: textColor,
      whiteSpace: 'nowrap',

      border: '2px solid transparent',
      transition: '150ms border-color ease-in-out',

      // TODO: Create AvatarInput component that controls selecting an avatar instead of this excape hatch
      ':hover, :focus': outlineOnHover && {
        border: '2px solid',
        borderColor: 'accent-background-medium',
      },
    }),

  variant({
    prop: 'sizeVariant',
    variants: {
      large: {
        height: 4,
        width: 4,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        [`${Initials}`]: {
          fontSize: 1,
        },
      },
      medium: {
        height: 3,
        width: 3,
      },
      small: {
        height: '20px',
        width: '20px',
      },
      adaptive: {
        height: '1em',
        width: '1em',
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        [`${Initials}`]: {
          fontSize: '0.5em',
        },
      },
    },
  }),
  ({ outlineOnHover }) =>
    variant({
      prop: 'statusVariant',
      variants: {
        invalid: {
          border: '1px solid',
          borderColor: 'system-text-warning',
          color: 'system-text-warning',

          // TODO: Create AvatarInput component that controls selecting an avatar instead of this excape hatch
          ':hover, :focus': outlineOnHover && {
            border: '2px solid',
            borderColor: 'system-text-warning',
          },
        },
        empty: {
          border: '1px dashed',
          borderColor: 'text-light',
          color: 'text-light',

          // TODO: Create AvatarInput component that controls selecting an avatar instead of this excape hatch
          ':hover, :focus': outlineOnHover && {
            border: '2px solid',
            borderColor: 'accent-background-medium',
            color: 'accent-text',
          },
        },
      },
    }),
)

const AvatarMedia = styled('img')(
  css({
    width: '100%',
    height: '100%',
    textAlign: 'center',
    objectFit: 'cover',
    color: 'transparent',
    position: 'absolute',
  }),
)

export const Avatar = ({
  backgroundColor,
  size = 'medium',
  status,
  name,
  mediaUrl,
  icon,
  className,
  containerProps,
  haveTooltip = true,
  ...props
}: AvatarProps) => {
  const renderAvatarContent = () => {
    if (icon && mediaUrl) {
      // eslint-disable-next-line no-console
      console.warn('Avatar component will override icon prop if mediaUrl prop is also defined')
    }

    const initials = <Initials>{getInitialsFromName(name)}</Initials>

    if (icon && !mediaUrl) {
      return (
        <Flex className="icon" sx={{ fontSize: size === 'small' ? 0 : 1 }}>
          {icon}
        </Flex>
      )
    }

    if (mediaUrl) {
      return (
        <>
          <AvatarMedia
            src={mediaUrl}
            alt={name}
            onError={(e) => {
              e.currentTarget.style.setProperty('display', 'none')
            }}
            className={className}
          />
          {initials}
        </>
      )
    }

    if (status) {
      return (
        <Flex className="icon" sx={{ fontSize: size === 'small' ? 0 : 1 }}>
          <SingleUserIcon />
        </Flex>
      )
    }

    return initials
  }
  const defaultColors = generateBackgroundTextColor(name)
  const avatarBase = (
    <AvatarBase
      aria-label={name}
      sizeVariant={size}
      statusVariant={status}
      className={className}
      backgroundColor={backgroundColor || defaultColors.backgroundColor}
      textColor={defaultColors.textColor}
      {...containerProps}
      {...props}
    >
      {renderAvatarContent()}
    </AvatarBase>
  )
  if (haveTooltip) {
    return (
      <Tooltip description={name} position="bottom">
        {avatarBase}
      </Tooltip>
    )
  }
  return avatarBase
}
