import { LoadingIcon } from '@mm/company-ui-icons'
import React, { useMemo } from 'react'
import { ZIndexLayer } from '../../ZIndexLayer'

export type ModalContextValue = {
  showModal: (render: (props: { onRequestClose: () => void }) => React.ReactElement) => void
}

const context = React.createContext<ModalContextValue>({
  showModal: () => {
    // do nothing
  },
})

export type ModalProviderProps = {
  children?: React.ReactNode
}

let modalCounter = 0 // could use uuid instead...

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [activeModals, setActiveModals] = React.useState<
    Array<{
      key: React.Key
      modal: React.ReactElement
    }>
  >([])

  const value = useMemo<ModalContextValue>(
    () => ({
      showModal: (render) => {
        const key = modalCounter++
        setActiveModals((modals) => [
          ...modals,
          {
            key,
            modal: render({
              onRequestClose: () => {
                setActiveModals((modals) => modals.filter((other) => other.key !== key))
              },
            }),
          },
        ])
      },
    }),
    [],
  )

  return (
    <context.Provider value={value}>
      {children}
      {activeModals.map(({ key, modal }) => (
        <React.Suspense
          key={key}
          fallback={
            <div
              sx={{
                position: 'fixed',
                display: 'flex',
                inset: 0,
                zIndex: ZIndexLayer.LAYER_999_INFINITY,
                bg: 'background-light-alpha',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LoadingIcon />
            </div>
          }
        >
          {modal}
        </React.Suspense>
      ))}
    </context.Provider>
  )
}

export const useModal = () => React.useContext(context)
