import { formatUserDisplayName } from '@mm/company-ui/src'
import { Router } from 'next/router'
import { posthog } from 'posthog-js'
import React, { useEffect } from 'react'
import { useActiveCompanyIdOrNull } from '../../companies'
import { useActiveUser } from '../../users'

type AnalyticsProviderProps = {
  children?: React.ReactNode
  appVersion: string
}

export function AnalyticsProvider({ children, appVersion }: AnalyticsProviderProps) {
  const { activeCompanyId } = useActiveCompanyIdOrNull()
  const user = useActiveUser()

  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_TOKEN, {
      api_host: 'https://app.posthog.com',
      autocapture: false,
      session_recording: {
        maskTextSelector: '.ProseMirror',
      },
    })
    posthog.register({ env: process.env.NODE_ENV })

    // Track page views
    const handleRouteChange = () => posthog.capture('$pageview')
    Router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    if (activeCompanyId) {
      posthog.group('company', activeCompanyId)
      posthog.register({ activeCompanyId })
    } else {
      posthog.resetGroups()
    }
  }, [activeCompanyId])

  // We identify the user after the company is set so that we can fire the app loaded event within the context of of the company group
  useEffect(() => {
    if (user) {
      posthog.identify(user.id, {
        email: user.email,
        name: formatUserDisplayName(user),
      })
    } else {
      posthog.reset()
    }
  }, [user])

  useEffect(() => {
    posthog.capture('App Loaded', { $set: { clientAppVersion: appVersion } })
  }, [appVersion])

  return <>{children}</>
}
