import { Theme } from '@emotion/react'
import _ from 'lodash'
import React from 'react'
import { createPortal } from 'react-dom'
import { css, ThemeUIStyleObject } from 'theme-ui'

type PortalProps = {
  children?: React.ReactNode
}

type RGB = [r: number, g: number, b: number]

export const hexToRgb = (hex: string): RGB | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) || []
  const [, r, g, b] = result

  return r && g && b ? [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)] : null
}

export const shouldFontBeDark = (backgroundColor: string, theme: Theme) => {
  const hexBgColor = themeColorNameToHex(backgroundColor, theme) || backgroundColor
  const color = hexToRgb(hexBgColor)

  if (color != null) {
    const [r, g, b] = color

    const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000)

    return brightness > 125
  }

  return null
}

export const themeColorNameToHex = (color: string | undefined, theme: Theme) => {
  return color ? (_.get(theme.colors, color) as string) : null
}

export const Portal = ({ children }: PortalProps) => {
  return createPortal(children, document.body)
}

export const variant = <P extends string, V extends Record<string, ThemeUIStyleObject>>({
  prop,
  variants,
}: {
  prop: P
  variants: V
}) => {
  return (props: Partial<Record<P, keyof V>>) => {
    const propValue = props[prop]
    if (propValue == null) {
      return {}
    }
    return css(variants[propValue as keyof V])
  }
}
