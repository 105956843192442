import React, { Key, useCallback } from 'react'
import { AriaListBoxOptions, useListBox } from 'react-aria'
import { ListState, SingleSelectListState } from 'react-stately'
import { Flex } from '../../Flex'
import { Text } from '../../Text'
import { UserSelectItem } from '../types'
import { UserSelectOption } from './UserSelectOption'

type UserSelectListProps = {
  listBoxRef: React.RefObject<HTMLUListElement>
  state: SingleSelectListState<UserSelectItem>
} & AriaListBoxOptions<UserSelectItem>

export function UserSelectList({ listBoxRef, state, ...props }: UserSelectListProps) {
  const { listBoxProps } = useListBox(props, state, listBoxRef)
  const displayItems = [...state.collection]

  return (
    <ul
      {...listBoxProps}
      ref={listBoxRef}
      sx={{
        padding: 0,
        margin: 0,
        listStyle: 'none',
        maxHeight: 260,
        overflowY: 'auto',
        py: 1,
        bg: 'background-light',
      }}
    >
      {displayItems.length ? (
        displayItems.map((item) => <OptionWithState key={item.key} item={item} state={state} />)
      ) : (
        <Flex justify="center" sx={{ marginY: 3 }}>
          <Text color="text-light">No results for this search term.</Text>
        </Flex>
      )}
    </ul>
  )
}

type OptionWithStateProps = {
  item: {
    key: Key
    value: UserSelectItem
  }
  state: ListState<UserSelectItem>
}

function OptionWithState({ item, state }: OptionWithStateProps) {
  // TODO: Figure out why using useOption causes users to have to click twice to blur the user picker.
  // Using an excape hatch for now.
  // const ref = useRef<HTMLLIElement>(null)
  // const { optionProps } = useOption({ key: item.key }, state, ref)

  const isFocused = state.selectionManager.focusedKey === item.key

  const handleClick = useCallback(() => {
    state.selectionManager.setFocusedKey(item.key)
    state.selectionManager.replaceSelection(item.key)
  }, [item.key, state])

  return <UserSelectOption {...item.value} isFocused={isFocused} onClick={handleClick} />
}
