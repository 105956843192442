import React from 'react'
import { Button } from '../Button'
import { Flex } from '../Flex'
import { Text } from '../Text'
import { ToastOptions } from './Toast'

export type ToastBodyProps = ToastOptions & { message: string }

export const ToastBody = ({ message = '', btnTitle, onBtnClick }: ToastBodyProps) => {
  return (
    <Flex justify="space-between" align="center" gap={1}>
      <Text>{message}</Text>
      {btnTitle && (
        <Button size="small" onClick={onBtnClick}>
          {btnTitle}
        </Button>
      )}
    </Flex>
  )
}
