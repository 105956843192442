import { toast } from '@mm/company-ui'
import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useApolloClient } from '../../apollo'
import { logger } from '../helpers'
import { UndoResult } from '../types'

export type CommandCenter = {
  history: Array<UndoResult<any>>
  pushCommandResult: <T>(result: UndoResult<T>) => void
}

export const CommandCenterContext = React.createContext<CommandCenter>({
  history: [],
  pushCommandResult: () => {
    throw new Error('not registered')
  },
})

type CommandCenterProviderProps = {
  children: React.ReactNode
}

export const CommandCenterProvider = ({ children }: CommandCenterProviderProps) => {
  const apolloClient = useApolloClient()
  const [history, setHistory] = React.useState<CommandCenter['history']>([])
  const helpers = React.useMemo(() => ({ apolloClient }), [apolloClient])

  // TODO: Clear history on route change or timeout?
  useHotkeys(
    'cmd+z',
    () => {
      if (history.length) {
        const undoCommand = history.pop()
        if (undoCommand) {
          void undoCommand.execute(undoCommand.args, helpers)
          logger('undo', undoCommand.origin.id, history)
          toast(`Undo: ${undoCommand.origin.name}`)
        }
        setHistory(history)
      }
    },
    // TODO: Implement solution for handling undo when focused on inputs (in particular checkboxes for status)
    // Likely shouldnt use a global solution like below:
    // {
    //   enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'],
    // },
  )

  const value: CommandCenter = {
    history,
    pushCommandResult: (result) => {
      setHistory([...history, result])
    },
  }

  return <CommandCenterContext.Provider value={value}>{children}</CommandCenterContext.Provider>
}
