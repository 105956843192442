import { DEFAULT_COLORS } from './colors'

export const getInitialsFromName = (name: string): string => {
  const [first, last] = name.trim().toUpperCase().split(/\s+/)
  return (first?.[0] ?? '') + (last?.[0] ?? '')
}

export const stringToHslColor = (str: string, s: number, l: number) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  const h = hash % 360
  return `hsl(${h}, ${s}%, ${l}%)`
}

export const generateBackgroundTextColor = (str: string) => {
  // Sum ASCII char codes
  let charSum = 0
  for (let i = 0; i < str.length; i++) {
    charSum += str.charCodeAt(i)
  }
  // There is 20 colors in array so generate between 0 and 19.
  const key = charSum % 20

  // Generate unique random for given value.
  interface GeneratedColorTypes {
    backgroundColor: string
    textColor: string
  }
  return DEFAULT_COLORS[key] as GeneratedColorTypes
}
