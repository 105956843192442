import React from 'react'
import { Flex } from '../Flex'
import { Heading, Text } from '../Text'
import { NotFoundImage } from './NotFoundImage'

export type NotFoundBannerProps = {
  title?: string
  description?: string
}

export const NotFoundBanner = ({
  title = '404. This page has gone missing.',
  description = "The page you're looking for no longer exists. Don't worry it's not your fault. Use the navigation on the left to get back on track.",
}: NotFoundBannerProps) => {
  return (
    <Flex align="center" justify="center" sx={{ height: '100%', width: '100%' }}>
      <Flex column gap={3} align="center" sx={{ width: '500px', p: 2 }}>
        <div sx={{ color: 'text-disabled-medium' }}>
          <NotFoundImage />
        </div>
        <Heading level={4} color="text-light">
          {title}
        </Heading>
        <Text variant="small" color="text-light" sx={{ textAlign: 'center' }}>
          {description}
        </Text>
      </Flex>
    </Flex>
  )
}
