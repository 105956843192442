import { FieldReadFunction } from '@apollo/client'
import { TypePolicies, TypePolicy } from '@apollo/client/cache'
import { utils } from '@mm/common'
import {
  GraphQLNamedType,
  GraphQLSchema,
  isCompositeType,
  isInterfaceType,
  isNonNullType,
  isObjectType,
  isScalarType,
} from 'graphql'
import { getNullableType } from 'graphql/type/definition'
import _ from 'lodash'
import { relayStylePagination } from './relayStylePagination'

const getById =
  (__typename: string): FieldReadFunction =>
  (_, { args, toReference }) =>
    toReference({
      __typename,
      id: args?.['id'] as string,
    })

/**
 * Infers Apollo type policies based on the GraphQL Schema.
 * Conventions are the following:
 * 1. If a root Query field looks like `field(id: ID!): Type` - then this field looks up an object of the specified type
 * by the passed id.
 * 2. If a field on any object type returns a sub-type of Connection interface - it follows the relay pagination
 * specification: https://relay.dev/graphql/connections.htm
 */
export const inferTypePolicies = (schema: GraphQLSchema): TypePolicies => {
  const queryType = schema.getQueryType()
  if (queryType == null) throw new Error('Query not defined')
  const connectionInterface = schema.getType('Connection')
  if (connectionInterface == null || !isInterfaceType(connectionInterface)) {
    throw new Error('Connection interface is not defined')
  }

  const queryFields: TypePolicy['fields'] = _(queryType.getFields())
    .mapValues((field) => {
      const firstArg = field.args[0]
      if (
        field.args.length !== 1 ||
        firstArg?.name !== 'id' ||
        !isNonNullType(firstArg.type) ||
        !isScalarType(firstArg.type.ofType) ||
        firstArg.type.ofType.name !== 'ID'
      ) {
        // make sure there is exactly 1 query parameter id: ID!
        return null
      }

      if (!isObjectType(field.type)) {
        // make sure the return type is object (not union or interface)
        return null
      }

      return field.type.name
    })
    .pickBy(utils.isNonNil)
    .mapValues(getById)
    .value()

  const buildRelayPaginationPolicies = (type: GraphQLNamedType): TypePolicy | undefined => {
    if (!isObjectType(type)) return undefined
    const fields = _(type.getFields())
      .pickBy((field) => {
        const type = getNullableType(field.type)
        return isCompositeType(type) && schema.isSubType(connectionInterface, type)
      })
      .mapValues((field) => {
        const keyArgs = field.args
          .map((arg) => arg.name)
          .filter((argName) => !['first', 'after', 'last', 'before'].includes(argName))
        return relayStylePagination(keyArgs)
      })
      .value()
    if (_.isEmpty(fields)) return undefined
    return { fields }
  }

  const { Query, ...rest } = _(schema.getTypeMap())
    .mapValues(buildRelayPaginationPolicies)
    .pickBy(utils.isNonNil)
    .value()

  return {
    Query: {
      ...Query,
      fields: {
        ...Query?.fields,
        ...queryFields,
      },
    },
    ...rest,
  }
}
