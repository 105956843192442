import { formatUserDisplayName, useFeatureFlags } from '@mm/company-ui/src'
import React, { useEffect } from 'react'
import { useActiveCompanyIdOrNull } from '../../companies'
import Intercom from '../../intercom/components/Intercom'
import { useActiveUser } from '../../users'

type AnalyticsProviderProps = {
  children?: React.ReactNode
}

export function IntercomProvider({ children }: AnalyticsProviderProps) {
  const { activeCompanyId } = useActiveCompanyIdOrNull()
  const { intercomIntegration } = useFeatureFlags()
  const user = useActiveUser()

  // We identify the user after the company is set so that we can fire the intercom boot event within the context of of the company group
  useEffect(() => {
    if (user) {
      // intercom analytics
      if (window.Intercom != null) {
        window.Intercom?.('boot', {
          email: user.email,
          name: formatUserDisplayName(user),
          companyId: activeCompanyId,
        })
      }
    }
  }, [user, activeCompanyId])

  return (
    <>
      {intercomIntegration && user && <Intercom />}
      {children}
    </>
  )
}
