import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const ExclamationOctagonIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="exclamation-octagon" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.59629 0.261858C5.76396 0.0941932 5.99136 0 6.22848 0H13.7715C14.0086 0 14.236 0.0941932 14.4037 0.261858L19.7381 5.59629C19.9058 5.76396 20 5.99136 20 6.22848V13.7715C20 14.0086 19.9058 14.236 19.7381 14.4037L14.4037 19.7381C14.236 19.9058 14.0086 20 13.7715 20H6.22848C5.99136 20 5.76396 19.9058 5.59629 19.7381L0.261858 14.4037C0.0941932 14.236 0 14.0086 0 13.7715V6.22848C0 5.99136 0.0941932 5.76396 0.261858 5.59629L5.59629 0.261858ZM6.5988 1.78808L1.78808 6.5988V13.4012L6.5988 18.2119H13.4012L18.2119 13.4012V6.5988L13.4012 1.78808H6.5988ZM10 4.7666C10.4971 4.7666 10.9 5.16955 10.9 5.6666V10.6666C10.9 11.1637 10.4971 11.5666 10 11.5666C9.50296 11.5666 9.10002 11.1637 9.10002 10.6666V5.6666C9.10002 5.16955 9.50296 4.7666 10 4.7666ZM11.25 14.5833C11.25 15.2737 10.6904 15.8333 10 15.8333C9.30966 15.8333 8.75002 15.2737 8.75002 14.5833C8.75002 13.893 9.30966 13.3333 10 13.3333C10.6904 13.3333 11.25 13.893 11.25 14.5833Z"
    />
  </IconBase>
)
