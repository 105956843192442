import {
  GlobalDecisionsSubscriptionDataFragment,
  GlobalDecisionsSubscriptionDocument,
} from '../../../gen/graphql/documents'
import { useSubscription } from '../../apollo'
import { defineEventBusTopic, useEmitEventBusEvent } from '../../eventbus'

export type DecisionsUpdatedEvent =
  | {
      type: 'deleted'
      id: string
    }
  | {
      type: 'upserted'
      decision: GlobalDecisionsSubscriptionDataFragment
    }

export const DecisionsUpdatedTopic = defineEventBusTopic<DecisionsUpdatedEvent>('decisionsUpdated')

export const GlobalDecisionsSubscription = () => {
  const publish = useEmitEventBusEvent(DecisionsUpdatedTopic)

  useSubscription(GlobalDecisionsSubscriptionDocument, {
    onSubscriptionData: ({ subscriptionData: { data }, client }) => {
      const evictFromCache = (id: string) => {
        publish({ type: 'deleted', id })

        client.cache.evict({
          id: client.cache.identify({ __typename: 'Decision', id }),
        })
        client.cache.gc()
      }

      switch (data?.decisionsUpdated?.__typename) {
        case 'DecisionDeleted':
          evictFromCache(data.decisionsUpdated.deletedDecisionId)
          break
        case 'DecisionAccessRevoked':
          evictFromCache(data.decisionsUpdated.revokedDecisionId)
          break
        case 'Decision':
          publish({ type: 'upserted', decision: data.decisionsUpdated })
          break
      }
    },
  })

  return null
}
