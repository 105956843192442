import React, { useContext, useMemo } from 'react'
import { EditableProps, EditableRef } from './Editable'

export type EditablePlugin = (render: (props: EditableProps) => React.ReactElement) => React.ReactElement

export type EditableConfig = {
  plugins?: EditablePlugin[]
}

export const context = React.createContext<EditableConfig>({})

export const EditablePluginProvider: React.FC<EditableConfig> = ({ children, plugins }) => {
  const value = useMemo(() => ({ plugins }), [plugins])
  return <context.Provider value={value}>{children}</context.Provider>
}

export const withPlugins = (Editable: React.ComponentType<EditableProps & { ref?: React.Ref<EditableRef> }>) => {
  return React.forwardRef<EditableRef, EditableProps>(function EditableWithPlugins(props, ref) {
    const { plugins = [] } = useContext(context)
    const render = plugins.reduce<(props: EditableProps) => React.ReactElement>(
      (render, plugin) => (props) => plugin((extraProps) => render({ ...props, ...extraProps })),
      (props) => <Editable {...props} ref={ref} />,
    )
    return render(props)
  })
}
