import { useEffect } from 'react'

// Disabling the eslint rule here as it's intercom provided script.
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
const Intercom = () => {
  const appId = process.env.NEXT_PUBLIC_INTERCOM_APP_ID
  useEffect(() => {
    if (typeof window !== 'undefined' && appId) {
      window.intercomSettings = {
        app_id: appId,
      }
      ;(function () {
        const w = window
        const ic = w.Intercom
        if (typeof ic === 'function') {
          ic('reattach_activator')
          ic('update', w.intercomSettings)
        } else {
          const d = document
          const i = function () {
            // eslint-disable-next-line prefer-rest-params
            i.c(arguments)
          }
          i.q = [] as any[]
          i.c = function (args: any) {
            i.q.push(args)
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          w.Intercom = i as any
          const l = function () {
            const s = d.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = 'https://widget.intercom.io/widget/' + appId
            const x = d.getElementsByTagName('script')[0]
            if (x) x.parentNode?.insertBefore(s, x)
          }
          if (document.readyState === 'complete') {
            l()
          } else if ((w as any).attachEvent) {
            ;(w as any).attachEvent('onload', l)
          } else {
            w.addEventListener('load', l, false)
          }
        }
      })()
    }

    return () => {
      const intercomScript = document.querySelector('script[src*="intercom"]')
      if (intercomScript) intercomScript.remove()
      if (window.Intercom) window.Intercom('shutdown')
    }
  }, [appId])

  return null
}

export default Intercom
