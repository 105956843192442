import React from 'react'
import { Flex } from '../Flex'
import { Text, Heading } from '../Text'

export type InternalErrorBannerProps = {
  title?: string
  description?: string
}

export const InternalErrorBanner = ({
  title = '500. Internal error.',
  description = "Something went wrong. Don't worry it's not your fault. Use the navigation on the left to get back on track.",
}: InternalErrorBannerProps) => {
  return (
    <Flex align="center" justify="center" sx={{ height: '100%', width: '100%' }}>
      <Flex column gap={3} align="center" sx={{ width: '500px', p: 2 }}>
        <Heading level={4} color="text-light">
          {title}
        </Heading>
        <Text variant="small" color="text-light" sx={{ textAlign: 'center' }}>
          {description}
        </Text>
      </Flex>
    </Flex>
  )
}
