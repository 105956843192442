import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingTree,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { Flex, FloatingPortal, formatUserDisplayName, Popover, PopupWrapper, ZIndexLayer } from '@mm/company-ui'
import { LoadingIcon } from '@mm/company-ui-icons'
import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion'
import invariant from 'invariant'
import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { useLatest } from 'react-use'
import { MentionListDocument, MentionListOptionDataFragment } from '../../../../../gen/graphql/documents'
import { useQuery } from '../../../../apollo'
import { useActiveCompanyId } from '../../../../companies'
import { useActiveResource } from '../../../../hooks/useActiveResource'
import { useMeetingContext } from '../../../../meetings'
import { MentionConfirmationDialog } from './MentionConfirmationDialog'
import { MentionListOptions } from './MentionListOptions'

export type MentionListProps = SuggestionProps<{
  id: string
  label: string
}>

export type MentionListRef = {
  onKeyDown: (event: SuggestionKeyDownProps) => void
}

export const MentionList = forwardRef<MentionListRef, MentionListProps>(
  ({ editor, query, command, clientRect, decorationNode }: MentionListProps, ref) => {
    // data fetching part
    const { activeCompanyId: companyId } = useActiveCompanyId()
    const meeting = useMeetingContext()
    const activeResource = useActiveResource()

    const { data, loading } = useQuery(MentionListDocument, {
      variables: {
        companyId,
        includeMeetingInfo: meeting != null,
        meetingId: meeting?.id ?? '',
      },
    })

    const meetingInfo = data?.meeting
    const items = data?.company?.members.edges ?? []

    // floating ui part
    const nodeId = useFloatingNodeId()
    const {
      x,
      y,
      refs: { setReference, setFloating },
      strategy,
      context,
    } = useFloating({
      nodeId,
      open: clientRect != null,
      middleware: [offset({ mainAxis: 8, crossAxis: -8 }), flip(), shift()],
      placement: 'bottom-start',
      whileElementsMounted: autoUpdate,
    })

    useEffect(() => {
      const rect = clientRect?.()
      if (rect != null) {
        setReference({
          getBoundingClientRect: () => rect,
          contextElement: decorationNode ?? undefined,
        })
      }
    }, [setReference, clientRect, decorationNode])

    const { getFloatingProps } = useInteractions([useRole(context, { role: 'dialog' }), useDismiss(context)])

    // tiptap adapter
    const editorViewRef = useLatest(editor.view.dom)
    const execCommand = useCallback(
      (node: MentionListOptionDataFragment) => {
        command({
          id: node.id,
          label: formatUserDisplayName(node),
        })
      },
      [command],
    )

    // inner state part
    const [confirmingItem, setConfirmingItem] = useState<MentionListOptionDataFragment>()
    const handleItemSelected = useCallback(
      (item: MentionListOptionDataFragment) => {
        if (
          meetingInfo?.privacyLevel === 'PRIVATE' &&
          item.isMeetingParticipant === false &&
          (activeResource ? activeResource.privacy === 'PRIVATE' : true)
        ) {
          setConfirmingItem(item)
        } else {
          execCommand(item)
        }
      },
      [meetingInfo?.privacyLevel, activeResource, execCommand],
    )

    const [dismissed, setDismissed] = useState(false)
    if (dismissed) return null

    return (
      <FloatingTree>
        <FloatingPortal>
          <Popover
            trigger="manual"
            placement="right-start"
            open={confirmingItem != null}
            onOpenChange={(open) => {
              if (!open) {
                setConfirmingItem(undefined)
              }
            }}
            render={({ hide, context }) => {
              invariant(confirmingItem, 'confirmingItem is not defined')
              invariant(meetingInfo, 'meetingInfo is not defined')
              return (
                <FloatingFocusManager context={context} initialFocus={3}>
                  <MentionConfirmationDialog
                    userId={confirmingItem.id}
                    meetingId={meetingInfo.id}
                    onConfirm={() => {
                      execCommand(confirmingItem)
                    }}
                    onCancel={hide}
                  />
                </FloatingFocusManager>
              )
            }}
          >
            {({ getReferenceProps }) => (
              <PopupWrapper
                {...getFloatingProps(
                  getReferenceProps({
                    ref: setFloating,
                    style: {
                      top: y ?? 0,
                      left: x ?? 0,
                      zIndex: ZIndexLayer.LAYER_3_POPOVER,
                    },
                  }),
                )}
                sx={{
                  position: strategy,
                  padding: 0,
                  width: '380px',
                }}
              >
                {items.length === 0 && loading ? (
                  <Flex column align="center" sx={{ py: 3 }}>
                    <LoadingIcon color="text-light" sx={{ alignSelf: 'center' }} />
                  </Flex>
                ) : (
                  <MentionListOptions
                    editorViewRef={editorViewRef}
                    ref={ref}
                    query={query}
                    items={items}
                    onItemSelected={handleItemSelected}
                    onDismiss={() => {
                      setDismissed(true)
                    }}
                  />
                )}
              </PopupWrapper>
            )}
          </Popover>
        </FloatingPortal>
      </FloatingTree>
    )
  },
)

MentionList.displayName = 'MentionList'
