// eslint-disable-next-line no-restricted-imports
import {
  ApolloError,
  DocumentType,
  OperationVariables,
  SubscriptionHookOptions,
  SubscriptionResult,
  TypedDocumentNode,
  useApolloClient,
} from '@apollo/client'
import { verifyDocumentType } from '@apollo/client/react/parser'
import { DocumentNode } from 'graphql'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'

// This is a copy of @apollo/client useSubscription with added support for resubscribing on error
export function useSubscription<TData = any, TVariables extends OperationVariables = Record<string, unknown>>(
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<TData, TVariables> & {
    onSubscriptionError?: (error: ApolloError) => void
  },
) {
  const client = useApolloClient(options?.client)
  verifyDocumentType(subscription, DocumentType.Subscription)
  const [result, setResult] = useState<SubscriptionResult<TData>>({
    loading: !options?.skip,
    error: undefined,
    data: undefined,
    variables: options?.variables,
  })

  const [observable, setObservable] = useState(() => {
    if (options?.skip) {
      return null
    }

    return client.subscribe({
      query: subscription,
      variables: options?.variables,
      fetchPolicy: options?.fetchPolicy,
      context: options?.context,
    })
  })

  const ref = useRef({ client, subscription, options })
  useEffect(() => {
    const shouldResubscribe =
      typeof options?.shouldResubscribe === 'function' ? options.shouldResubscribe(options) : options?.shouldResubscribe

    if (options?.skip) {
      if (!options?.skip !== !ref.current.options?.skip) {
        setResult({
          loading: false,
          data: undefined,
          error: undefined,
          variables: options?.variables,
        })
        setObservable(null)
      }
    } else if (
      result.error ||
      (shouldResubscribe !== false &&
        (client !== ref.current.client ||
          subscription !== ref.current.subscription ||
          options?.fetchPolicy !== ref.current.options?.fetchPolicy ||
          !options?.skip !== !ref.current.options?.skip ||
          !_.isEqual(options?.variables, ref.current.options?.variables)))
    ) {
      setResult({
        loading: true,
        data: undefined,
        error: undefined,
        variables: options?.variables,
      })
      setObservable(
        client.subscribe({
          query: subscription,
          variables: options?.variables,
          fetchPolicy: options?.fetchPolicy,
          context: options?.context,
        }),
      )
    }

    Object.assign(ref.current, { client, subscription, options })
  }, [client, subscription, options, result.error])

  useEffect(() => {
    if (!observable) {
      return
    }

    const subscription = observable.subscribe({
      next(fetchResult) {
        const result = {
          loading: false,
          // TODO: fetchResult.data can be null but SubscriptionResult.data
          // expects TData | undefined only
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          data: fetchResult.data!,
          error: undefined,
          variables: options?.variables,
        }
        setResult(result)

        ref.current.options?.onSubscriptionData?.({
          client,
          subscriptionData: result,
        })
      },
      error(error: ApolloError) {
        // Work around for apollographql/apollo-client#7608
        // Fixed in @apollo/client@3.6.4
        if (error.message === 'Observable cancelled prematurely') return

        setResult({
          loading: false,
          data: undefined,
          error,
          variables: options?.variables,
        })
        ref.current.options?.onSubscriptionError?.(error)
      },
      complete() {
        ref.current.options?.onSubscriptionComplete?.()
      },
    })

    return () => {
      subscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observable])

  return result
}
