import {
  autoUpdate,
  flip,
  FloatingContext,
  FloatingNode,
  FloatingOverlay,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFocus,
  useId,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { mergeRefs } from '@react-aria/utils'
import React, { useCallback } from 'react'
import { ZIndexLayer } from '../../ZIndexLayer'
import { FloatingPortal } from './FloatingPortal'
import { floatingTreeNode } from './floatingTreeNode'
import { Wrapper } from './styles'
import { useFloatingTreeNode } from './useFloatingTreeNode'
import { useOpenState, UseOpenStateProps } from './useOpenState'
import { usePopoverInterceptor } from './usePopoverInterceptor'

export type PopoverRenderProps = {
  hide: () => void
  labelId: string
  descriptionId: string
  context: FloatingContext
}

export type PopoverChildrenFnProps = {
  getReferenceProps: (
    userProps?: (React.HTMLProps<HTMLElement> & { ref?: React.Ref<HTMLElement>; 'data-testid'?: string }) | undefined,
  ) => Record<string, unknown>
}

export type PopoverProps = UseOpenStateProps & {
  trigger?: 'click' | 'manual'
  render: (props: PopoverRenderProps) => React.ReactNode
  placement?: Placement
  children: (props: PopoverChildrenFnProps) => React.ReactNode

  ariaLabel?: string
  zIndex?: number
  root?: HTMLElement | null
}

// inspired by https://codesandbox.io/s/optimistic-jennings-jmpgfk?file=/src/Popover.tsx
// see also:
// https://floating-ui.com/docs/react-dom-interactions#examples
// https://codesandbox.io/s/optimistic-jennings-jmpgfk?file=/src/NestedPopover.tsx:0-2375 -- nesting popovers
// https://codesandbox.io/s/winter-tree-wmmffl?file=/src/Tooltip.tsx -- simple tooltips
// https://codesandbox.io/s/admiring-lamport-5wt3yg?file=/src/DropdownMenu.tsx -- keyboard interactable menus
export const Popover = floatingTreeNode<PopoverProps>(function Popover({
  trigger = 'click',
  children,
  render,
  placement,
  ariaLabel,
  zIndex = ZIndexLayer.LAYER_3_POPOVER,
  root,
  ...openStateProps
}) {
  const [open, setOpen] = useOpenState(openStateProps)
  const { interceptors } = usePopoverInterceptor()

  const handleOpenStateChange = useCallback(
    (change: boolean) => {
      if (change === true) {
        setOpen(true)
      } else if (interceptors.every((cb) => !cb())) {
        setOpen(false)
      }
    },
    [setOpen, interceptors],
  )

  const nodeId = useFloatingNodeId()
  const { hide } = useFloatingTreeNode({
    onOpenChange: handleOpenStateChange,
  })

  const {
    x,
    y,
    refs: { setReference, setFloating },
    strategy,
    context,
  } = useFloating({
    nodeId,
    open,
    onOpenChange: handleOpenStateChange,
    middleware: [offset(12), flip(), shift()],
    placement,
    whileElementsMounted: autoUpdate,
  })

  const id = useId()
  const labelId = `${id}-label`
  const descriptionId = `${id}-description`

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, { enabled: trigger === 'click' }),
    useFocus(context, { enabled: trigger === 'click' }),
    useRole(context),
    useDismiss(context),
  ])

  return (
    <FloatingNode id={nodeId}>
      {children({
        getReferenceProps: (props) =>
          getReferenceProps({
            ...props,
            ref: props?.ref ? mergeRefs(props.ref, setReference) : setReference,
          }),
      })}
      <FloatingPortal root={root}>
        {open && (
          <FloatingOverlay sx={{ zIndex }}>
            <Wrapper
              {...getFloatingProps({
                ref: setFloating,
                style: {
                  top: y ?? 0,
                  left: x ?? 0,
                },
                'aria-label': ariaLabel,
                'aria-labelledby': ariaLabel ? undefined : labelId,
                'aria-describedby': descriptionId,
                onKeyDown: (event) => {
                  if (event.key === 'Escape') {
                    hide()
                    event.stopPropagation()
                  }
                },
              })}
              sx={{
                position: strategy,
              }}
            >
              {render({
                labelId,
                descriptionId,
                hide,
                context,
              })}
            </Wrapper>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </FloatingNode>
  )
})
