import { ApolloLink, FetchResult, Observable, Operation } from '@apollo/client/core'
import { GraphQLError, print } from 'graphql'
import { Client, ClientOptions, createClient } from 'graphql-ws'

/**
 * See https://github.com/enisdenjo/graphql-ws#recipes
 */
export class WebSocketLink extends ApolloLink {
  private client: Client

  constructor(options: ClientOptions) {
    super()
    this.client = createClient({
      ...options,
      isFatalConnectionProblem: (errOrCloseEvent) => {
        return (
          (!(errOrCloseEvent instanceof Error) && (errOrCloseEvent as CloseEvent).code === 4403) ||
          !!options.isFatalConnectionProblem?.(errOrCloseEvent)
        )
      },
    })
  }

  override request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (err instanceof Error) {
              sink.error(err)
              return
            }

            if (Array.isArray(err)) {
              sink.error(new Error((err as GraphQLError[]).map(({ message }) => message).join(', ')))
            } else {
              const closeEvent = err as CloseEvent
              sink.error(
                // reason will be available on clean closes
                new WebSocketClosedError(`Socket closed with event ${closeEvent.code} ${closeEvent.reason || ''}`),
              )
            }
          },
        },
      )
    })
  }

  dispose(): void | Promise<void> {
    return this.client.dispose()
  }
}

export class WebSocketClosedError extends Error {}
