import styled from '@emotion/styled'
import {
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { mergeRefs } from '@react-aria/utils'
import React from 'react'
import { FloatingPortal } from './FloatingPortal'
import { useOpenState, UseOpenStateProps } from './useOpenState'

export type FloatingTooltipChildrenFnProps = {
  getReferenceProps: (
    userProps?: (React.HTMLProps<HTMLElement> & { ref?: React.Ref<HTMLElement> }) | undefined,
  ) => Record<string, unknown>
}

export type FloatingTooltipProps = UseOpenStateProps & {
  tooltip: React.ReactNode
  placement?: Placement
  children: (props: FloatingTooltipChildrenFnProps) => React.ReactNode
  openDelayMs?: number
  closeDelayMs?: number
  hoverRestMs?: number
}

// inspired by https://codesandbox.io/s/winter-tree-wmmffl?file=/src/Tooltip.tsx
// see also:
// https://floating-ui.com/docs/react-dom-interactions#examples
// https://codesandbox.io/s/optimistic-jennings-jmpgfk?file=/src/NestedPopover.tsx:0-2375 -- nesting popovers
// https://codesandbox.io/s/optimistic-jennings-jmpgfk?file=/src/Popover.tsx -- simple popover
// https://codesandbox.io/s/admiring-lamport-5wt3yg?file=/src/DropdownMenu.tsx -- keyboard interactable menus
export const FloatingTooltip = ({
  tooltip,
  children,
  placement,
  openDelayMs = 200,
  closeDelayMs = 0,
  hoverRestMs = 0,
  ...openStateProps
}: FloatingTooltipProps) => {
  const [open, setOpen] = useOpenState(openStateProps)

  const {
    x,
    y,
    refs: { setReference, setFloating },
    strategy,
    context,
  } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(8), flip(), shift()],
    placement,
    whileElementsMounted: autoUpdate,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useFocus(context),
    useHover(context, {
      delay: {
        open: openDelayMs,
        close: closeDelayMs,
      },
      restMs: hoverRestMs,
    }),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ])

  return (
    <>
      {children({
        getReferenceProps: (props) =>
          getReferenceProps({
            ...props,
            ref: props?.ref ? mergeRefs(props.ref, setReference) : setReference,
          }),
      })}
      <FloatingPortal>
        {open && (
          <Wrapper
            {...getFloatingProps({
              ref: setFloating,
              style: {
                top: y ?? 0,
                left: x ?? 0,
              },
            })}
            sx={{
              position: strategy,
            }}
          >
            {tooltip}
          </Wrapper>
        )}
      </FloatingPortal>
    </>
  )
}

const Wrapper = styled.div(({ theme }) => ({
  animation: `${theme.animation.default} ${theme.transition.default}`,
  zIndex: 9999,
}))
