// eslint-disable-next-line no-restricted-imports
import { ApolloClient, ApolloLink, ApolloProvider, concat, HttpLink, RequestHandler, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { getOperationDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@mm/graphql-ws-link'
import fetch from 'cross-fetch'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { createCache } from './createCache'
import { pendingOperationsLink } from './pendingOperationsLink'
import { errorLink } from './utils'

export type ConfiguredApolloProviderProps = {
  getAccessToken: () => Promise<string> | string
  appName: string
  appVersion: string
  getAppPath?: () => string | undefined
  children?: React.ReactNode
  hasPendingOperationsLink?: boolean
}

export const ConfiguredApolloProvider = ({
  getAccessToken,
  children,
  appName,
  appVersion,
  getAppPath,
  hasPendingOperationsLink,
}: ConfiguredApolloProviderProps) => {
  const getAccessTokenRef = useRef(getAccessToken)
  getAccessTokenRef.current = getAccessToken
  const [apollo] = useState(() => {
    const url = new URL('/graphql', process.env.NEXT_PUBLIC_BACKEND_URL)
    const wsUrl = new URL(url.href)
    wsUrl.protocol = url.protocol === 'http:' ? 'ws:' : 'wss:'

    const getAccessToken = getAccessTokenRef.current

    // provides basic tracing information via headers
    const tracingLink = setContext((_, prevContext: Record<'headers', Record<string, string>>) => {
      const path = getAppPath?.()
      return {
        ...prevContext,
        headers: {
          ...prevContext.headers,
          'x-app-name': appName,
          'x-active-company-id': localStorage.getItem('active-company') ?? '',
          'x-app-version': appVersion,
          ...(path && { 'x-app-path': path }),
        },
      }
    })

    const httpLink = concat(
      setContext(async (_operation, prevContext: Record<'headers', Record<string, string>>) => {
        try {
          const token = await getAccessToken()
          return {
            ...prevContext,
            headers: _.pickBy({
              ...prevContext.headers,
              authorization: `Bearer ${token}`,
              'x-feature-flag-overrides': localStorage.getItem('feature-flag-overrides'),
            }),
          }
        } catch (e) {
          return prevContext
        }
      }),
      new HttpLink({
        uri: (operation) => {
          const operationUrl = new URL(url)
          // only used for better visibility in DevTools network tab.
          const shorthand = {
            query: 'q',
            mutation: 'm',
            subscription: 's',
          }[getOperationDefinition(operation.query)?.operation ?? 'query']
          operationUrl.searchParams.set(shorthand, operation.operationName)
          return operationUrl.href
        },
        fetch,
      }),
    )
    const wsLink =
      typeof window !== 'undefined'
        ? new WebSocketLink({
            url: wsUrl.href,
            lazy: true,
            connectionParams: async () => {
              try {
                const authToken = await getAccessToken()
                return {
                  authToken,
                  featureFlagOverrides: localStorage.getItem('feature-flag-overrides'),
                  hostName: wsUrl.hostname,
                }
              } catch (e) {
                return {}
              }
            },
          })
        : ApolloLink.empty()
    const splitLink = split(
      ({ query }) => getOperationDefinition(query)?.operation === 'subscription',
      wsLink,
      httpLink,
    )

    const retryLink = new RetryLink({
      delay: {
        initial: 400,
      },
    })
    const links: Array<ApolloLink | RequestHandler> = [tracingLink, retryLink, errorLink, splitLink]

    if (hasPendingOperationsLink) {
      links.unshift(pendingOperationsLink)
    }

    return new ApolloClient({
      link: ApolloLink.from(links),
      cache: createCache(),
      defaultOptions: {
        query: {
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
        watchQuery: {
          errorPolicy: 'all',
          fetchPolicy: 'cache-and-network',
          nextFetchPolicy: 'cache-first',
          notifyOnNetworkStatusChange: true,
        },
      },
    })
  })

  return <ApolloProvider client={apollo}>{children}</ApolloProvider>
}
