import { FetchResult, Observable, RequestHandler } from '@apollo/client'

declare global {
  // eslint-disable-next-line no-var
  var pendingOperationsCount: number | undefined
}

const update = (diff: number) => {
  if (typeof window !== 'undefined') {
    window.pendingOperationsCount = Math.max(0, (window.pendingOperationsCount ?? 0) + diff)
    document.dispatchEvent(new Event('pendingOperationsCountUpdate'))
  }
}

export const pendingOperationsLink: RequestHandler = (operation, next) => {
  const observable = next(operation)
  return new Observable((observer) => {
    let pending = true
    update(1)
    const resolvePending = () => {
      if (pending) {
        update(-1)
        pending = false
      }
    }

    const subscription = observable.subscribe({
      next(value: FetchResult) {
        resolvePending()
        observer.next(value)
      },
      error(errorValue: any) {
        resolvePending()
        observer.error(errorValue)
      },
      complete() {
        resolvePending()
        observer.complete()
      },
    })

    return () => {
      resolvePending()
      subscription.unsubscribe()
    }
  })
}
