import { Button, Flex, formatUserDisplayName, Heading, ModalHeader, ModalSection, PopupWrapper } from '@mm/company-ui'
import React from 'react'
import { MeetingParticipantsAddDocument, MentionConfirmationDialogDocument } from '../../../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../../../apollo'

export type MentionConfirmationDialogProps = {
  userId: string
  meetingId: string
  onConfirm?: () => void
  onCancel?: () => void
}

export const MentionConfirmationDialog = ({
  userId,
  meetingId,
  onConfirm,
  onCancel,
}: MentionConfirmationDialogProps) => {
  const [addMeetingParticipant, { loading: inviting }] = useMutation(MeetingParticipantsAddDocument)
  const { data, loading, error } = useQuery(MentionConfirmationDialogDocument, {
    variables: {
      userId,
      meetingId,
    },
  })

  const { user, meeting } = data ?? {}
  if (user == null || meeting == null) {
    if (loading) return null
    throw error ?? new Error('User or meeting not found')
  }

  return (
    <PopupWrapper sx={{ padding: 0, width: '500px' }}>
      <ModalHeader>
        <Heading level={4}>
          Invite {formatUserDisplayName(user)} to {meeting.title}?
        </Heading>
      </ModalHeader>

      <ModalSection>
        {user.firstName} is not a member of this meeting and they won’t be notified of this mention. What would you like
        to do next?
      </ModalSection>

      <ModalSection row gap={1} align="center" sx={{ paddingY: 1 }}>
        <Button onClick={onCancel} variant="muted">
          Cancel
        </Button>

        <Flex row grow />

        <Button onClick={onConfirm}>Mention anyway</Button>
        <Button
          variant="accent"
          onClick={() => {
            void addMeetingParticipant({
              variables: {
                meetingId: meeting.id,
                userId: user.id,
                role: 'PARTICIPANT',
              },
            }).then(({ data }) => {
              if (data?.addMeetingParticipant.__typename === 'Meeting') {
                onConfirm?.()
              }
            })
          }}
          loading={inviting}
        >
          {`Invite & Mention ${user.firstName}`}
        </Button>
      </ModalSection>
    </PopupWrapper>
  )
}
