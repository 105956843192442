import { useRouter } from 'next/router'
import { isNestedRoute } from '../../../utils'

export type MeetingContextValue = {
  id: string
}

export const useMeetingContext = (): MeetingContextValue | null => {
  const router = useRouter()

  // isReady=false during Automatic Static Optimization
  if (!router.isReady) return null

  if (isNestedRoute(router, '/meetings/[id]')) {
    return { id: router.query.id }
  }

  if (typeof router.query['collapseTo'] === 'string') {
    const collapseTo = router.query['collapseTo']
    const match = collapseTo.match(/^\/meetings\/([a-zA-Z0-9]+)/)
    if (match?.[1]) {
      return { id: match[1] }
    }
  }
  return null
}
