import { defaultFeatureFlagValues } from '@mm/common'
import { camelCaseKeys, useFlags } from 'launchdarkly-react-client-sdk'
import { ReactCompanyOSFlagSet } from './flagSet'

const defaultFlags = camelCaseKeys(defaultFeatureFlagValues) as ReactCompanyOSFlagSet

export function useFeatureFlags(): ReactCompanyOSFlagSet {
  const flags = useFlags<ReactCompanyOSFlagSet>()
  return new Proxy(flags, {
    get: (target, key, receiver): any => {
      return Reflect.get(target, key, receiver) ?? Reflect.get(defaultFlags, key)
    },
  })
}
