import { produce } from 'immer'
import { useCallback } from 'react'
import { UseAddGoalToCacheOnUserFragmentDoc } from '../../../gen/graphql/documents'
import { ApolloCache } from '../../apollo'
import { useActiveCompanyId } from '../../companies'

export type GoalFragment = {
  id: string
  parentMeeting?: {
    id: string
  } | null
  assignee?: {
    id: string
  } | null
}

export const useAddGoalToCache = () => {
  const { activeCompanyId } = useActiveCompanyId()
  return useCallback(
    (cache: ApolloCache<unknown>, goal: GoalFragment) => {
      if (!goal.assignee) {
        return
      }
      const id = cache.identify({
        __typename: 'User',
        id: goal.assignee.id,
      })
      const fragmentData = cache.readFragment({
        fragment: UseAddGoalToCacheOnUserFragmentDoc,
        id,
        variables: {
          companyId: activeCompanyId,
          meetingId: goal.parentMeeting?.id,
        },
      })
      const updated = produce(fragmentData, (draft) => {
        if (draft?.goals?.edges.every(({ node }) => node.id !== goal.id)) {
          draft.goals.edges.unshift({
            __typename: 'GoalsEdge',
            node: goal,
          })
        }
      })
      if (updated !== fragmentData) {
        cache.writeFragment({
          fragment: UseAddGoalToCacheOnUserFragmentDoc,
          id,
          data: updated,
          variables: {
            companyId: activeCompanyId,
            meetingId: goal.parentMeeting?.id,
          },
        })
      }
    },
    [activeCompanyId],
  )
}
