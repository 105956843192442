import styled from '@emotion/styled'
import { css } from 'theme-ui'
import { Card } from '../Card'

export const PopupReference = styled.div(
  css({
    width: 'max-content',
  }),
)

export const PopupWrapper = styled(Card)(({ theme }) =>
  css({
    whiteSpace: 'pre-line',
    fontFamily: 'body',
    fontWeight: 'body',
    height: 'auto',
    animation: `${theme.animation.default} ${theme.transition.default}`,
  }),
)
