// eslint-disable-next-line no-restricted-imports
import { useMutation as useApolloMutation } from '@apollo/client'
import { isGraphQLError } from '@mm/graphql-helpers'

export const useMutation: typeof useApolloMutation = (mutation, options) =>
  useApolloMutation(mutation, {
    onCompleted: (data) => {
      if (data == null || typeof data !== 'object') return
      const errors = Object.values(data).filter(isGraphQLError)
      if (errors.length > 0) {
        const messages = errors.map(({ message }) => message).filter((x): x is string => !!x)
        if (messages.length !== 1) {
          alert('Something went wrong...')
        } else {
          alert(messages[0])
        }
      }
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('Network error', error)
      alert('Network error')
    },
    ...options,
  })
