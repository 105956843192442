import {
  GlobalGoodThingsSubscriptionDataFragment,
  GlobalGoodThingsSubscriptionDocument,
} from '../../../gen/graphql/documents'
import { useSubscription } from '../../apollo'
import { defineEventBusTopic, useEmitEventBusEvent } from '../../eventbus'

export type GoodThingsUpdatedEvent =
  | {
      type: 'deleted'
      id: string
    }
  | {
      type: 'upserted'
      goodThing: GlobalGoodThingsSubscriptionDataFragment
    }

export const GoodThingsUpdatedTopic = defineEventBusTopic<GoodThingsUpdatedEvent>('goodThingsUpdated')

export const GlobalGoodThingsSubscription = () => {
  const publish = useEmitEventBusEvent(GoodThingsUpdatedTopic)

  useSubscription(GlobalGoodThingsSubscriptionDocument, {
    onSubscriptionData: ({ subscriptionData: { data }, client }) => {
      const evictFromCache = (id: string) => {
        publish({ type: 'deleted', id })

        client.cache.evict({
          id: client.cache.identify({ __typename: 'GoodThing', id }),
        })
        client.cache.gc()
      }

      switch (data?.goodThingsUpdated?.__typename) {
        case 'GoodThingDeleted':
          evictFromCache(data.goodThingsUpdated.deletedGoodThingId)
          break
        case 'GoodThingAccessRevoked':
          evictFromCache(data.goodThingsUpdated.revokedGoodThingId)
          break
        case 'GoodThing':
          publish({ type: 'upserted', goodThing: data.goodThingsUpdated })
          break
      }
    },
  })

  return null
}
