import React from 'react'

export const NotFoundImage = () => (
  <svg width="202" height="188" viewBox="0 0 202 188" fill="none" stroke="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.41754 167.106C4.07453 167.106 3.79749 166.829 3.78429 166.486L0.0112305 1.50067C0.0112305 1.32917 0.0771963 1.17086 0.195929 1.05213C0.314662 0.933393 0.472971 0.867432 0.644474 0.867432H168.15C168.493 0.867432 168.783 1.14447 168.783 1.48748L168.968 24.4425C168.968 24.7855 168.691 25.0757 168.348 25.0757C168.005 25.0757 167.715 24.7987 167.715 24.4557L167.543 2.12072H1.27771L5.02439 165.84L171.277 163.347L170.459 128.32C170.445 127.977 170.723 127.687 171.079 127.674C171.409 127.661 171.712 127.938 171.725 128.294L172.556 163.953C172.556 164.125 172.49 164.283 172.385 164.402C172.266 164.521 172.108 164.587 171.936 164.6L4.41754 167.106Z"
      fill="currentColor"
    />
    <path
      d="M58.6151 38.9263L58.6151 38.9264H58.6257C59.5897 38.9264 60.3685 39.7052 60.3685 40.6692L60.3684 45.2866L60.3685 45.2919C60.3787 46.2355 59.5927 47.0293 58.6257 47.0293H20.816C19.852 47.0293 19.0732 46.2505 19.0732 45.2866V39.8644C19.0732 38.9021 19.8493 38.1244 20.811 38.1217L58.6151 38.9263Z"
      stroke="currentColor"
    />
    <path
      d="M23.5336 22.1601C23.5336 23.4002 23.0454 24.4424 21.2908 24.4424C20.0507 24.4424 19.0481 23.4002 19.0481 22.1601C19.0481 20.92 20.0507 19.9174 21.2908 19.9174C22.5309 19.9174 23.5336 20.9332 23.5336 22.1601Z"
      fill="currentColor"
    />
    <path
      d="M21.3039 25.0756C19.7208 25.0756 18.4412 23.7695 18.4412 22.16C18.4412 20.5769 19.734 19.2972 21.3039 19.2972C22.887 19.2972 24.1667 20.5901 24.1667 22.16C24.1667 24.0466 23.1509 25.0756 21.3039 25.0756ZM21.3039 20.5505C20.42 20.5505 19.6945 21.2761 19.6945 22.16C19.6945 23.0571 20.4332 23.8091 21.3039 23.8091C22.4517 23.8091 22.9134 23.3342 22.9134 22.16C22.9134 21.2761 22.1878 20.5505 21.3039 20.5505Z"
      fill="currentColor"
    />
    <path
      d="M30.1299 25.0756C28.5468 25.0756 27.2671 23.7695 27.2671 22.16C27.2671 20.5769 28.56 19.2972 30.1299 19.2972C31.713 19.2972 32.9926 20.5901 32.9926 22.16C33.0058 24.0466 31.99 25.0756 30.1299 25.0756ZM30.1299 20.5505C29.246 20.5505 28.5204 21.2761 28.5204 22.16C28.5204 23.0571 29.2592 23.8091 30.1299 23.8091C31.2776 23.8091 31.7394 23.3342 31.7394 22.16C31.7394 21.2761 31.027 20.5505 30.1299 20.5505Z"
      fill="currentColor"
    />
    <path
      d="M23.3093 161.974C19.5758 161.974 16.5547 158.887 16.5547 155.088C16.5547 151.354 19.589 148.333 23.3093 148.333C27.0427 148.333 30.0638 151.368 30.0638 155.088C30.0638 159.6 27.7288 161.974 23.3093 161.974ZM23.3093 149.6C20.275 149.6 17.808 152.067 17.808 155.101C17.808 158.149 20.3277 160.721 23.3093 160.721C27.0559 160.721 28.8105 158.94 28.8105 155.101C28.8105 152.067 26.3435 149.6 23.3093 149.6Z"
      fill="currentColor"
    />
    <path
      d="M38.4148 25.0756C36.8317 25.0756 35.552 23.7695 35.552 22.16C35.552 20.5769 36.8449 19.2972 38.4148 19.2972C39.9847 19.2972 41.2776 20.5901 41.2776 22.16C41.2776 24.0466 40.2617 25.0756 38.4148 25.0756ZM38.4148 20.5505C37.5309 20.5505 36.8053 21.2761 36.8053 22.16C36.8053 23.0571 37.5441 23.8091 38.4148 23.8091C39.5625 23.8091 40.0243 23.3342 40.0243 22.16C40.0243 21.2761 39.2987 20.5505 38.4148 20.5505Z"
      fill="currentColor"
    />
    <path
      d="M36.8186 59.3102H21.3041C20.9611 59.3102 20.6709 59.0331 20.6709 58.6769C20.6709 58.3207 20.9479 58.0437 21.3041 58.0437H36.8317C37.1748 58.0437 37.465 58.3207 37.465 58.6769C37.465 59.0331 37.1747 59.3102 36.8186 59.3102Z"
      fill="currentColor"
    />
    <path
      d="M31.2115 67.7007H21.2907C20.9477 67.7007 20.6575 67.4237 20.6575 67.0675C20.6575 66.7113 20.9345 66.4342 21.2907 66.4342H31.2115C31.5545 66.4342 31.8447 66.7113 31.8447 67.0675C31.8447 67.4237 31.5677 67.7007 31.2115 67.7007Z"
      fill="currentColor"
    />
    <path
      d="M23.3093 157.08C22.9663 157.08 22.676 156.803 22.676 156.447V146.948C22.676 146.605 22.9531 146.315 23.3093 146.315C23.6523 146.315 23.9425 146.592 23.9425 146.948V156.447C23.9425 156.803 23.6523 157.08 23.3093 157.08Z"
      fill="currentColor"
    />
    <path
      d="M89.9315 179.112H81.7521C81.4091 179.112 81.1189 178.835 81.1189 178.478V165.312C81.1189 164.969 81.3959 164.679 81.7521 164.679C82.1083 164.679 82.3854 164.956 82.3854 165.312V177.845H89.2719L88.4539 165.233C88.4276 164.89 88.6914 164.587 89.0476 164.56C89.4038 164.534 89.694 164.798 89.7204 165.154L90.5779 178.426C90.5911 178.597 90.5252 178.769 90.4064 178.901C90.2745 179.032 90.1162 179.112 89.9315 179.112Z"
      fill="currentColor"
    />
    <path
      d="M89.483 171.394L89.0872 165.207L81.7522 165.312V178.478H81.7786C84.3115 176.077 86.8577 173.689 89.483 171.394Z"
      fill="currentColor"
    />
    <path
      d="M111.198 187.133H60.9211C60.6836 187.133 60.4594 186.988 60.3538 186.776C60.2483 186.552 60.2879 186.302 60.433 186.104C64.1796 181.565 77.8999 176.974 89.971 177.845C101.937 178.703 111.185 185.708 111.58 185.998C111.791 186.156 111.884 186.447 111.791 186.697C111.712 186.961 111.475 187.133 111.198 187.133ZM62.4514 185.879H109.166C106 183.848 98.5989 179.745 89.8919 179.112C79.3115 178.333 67.0688 182.001 62.4514 185.879Z"
      fill="currentColor"
    />
    <path
      d="M163.955 85.7349C163.612 85.7349 163.335 85.4579 163.322 85.1149L162.715 56.1969C162.702 55.8538 162.979 55.5636 163.335 55.5504H163.348C163.691 55.5504 163.968 55.8275 163.981 56.1705L164.588 85.0885C164.601 85.4315 164.324 85.7217 163.968 85.7349H163.955Z"
      fill="currentColor"
    />
    <path
      d="M77.3721 71.9881C72.8075 71.6451 52.7021 70.9591 32.8605 83.4524C23.0717 89.6133 11.3567 97.3574 11.1193 99.1779C11.0929 99.3626 11.1588 99.6001 11.2908 99.7452C11.4095 99.8771 11.581 99.9431 11.7525 99.9431C11.9108 99.9431 12.0559 99.8903 12.1879 99.7716C12.3066 99.6661 12.3725 99.5209 12.3857 99.3758C12.6628 98.9273 14.9583 96.2228 33.5466 84.521C55.6045 70.6293 77.9658 73.3074 78.1901 73.3338C78.3484 73.347 78.5067 73.3074 78.6386 73.215C78.2164 72.7797 77.7943 72.3839 77.3721 71.9881Z"
      fill="currentColor"
    />
    <path
      d="M110.169 123.901C110.129 123.901 110.103 123.901 110.063 123.887C109.72 123.835 109.483 123.505 109.549 123.162C109.562 123.083 110.841 115.457 114.522 107.463C114.667 107.146 115.037 107.001 115.353 107.159C115.67 107.304 115.802 107.674 115.657 107.99C112.055 115.827 110.736 123.597 110.723 123.676C110.683 123.993 110.472 123.901 110.169 123.901Z"
      fill="currentColor"
    />
    <path
      d="M149.799 155.787H142.807C142.464 155.787 142.174 155.51 142.174 155.154C142.174 154.811 142.451 154.521 142.807 154.521H149.799C150.142 154.521 150.432 154.798 150.432 155.154C150.432 155.51 150.142 155.787 149.799 155.787Z"
      fill="currentColor"
    />
    <path
      d="M80.4196 41.8169C76.2904 41.6058 73.3748 42.2787 71.7653 43.7958C70.644 44.8644 70.4065 46.1045 70.4065 46.962C70.4065 48.2417 70.9342 49.4554 71.8841 50.3657C72.7284 51.1704 74.2983 52.1071 77.0951 52.1335L77.5964 72.0014C77.2666 71.975 76.8181 71.9487 76.2772 71.9091C76.5542 72.3576 76.8444 72.793 77.1479 73.2283C77.7811 73.2811 78.1373 73.3207 78.1637 73.3207C78.3484 73.3471 78.5331 73.2811 78.665 73.1624C78.7969 73.0436 78.8761 72.8589 78.8761 72.6742L78.3352 51.4739C78.3352 51.3024 78.256 51.144 78.1373 51.0253C78.0186 50.9066 77.8471 50.8538 77.6756 50.8538C75.4988 50.9462 73.797 50.458 72.7548 49.4554C71.8049 48.5451 71.673 47.5029 71.673 46.962C71.673 46.0781 72.0028 45.3129 72.636 44.7061C73.9685 43.4396 76.6334 42.8723 80.3537 43.0702C80.6703 43.0834 81.0001 42.8196 81.0133 42.4766C81.0397 42.1335 80.7758 41.8301 80.4196 41.8169Z"
      fill="currentColor"
    />
    <path
      d="M102.86 50.933C102.517 50.933 102.227 50.6559 102.227 50.2997C102.227 49.9435 102.504 49.6665 102.86 49.6665C104.034 49.6665 105.011 49.3499 105.459 48.8222C105.71 48.5187 105.802 48.1626 105.723 47.714C105.551 46.7246 103.348 46.1705 102.293 46.0517C101.976 46.0122 101.726 45.7483 101.726 45.4317V39.6666C101.726 39.3236 102.003 39.0333 102.359 39.0333C102.702 39.0333 102.992 39.3104 102.992 39.6666V44.8908C104.166 45.1019 106.66 45.7483 106.963 47.5161C107.095 48.3341 106.91 49.0596 106.422 49.6401C105.71 50.4712 104.456 50.933 102.86 50.933Z"
      fill="currentColor"
    />
    <path
      d="M94.9316 43.255C94.7997 43.255 94.6678 43.2154 94.549 43.1231C93.4409 42.2788 90.7364 41.5663 89.7997 41.3685C89.4567 41.2893 89.2456 40.9595 89.3116 40.6165C89.3908 40.2735 89.7206 40.0624 90.0636 40.1284C90.2087 40.1548 93.7575 40.9331 95.3142 42.1204C95.5912 42.3315 95.644 42.7273 95.4329 43.0043C95.3142 43.1758 95.1295 43.255 94.9316 43.255Z"
      fill="currentColor"
    />
    <path
      d="M107.042 41.0649V42.3182C107.042 43.9409 109.562 43.9409 109.562 42.3182V41.0649C109.562 39.4422 107.042 39.4422 107.042 41.0649Z"
      fill="currentColor"
    />
    <path
      d="M92.3589 34.9834C91.3431 35.2076 90.3668 35.4715 89.417 35.8804C88.6782 36.1971 89.3114 37.292 90.0502 36.9622C90.8945 36.5928 91.7916 36.3818 92.6887 36.1839C93.4803 36.0256 93.1504 34.8119 92.3589 34.9834Z"
      fill="currentColor"
    />
    <path
      d="M109.879 34.759C109.232 34.3237 108.52 34.0071 107.913 33.5321C107.28 33.0308 106.383 33.9147 107.016 34.4292C107.715 34.9833 108.507 35.3659 109.232 35.854C109.918 36.3026 110.551 35.2208 109.879 34.759Z"
      fill="currentColor"
    />
    <path
      d="M94.9974 56.6322C94.0607 56.4871 92.8074 56.0517 92.7415 54.9303C92.6887 54.1256 91.4354 54.1256 91.4882 54.9303C91.5937 56.6454 93.0977 57.5952 94.6676 57.8459C95.4459 57.9646 95.789 56.7509 94.9974 56.6322Z"
      fill="currentColor"
    />
    <path
      d="M93.2033 89.6267C83.177 89.6267 75.5121 82.5555 74.9449 72.7403C74.9185 72.3973 75.1955 72.0939 75.5385 72.0807C75.8815 72.0675 76.185 72.3313 76.1981 72.6743C76.739 82.041 83.8234 88.5186 93.454 88.3734C98.3616 88.3075 101.304 86.3286 102.913 84.6663C104.734 82.7798 105.802 80.2732 105.749 77.9645C105.736 77.6215 106.013 77.3313 106.369 77.3181H106.383C106.726 77.3181 107.003 77.5951 107.016 77.9382C107.069 80.6162 105.908 83.3867 103.823 85.5371C102.055 87.3708 98.8233 89.5608 93.4804 89.6267C93.3748 89.6267 93.2957 89.6267 93.2033 89.6267Z"
      fill="currentColor"
    />
    <path
      d="M114.773 36.4081C114.733 34.4556 114.694 32.4107 114.668 30.2999C114.509 20.366 108.137 15.3264 95.7231 15.3264C81.5411 15.3264 74.9448 20.7353 74.9448 32.3316V42.4107C74.9448 42.7537 75.2219 43.0439 75.5781 43.0439C75.8683 43.0439 76.1058 42.846 76.1849 42.5822C78.0187 42.4371 78.8102 42.8724 80.8551 42.8592C81.6334 42.3183 81.6598 29.8646 84.9711 27.0282C90.8286 22.0018 108.348 23.6773 112.966 26.4741C113.243 27.6482 113.388 28.9279 113.414 30.3131C113.454 32.4239 113.493 34.4688 113.52 36.4213C113.876 54.8908 114.1 66.0517 108.005 69.5609C102.781 72.582 93.3352 72.0015 93.2429 72.0015C93.0186 71.9883 92.8207 72.0807 92.702 72.2522C92.57 72.4237 92.5437 72.6479 92.6228 72.8458C94.2323 77.1202 101.91 78.7825 102.24 78.8616C102.28 78.8748 102.333 78.8748 102.372 78.8748C102.517 78.8748 102.649 78.822 102.768 78.7297C102.913 78.611 103.005 78.4263 103.005 78.2416V72.6743C103.005 72.6611 103.005 72.6348 103.005 72.6216C105.011 72.2258 107.003 71.6057 108.652 70.6559C115.367 66.7773 115.142 55.3525 114.773 36.4081Z"
      fill="currentColor"
    />
    <path
      d="M63.0318 120.972C62.3853 119.402 59.1004 117.08 55.9078 117.08C53.3748 117.08 51.739 117.713 50.6968 118.479V118.465C50.644 118.373 49.483 116.236 44.9316 114.877C43.8103 114.547 42.6493 114.442 41.4752 114.495L45.5385 110.748C45.7891 110.51 45.8155 110.115 45.5781 109.864C45.3406 109.613 44.9448 109.587 44.6942 109.824L39.338 114.772C33.9554 115.853 28.9951 119.574 28.718 119.785C28.441 119.996 28.3882 120.392 28.5993 120.669C28.718 120.827 28.9159 120.919 29.1006 120.919C29.2325 120.919 29.3644 120.88 29.4832 120.787C29.5755 120.721 38.243 114.204 44.6018 116.078C48.5859 117.252 49.6018 119.033 49.615 119.033C49.6677 119.138 49.7469 119.217 49.8524 119.283C49.0873 120.167 48.9158 120.998 48.9026 121.078C48.8366 121.421 49.0609 121.737 49.4039 121.803C49.7469 121.869 50.0767 121.645 50.1427 121.315C50.1691 121.196 50.8287 118.32 55.9474 118.32C58.6387 118.32 61.4619 120.365 61.9104 121.434C62.0159 121.671 62.2402 121.816 62.4909 121.816C62.57 121.816 62.6492 121.803 62.7283 121.764C63.0054 121.658 63.1637 121.289 63.0318 120.972Z"
      fill="currentColor"
    />
    <path
      d="M57.9923 145.062C57.7416 145.062 57.491 144.903 57.3986 144.653C53.7971 135.035 58.2429 126.183 59.6941 123.69C60.4989 122.318 62.3062 119.97 64.0213 119.93C64.5094 119.93 65.1426 120.075 65.6571 120.853C66.1453 121.605 65.5516 122.74 64.483 124.811C63.52 126.658 62.3326 128.967 61.7126 131.421C60.4197 136.553 61.2508 142.252 62.4118 144.085C62.5965 144.376 62.5173 144.771 62.2139 144.956C61.9237 145.141 61.5279 145.062 61.3432 144.758C59.892 142.463 59.1796 136.302 60.4857 131.117C61.1453 128.518 62.425 126.038 63.3616 124.231C63.9157 123.162 64.6149 121.816 64.5622 121.473C64.3907 121.223 64.1928 121.183 64.0477 121.196C63.1506 121.223 61.6598 122.845 60.7891 124.336C59.4039 126.698 55.1691 135.128 58.5859 144.217C58.7047 144.547 58.5464 144.903 58.2166 145.022C58.1374 145.049 58.0582 145.062 57.9923 145.062Z"
      fill="currentColor"
    />
    <path
      d="M126.316 138.413C125.973 138.413 125.683 138.136 125.683 137.779C125.683 136.117 125.683 133.004 113.994 126.434C107.385 122.727 93.9946 122.753 81.0659 122.766C75.723 122.779 70.1953 122.779 65.103 122.542C64.76 122.529 64.4829 122.225 64.5093 121.882C64.5225 121.539 64.8391 121.262 65.1689 121.289C70.2349 121.526 75.7493 121.526 81.0791 121.513C94.7862 121.5 107.728 121.473 114.628 125.352C126.013 131.75 126.963 135.128 126.963 137.793C126.936 138.136 126.659 138.413 126.316 138.413Z"
      fill="currentColor"
    />
    <path
      d="M126.501 143.558C126.382 143.558 126.264 143.518 126.158 143.452C125.868 143.254 125.789 142.872 125.987 142.581C126.448 141.895 126.541 141.051 126.264 140.062C125.802 138.426 123.559 134.046 112.53 128.861C112.214 128.716 112.082 128.334 112.227 128.017C112.372 127.7 112.754 127.568 113.071 127.713C121.29 131.579 126.409 135.84 127.477 139.705C127.847 141.038 127.688 142.265 127.029 143.254C126.897 143.452 126.699 143.558 126.501 143.558Z"
      fill="currentColor"
    />
    <path
      d="M126.066 145.062C125.986 145.062 125.92 145.048 125.841 145.022C125.512 144.903 125.353 144.534 125.472 144.217C126.145 142.41 124.06 138.822 110.274 131.975C109.958 131.816 109.839 131.447 109.984 131.13C110.142 130.814 110.512 130.695 110.828 130.84C122.899 136.843 127.939 141.223 126.646 144.639C126.567 144.903 126.329 145.062 126.066 145.062Z"
      fill="currentColor"
    />
    <path
      d="M121.884 145.062C121.831 145.062 121.765 145.048 121.699 145.035C121.369 144.943 121.171 144.587 121.277 144.257C121.963 141.909 116.818 140.22 112.675 138.848C111.145 138.347 109.707 137.872 108.52 137.37C108.203 137.238 108.045 136.869 108.19 136.539C108.322 136.223 108.691 136.077 109.021 136.209C110.156 136.684 111.567 137.159 113.071 137.647C117.966 139.257 123.52 141.091 122.491 144.613C122.398 144.89 122.148 145.062 121.884 145.062Z"
      fill="currentColor"
    />
    <path
      d="M196.131 20.6561C195.116 20.0888 193.968 19.8645 192.741 19.8645C188.81 19.8645 185.63 24.5347 185.63 30.313C185.63 32.3842 186.052 34.3235 186.765 35.9462C189.693 30.7351 192.649 25.5241 196.131 20.6561Z"
      fill="currentColor"
    />
    <path
      d="M188.664 38.8748C189.812 40.0622 191.224 40.7614 192.728 40.7614C196.659 40.7614 199.838 36.0912 199.838 30.3129C199.838 26.896 199.271 24.5214 198.321 22.8987C194.733 27.9646 191.725 33.4395 188.664 38.8748Z"
      fill="currentColor"
    />
    <path
      d="M192.741 43.611C187.635 43.611 183.493 37.648 183.493 30.313C183.493 22.9779 187.649 17.0149 192.741 17.0149C198.875 17.0149 201.989 21.4872 201.989 30.313C201.989 37.648 197.833 43.611 192.741 43.611ZM192.741 18.2814C188.335 18.2814 184.746 23.6771 184.746 30.313C184.746 36.9488 188.335 42.3445 192.741 42.3445C197.147 42.3445 200.736 36.9488 200.736 30.313C200.736 22.2127 198.11 18.2814 192.741 18.2814Z"
      fill="currentColor"
    />
    <path
      d="M118.388 46.9092C118.177 46.9092 117.979 46.8037 117.86 46.619C115.696 43.2813 115.71 40.1151 117.886 37.2259C117.966 37.1072 118.098 37.028 118.229 37.0016L188.651 18.4529C188.994 18.3606 189.337 18.5717 189.416 18.9015C189.509 19.2313 189.311 19.5875 188.968 19.6666L118.77 38.1626C117.108 40.4845 117.095 42.9383 118.731 45.6427L154.878 44.0464C155.208 44.0332 155.525 44.2971 155.538 44.6533C155.551 44.9963 155.287 45.2997 154.931 45.3129L118.414 46.896C118.414 46.9092 118.401 46.9092 118.388 46.9092Z"
      fill="currentColor"
    />
    <path
      d="M118.216 44.6798L116.699 41.9753L118.098 39.2708C118.216 39.0465 118.19 38.7959 118.032 38.598C117.886 38.4001 117.636 38.321 117.398 38.3737L111.528 39.7194C111.317 39.7721 111.145 39.9172 111.079 40.1151C111.026 40.2471 109.945 43.2945 111.132 45.2998C111.251 45.4977 111.448 45.6032 111.673 45.6032H117.662C117.886 45.6032 118.097 45.4845 118.203 45.2866C118.322 45.1151 118.322 44.8644 118.216 44.6798Z"
      fill="currentColor"
    />
    <path
      d="M168.849 44.6665C168.519 44.6665 168.229 44.4026 168.216 44.0596C168.203 43.7166 168.466 43.4132 168.823 43.4L192.701 42.3446C193.018 42.3314 193.348 42.5952 193.361 42.9514C193.374 43.2944 193.11 43.5979 192.754 43.6111L168.875 44.6665C168.875 44.6665 168.862 44.6665 168.849 44.6665Z"
      fill="currentColor"
    />
    <path
      d="M145.947 105.273C141.25 105.273 135.947 102.595 127.926 94.2308C117.161 83.004 110.908 78.5845 102.187 76.0515C101.857 75.9592 101.66 75.603 101.765 75.2732C101.857 74.9434 102.2 74.7455 102.543 74.851C111.514 77.4631 117.9 81.9618 128.849 93.3601C140.195 105.194 145.591 104.758 150.142 103.294C156.91 101.117 158.717 97.5686 158.744 97.529C158.902 97.2124 159.271 97.0804 159.588 97.2387C159.905 97.3838 160.036 97.7664 159.878 98.0699C159.799 98.2282 157.873 102.12 150.538 104.481C149.021 104.97 147.517 105.273 145.947 105.273Z"
      fill="currentColor"
    />
    <path
      d="M192.741 87.0145C192.728 87.0145 192.728 87.0145 192.741 87.0145C192.385 87.0013 192.108 86.7243 192.108 86.3681C192.398 72.1993 188.189 59.1123 178.44 43.9277C178.255 43.6375 178.335 43.2417 178.625 43.057C178.915 42.8723 179.311 42.9515 179.496 43.2417C189.377 58.6506 193.664 71.9618 193.361 86.3813C193.361 86.7375 193.084 87.0145 192.741 87.0145Z"
      fill="currentColor"
    />
    <path
      d="M150.063 134.838C136.514 134.838 123.493 129.006 111.25 117.45C111 117.212 110.987 116.817 111.224 116.566C111.462 116.315 111.857 116.302 112.108 116.539C126.118 129.758 141.132 135.339 156.725 133.109C176.857 129.336 192.081 111.051 192.107 90.5766C192.107 90.2336 192.398 89.9566 192.741 89.9434C193.084 89.9434 193.374 90.2204 193.374 90.5766C193.334 111.645 177.675 130.471 156.936 134.349C154.628 134.679 152.332 134.838 150.063 134.838Z"
      fill="currentColor"
    />
    <path
      d="M160.261 105.286C159.957 105.286 159.68 105.062 159.641 104.745L159.496 103.861C158.744 99.2573 157.345 90.6821 164.548 83.5186C164.799 83.2679 165.195 83.2679 165.432 83.5186C165.683 83.7692 165.683 84.165 165.432 84.4025C158.691 91.1043 160.023 99.2704 160.736 103.65L160.881 104.534C160.934 104.877 160.696 105.194 160.353 105.247C160.327 105.286 160.3 105.286 160.261 105.286Z"
      fill="currentColor"
    />
    <path
      d="M121.303 125.497C121.211 125.418 121.118 125.339 121 125.233C115.881 120.774 114.285 111.038 114.285 111.038C114.285 111.038 114.034 110.853 112.609 113.94C111.185 117.028 112.609 118.136 112.609 118.136C112.609 118.136 118.902 123.518 121.264 125.524L121.303 125.497Z"
      fill="currentColor"
    />
    <path
      d="M167.345 81.0648C167.306 81.0648 167.253 81.0648 167.213 81.0516C166.87 80.9856 166.659 80.6426 166.725 80.2996C167.741 75.4843 166.342 60.2074 162.886 56.6058L162.741 56.4607C158.269 51.8037 152.688 45.999 154.192 40.8803C155.393 36.7906 159.205 32.0149 162.029 31.0651C163.229 30.6561 164.034 30.9727 164.496 31.3157C166.105 32.5031 165.472 35.1548 162.596 39.2313C162.398 39.5083 162.345 39.8513 162.451 40.1679C162.556 40.4845 162.794 40.7352 163.124 40.8407C166.844 42.1072 173.532 44.97 176.554 49.97C176.738 50.2734 176.646 50.656 176.342 50.8407C176.052 51.0254 175.656 50.933 175.472 50.6296C172.649 45.9726 166.263 43.255 162.701 42.0413C162.002 41.8038 161.461 41.2629 161.237 40.5637C161.013 39.8645 161.131 39.1125 161.554 38.5189C163.783 35.3658 164.614 33.0044 163.73 32.358C163.401 32.1205 162.965 32.0941 162.411 32.2788C159.957 33.1099 156.474 37.5558 155.38 41.2629C154.087 45.6692 159.601 51.4212 163.625 55.6164L163.77 55.7615C167.807 59.9699 168.928 75.8801 167.926 80.5898C167.899 80.8669 167.635 81.0648 167.345 81.0648Z"
      fill="currentColor"
    />
    <path
      d="M183.374 93.215C179.667 93.215 175.657 92.5686 172.253 91.2493C167.543 89.4156 164.812 86.6188 164.364 83.1491C164.153 81.566 164.971 79.9961 166.171 79.6399C166.527 79.5344 167.781 79.3365 168.651 81.1834C169.891 83.8088 172.147 85.6689 175.353 86.7111C182.174 88.9274 190.656 86.8035 192.424 85.8404C192.622 85.7217 192.873 85.7349 193.071 85.8668C193.242 85.9855 194.786 87.0145 194.878 88.4789C194.918 89.0066 194.759 89.785 193.849 90.5501C191.765 92.3047 187.767 93.215 183.374 93.215ZM166.646 80.8272C166.607 80.8272 166.567 80.8272 166.527 80.8404C166.052 80.9724 165.472 81.8826 165.617 82.9776C166.105 86.7375 169.746 88.9143 172.715 90.062C179.654 92.7665 189.535 92.529 193.058 89.5739C193.466 89.2309 193.651 88.8879 193.638 88.5449C193.598 87.9908 193.071 87.4367 192.675 87.1201C189.878 88.3866 181.448 89.996 174.984 87.9116C171.422 86.7639 168.915 84.6795 167.53 81.7243C167.385 81.4341 167.055 80.8272 166.646 80.8272Z"
      fill="currentColor"
    />
    <path
      d="M164.997 145.062H12.5968C12.2538 145.062 11.9768 144.785 11.9636 144.442L9.44381 13.4662C9.43062 13.1232 9.70766 12.833 10.0639 12.8198L162.451 9.04675C162.622 9.05995 162.781 9.11271 162.899 9.21825C163.018 9.33699 163.084 9.4953 163.097 9.6668L163.401 25.9068C163.401 26.2498 163.137 26.54 162.781 26.5532C162.451 26.5664 162.147 26.2762 162.134 25.9332L161.844 10.3264L10.7235 14.0599L13.2169 143.795H164.35L164.232 131.407C164.232 131.064 164.509 130.774 164.852 130.774C165.195 130.774 165.485 131.051 165.485 131.394L165.604 144.415C165.604 144.587 165.538 144.745 165.419 144.864C165.327 144.996 165.168 145.062 164.997 145.062Z"
      fill="currentColor"
    />
  </svg>
)
