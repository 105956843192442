import React from 'react'

// Suggested to add a suspender component to throw when loading instead of messing with the useQuery hook:
// https://github.com/apollographql/apollo-feature-requests/issues/162#issuecomment-962696368

export const Suspender = (): React.ReactElement => {
  const resolve = React.useRef<() => void>()
  const promise = React.useMemo(
    () =>
      new Promise<void>((res) => {
        resolve.current = res
      }),
    [],
  )

  React.useEffect(() => {
    return () => {
      resolve.current?.()
    }
  })

  throw promise
}
