import { datadogRum } from '@datadog/browser-rum'

export const initDatadogRum = (config: { version: string }) => {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'v2',
    version: config.version,
    env: process.env['TARGET_ENV'],
    sampleRate: 100,
    trackInteractions: true,
  })
}
