import Tippy, { TippyProps } from '@tippyjs/react/headless'
import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { PopupReference, PopupWrapper } from '../Popup'

export type TooltipProps = {
  description: string | React.ReactElement
  position?: TippyProps['placement']
  children?: React.ReactNode
  interactive?: boolean
  visible?: boolean
  delay?: number | [number | null, number | null]
  sx?: ThemeUIStyleObject
  referenceWrapperProps?: React.HTMLAttributes<HTMLDivElement>
}

export const Tooltip = ({
  position = 'auto',
  description,
  interactive = true,
  visible = false,
  children,
  delay,
  referenceWrapperProps,
  ...props
}: TooltipProps) => {
  return (
    <Tippy
      arrow={false}
      offset={[0, 12]}
      interactive={interactive}
      visible={visible || undefined}
      placement={position}
      delay={delay}
      render={(attrs) => (
        <PopupWrapper sx={{ fontSize: 0, color: 'text' }} {...attrs} {...props}>
          {description}
        </PopupWrapper>
      )}
    >
      <PopupReference role="tooltip" {...referenceWrapperProps}>
        {children}
      </PopupReference>
    </Tippy>
  )
}
