import styled from '@emotion/styled'
import { css } from 'theme-ui'
import { ZIndexLayer } from '../../ZIndexLayer'

export const ModalOverlay = styled.div(
  css({
    position: 'fixed',
    zIndex: ZIndexLayer.LAYER_2_MODAL,
    inset: 0,
    backgroundColor: 'transparentBlack.40',
  }),
)
