import {
  GlobalMeetingPrepSubscriptionDataFragment,
  GlobalMeetingPrepSubscriptionDocument,
} from '../../../gen/graphql/documents'
import { useSubscription } from '../../apollo'
import { defineEventBusTopic, useEmitEventBusEvent } from '../../eventbus'

export type MeetingPrepUpdatedEvent =
  | {
      type: 'deleted'
      id: string
    }
  | {
      type: 'upserted'
      meetingPrep: GlobalMeetingPrepSubscriptionDataFragment
    }

export const MeetingPrepUpdatedTopic = defineEventBusTopic<MeetingPrepUpdatedEvent>('meetingPrepUpdated')

export const GlobalMeetingPrepSubscription = () => {
  const publish = useEmitEventBusEvent(MeetingPrepUpdatedTopic)

  useSubscription(GlobalMeetingPrepSubscriptionDocument, {
    onSubscriptionData: ({ subscriptionData: { data }, client }) => {
      const evictFromCache = (id: string) => {
        publish({ type: 'deleted', id })

        client.cache.evict({
          id: client.cache.identify({ __typename: 'MeetingPrep', id }),
        })
        client.cache.gc()
      }

      switch (data?.meetingPrepUpdated?.__typename) {
        case 'MeetingPrepDeleted':
          evictFromCache(data.meetingPrepUpdated.deletedMeetingPrepId)
          break
        case 'MeetingPrepAccessRevoked':
          evictFromCache(data.meetingPrepUpdated.revokedMeetingPrepId)
          break
        case 'MeetingPrep':
          publish({ type: 'upserted', meetingPrep: data.meetingPrepUpdated })
          break
      }
    },
  })

  return null
}
