import React from 'react'
import { Text } from '../../Text'
import { UserSelectItem } from '../types'

export type UserSelectOptionProps = React.ComponentPropsWithRef<'li'> &
  UserSelectItem & {
    isFocused?: boolean
  }

export const UserSelectOption = React.forwardRef<HTMLLIElement, UserSelectOptionProps>(
  ({ avatar, name, detail, isFocused, ...rest }, ref) => {
    return (
      <li
        {...rest}
        ref={ref}
        sx={{
          backgroundColor: isFocused ? 'background-light-alpha' : 'transparent',
          px: 1.5,
          paddingY: 0.5,
          display: 'flex',
          alignItems: 'center',
          gap: 1,

          cursor: 'pointer',

          '&:hover': {
            backgroundColor: 'background-light-alpha',
          },
          '&:active': {
            backgroundColor: 'background-medium-alpha',
          },
        }}
      >
        {avatar}
        <div sx={{ flex: 1 }}>{name}</div>
        {detail && (
          <Text variant="small" color="text-light">
            {detail}
          </Text>
        )}
      </li>
    )
  },
)

UserSelectOption.displayName = 'UserSelectOption'
