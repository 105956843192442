export enum ZIndexLayer {
  // LAYER_O_DEFAULT = 0,

  LAYER_1_DRAWER = 100,

  LAYER_2_MODAL = 200,

  LAYER_3_POPOVER = 300,

  LAYER_999_INFINITY = 999,
}
