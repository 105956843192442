import { FloatingPortal as BaseFloatingPortal } from '@floating-ui/react'
import React from 'react'

// floating-ui used to put elements into #floating-ui-root
// this component brings back this behaviour for backward compatibility
export const FloatingPortal = (props: React.ComponentPropsWithoutRef<typeof BaseFloatingPortal>) => (
  <BaseFloatingPortal {...props} root={props.root ?? getFloatingUiRoot()} />
)

export const getFloatingUiRoot = () => {
  let root = document.getElementById('floating-ui-root')
  if (root == null) {
    root = document.createElement('div')
    root.id = 'floating-ui-root'
    document.body.append(root)
  }
  return root
}
