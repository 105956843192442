import { toast, useFeatureFlags } from '@mm/company-ui/src'
import { useEffect } from 'react'
import { capture } from '../modules/analytics'
import { useActiveUser } from '../modules/users'

export const AppVersionUpdateNotifier = ({ appVersion }: { appVersion: string }) => {
  // check app version and prompt user to refresh if a new version is available
  const user = useActiveUser()
  const { updateAppNotifier } = useFeatureFlags()

  useEffect(() => {
    if (!updateAppNotifier || !user || !appVersion) return

    const broadcastChannel = new BroadcastChannel('version-update')
    broadcastChannel.onmessage = (event) => {
      if (event.data === 'reload') {
        window.location.reload()
      }
    }

    let timerId: NodeJS.Timeout

    async function checkVersion() {
      try {
        const response = await fetch('/api/version')
        const data = (await response.json()) as { version: string }
        if (data.version !== appVersion) {
          toast('A new version of CompanyOS is available.', {
            toastId: 'app-version-update',
            type: 'warning',
            btnTitle: 'Update',
            autoClose: false,
            closeOnClick: false,
            onBtnClick: () => {
              capture('App Version Updated', { version: data.version })
              broadcastChannel.postMessage('reload')
              window.location.reload()
            },
          })
          return
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error on checking for new version', e)
      }
      timerId = setTimeout(checkVersion, 60000)
    }
    void checkVersion()
    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
      broadcastChannel.close()
    }
  }, [appVersion, user, updateAppNotifier])
  return null
}
