import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { CheckIcon, ExclamationCircleFilledIcon, ExclamationOctagonIcon } from '@mm/company-ui-icons'
import React, { ReactElement } from 'react'
import { ToastContainer as BaseToastContainer, toast as baseToast, cssTransition } from 'react-toastify'
import { css } from 'theme-ui'
import 'react-toastify/dist/ReactToastify.css'
import { ToastBody } from './ToastBody'

export const ToastContainer = styled(BaseToastContainer)(() =>
  css({
    '--toastify-toast-max-height': '50px',
    '--toastify-toast-width:': 'auto',
    '.Toastify__toast-container--bottom-right': {
      bottom: 1,
      right: 2,
    },
    '.Toastify__toast': {
      float: 'right',
      clear: 'right',
      minHeight: 'auto',
      borderRadius: '8px',
      boxShadow: 'default',
      minWidth: '250px',
      width: 'fit-content',
      padding: '8px 8px 8px 16px',
    },
    '.Toastify__toast-body': {
      padding: 0,
    },
    '.Toastify__close-button': {
      display: 'none',
    },
    '.Toastify__toast-theme--light.Toastify__toast--success': {
      color: 'text',
      border: '1px solid',
      borderColor: 'border',
    },
    '.Toastify__toast-theme--light.Toastify__toast--warning': {
      color: 'text',
      backgroundColor: 'system-background-alert',
      border: '1px solid',
      borderColor: 'system-border-alert-medium',
    },
    '.Toastify__toast-theme--light.Toastify__toast--error': {
      color: 'system-text-warning',
      backgroundColor: 'system-background-warning-light',
      border: '1px solid',
      borderColor: 'system-border-warning-light',
    },
    '.slide_in': {
      animation: `${slideIn} 200ms`,
      animationFillMode: 'both',
    },
    '.slide_out': {
      animation: `${slideOut} 200ms`,
      animationFillMode: 'both',
    },
  }),
)

const slideIn = keyframes({
  from: {
    transform: 'translate3d(0, 100%, 0)',
    opacity: 0,
  },
  to: {
    transform: 'translate3d(0, 0, 0)',
    opacity: 1,
  },
})
const slideOut = keyframes({
  from: {
    transform: 'translate3d(0, 0, 0)',
    opacity: 1,
  },
  to: {
    transform: 'translate3d(0, 100%, 0)',
    opacity: 0,
  },
})
const slideAnimation = cssTransition({
  enter: 'slide_in',
  exit: 'slide_out',
})

ToastContainer.defaultProps = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
}

export type ToastType = 'success' | 'warning' | 'error'
const iconSize = { height: 20, width: 20 }
const icons: Record<ToastType, ReactElement> = {
  success: <CheckIcon {...iconSize} />,
  warning: <ExclamationOctagonIcon {...iconSize} />,
  error: <ExclamationCircleFilledIcon {...iconSize} />,
}
export type ToastOptions = {
  type?: ToastType
  btnTitle?: string
  onBtnClick?: (event: React.MouseEvent) => void
  autoClose?: number | false
  closeOnClick?: boolean
  toastId?: string
}

export const toast = (message: string, options?: ToastOptions): void => {
  const { type = 'success' } = options || {}
  baseToast(
    ({ closeToast }) => (
      <ToastBody
        message={message}
        onBtnClick={(e) => {
          closeToast?.()
          options?.onBtnClick?.(e)
        }}
        {...options}
      ></ToastBody>
    ),
    {
      type,
      icon: icons[type],
      transition: slideAnimation,
      ...(options?.toastId != null ? { toastId: options?.toastId } : undefined),
      ...(options?.autoClose != null ? { autoClose: options?.autoClose } : undefined),
      ...(options?.closeOnClick != null ? { closeOnClick: options?.closeOnClick } : undefined),
    },
  )
}
