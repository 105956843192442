import { FloatingTree, useFloatingParentNodeId } from '@floating-ui/react'
import React from 'react'
import { PopoverInterceptorProvider } from './usePopoverInterceptor'

export const floatingTreeNode = <T,>(Component: React.ComponentType<T>) => {
  const WrappedComponent: React.FC<T> = (props) => {
    const parentId = useFloatingParentNodeId()

    if (parentId == null) {
      return (
        <FloatingTree>
          <PopoverInterceptorProvider>
            <Component {...props} />
          </PopoverInterceptorProvider>
        </FloatingTree>
      )
    } else {
      return (
        <PopoverInterceptorProvider>
          <Component {...props} />
        </PopoverInterceptorProvider>
      )
    }
  }
  WrappedComponent.displayName = `FloatingTree(${Component.displayName || Component.name})`
  return WrappedComponent
}
