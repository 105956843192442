/*
 * This file defines 2 major feature flag values sets: "default" and "bleeding-edge".
 * In order to cover most of the feature-flags combinations and ensure no regression when you add a new feature flag we
 * run all our tests twice - once with feature flags set to the "default" values, and then with feature flags set to the
 * "bleeding-edge" values.
 * Individual tests should also override relevant feature flags in order to verify behaviour depending on specific
 * feature flag values. This way we can make sure there are no unexpected/undesired coupling between feature flags,
 * and each feature flag works correctly in any environment.
 *
 * "default" feature flag values are also used in *production* as a fallback when LaunchDarkly is unavailable or not
 * initialised yet.
 *
 * "bleeding-edge" should only contain backward-compatible feature flag values, meaning that all existing tests are
 * either unaffected by the changes OR adjusted accordingly to them.
 * So If you introduce a backward-incompatible change behind a feature flag you may either:
 * a) fix the existing tests in the same PR, and set the feature flag value to the ON value in the "bleeding-edge" set;
 * b) defer the cleanup to another PR, and set the feature flag value to the OFF value in the "bleeding-edge" set.
 */

export enum TrinaryFlagValue {
  DISABLED = 0,
  UPDATES_ONLY = 1,
  QUERIES_AND_UPDATES = 2,
}

export const defaultFeatureFlagValues = {
  'actions-waiting-for-unassigned-notifications': false,
  prototypes: false,
  'prototype-feedback': false,
  'prototype-feedback-tracker': false,
  'copy-plain-text-actions': false,
  'actions-on-goals-in-meeting': false,
  'red-nav-bubbles': false,
  'prototype-home': false,
  'show-meeting-participants-first': false,
  'hyper-simple-meeting-page': false,
  'filter-meeting-participants-by-company-membership': false,
  'actions-dashboard-context': false,
  'prototype-meeting-category': false,
  'prototype-navigation-new-account-picker': false,
  'actions-dashboard-with-done-not-done': false,
  'simple-user-profile': false,
  'reorder-and-highlight-decision-comments': false,
  'highlight-to-make-decision': false,
  'not-done-row-in-action-status-menu': false,
  'dangerously-allow-for-clicking-on-context-in-action-table': false,
  'dangerously-serve-feedback-description-text': false,
  'matts-first-meeting-update': false,
  'record-loom-when-creating-decision': false,
  'viewers-of-comments-can-edit': false,
  'add-action-when-composing-comment': false,
  'actions-on-feedback-default-waiting-for-is-author': false,
  'user-department-and-manager': false,
  'decisions-comments-notifications': false,
  'matt-admin-dashboard-link-in-nav': false,
  'close-drawer-button': false,
  'meeting-section-categories': false,
  'goal-row-context': false,
  admin: false,
  'metadata-summary': false,
  'add-action-in-table-header': false,
  'comments-actions-in-drawers-collapsed-when-empty': false,
  'beta-users-invite-to-beta': false,
  'add-decision-in-table-header': false,
  'editor-image-upload': false,
  'meeting-description-as-tab': false,
  'force-user-select-menu-open-on-render': false,
  'can-create-on-behalf-of-other-users': false,
  'topic-row-context': false,
  'good-things-in-meeting-history': false,
  'curriculum-in-nav': false,
  'silent-not-done-flow-when-rescheduling-actions': false,
  'toggle-open-actions-inline': false,
  'goal-update-in-meeting-review-goals': false,
  'good-things-item-has-actions-and-comments': false,
  'reordered-action-status-menu': false,
  'prevent-closing-quick-add-actions-when-dirty': false,
  'quick-create-topic': false,
  'quick-create-issue': false,
  'good-thing-actions': false,
  'meeting-actions-grouped-by-week': false,
  'simplified-first-meeting': false,
  'new-drawer-views': false,
  'aws-scheduler': false,
  'actions-editable-inline-with-persistent-expand': false,
  'new-topic-drawer-view': false,
  'topics-editable-inline-with-persistent-expand': false,
  'hide-users-from-custom-meeting-sections': false,
  'quick-decisions': false,
  'sign-in-with-google': false,
  'close-add-topic-row-when-topic-created': false,
  'prevent-closing-of-feedback-modal-when-dirty': false,
  'quick-meeting-invite': false,
  'redirect-root-to-meetings': false,
  'maintenance-mode': false,
  'embedded-company-memberships': TrinaryFlagValue.DISABLED,
  'constrained-meeting-container': false,
  'meetings-based-navigation': false,
  'contextual-drawer-navigation': false,
  'non-blocking-drawer': false,
  'actions-hide-waiting-for-column': false,
  'hide-table-headers': false,
  'description-actions': false,
  'reconfigure-drawer-views': false,
  'good-things-open-in-drawer': false,
  'feedback-open-in-drawer': false,
  'confirm-before-delete': false,
  'non-collapsible-sub-groups': false,
  'flattened-meeting-sections': false,
  'move-goal-update-trigger-to-drawer': false,
  'move-action-not-done-to-drawer': false,
  'hide-action-status-toggle': false,
  'action-waiting-for': false,
  'row-more-menu': false,
  'prototype-meeting-canvas': false,
  'remove-meetings-tabs': false,
  'auto-expand-sections': false,
  'convert-topic-to-issue': false,
  'filter-review-actions-and-goals': false,
  'filter-meeting-list': false,
  'split-panel-meeting-page': false,
  'recent-meetings-list': false,
  'meeting-sections-add-row-on-bottom': false,
  'table-row-details': false,
  'presentation-mode': false,
  'animate-actions-appearance': false,
  'meetings-archive': false,
  'meeting-library-as-description': false,
  'prevent-not-done-submit-when-follow-up-dirty': false,
  'decision-parent-meeting': false,
  'action-done-modal': false,
  updates: false,
  'meeting-update-show-all-celebrations': false,
  'row-group-menu': false,
  'drawer-view-actions-collapsible': false,
  'show-topics-updated-at': false,
  'meeting-prep-notification-new-accountability': false,
  'tweak-wording-in-cancel-action-not-done-flow': false,
  'link-calendar-search-meeting': false,
  'decision-box-in-drawer-view': false,
  'meeting-prep': false,
  'show-decision-created-at-update-at': false,
  'action-filter-and-display-menu': false,
  'team-accountability-stats': false,
  'updates-in-main-app': false,
  'create-meeting-default-private': false,
  'meeting-prep-reminder': false,
  'create-issue-default-private': false,
  'user-reviews': false,
  'meeting-prep-multiple-reminder': false,
  'record-loom-on-updates-and-topics': false,
  'home-dashboard': false,
  'update-action-context': false,
  'topic-created-notification': false,
  'new-goal-dashboard-button': false,
  'hide-topic-sections': false,
  'meeting-update-section-filter': false,
  'edit-updates': false,
  'edit-meeting-section-description': false,
  'meeting-prep-reminder-action': false,
  'public-goals-only': false,
  'recurring-action': false,
  'predict-due-date-from-action-title': false,
  'notify-participants-on-publish': false,
  'hide-meeting-template-option': false,
  'meeting-prep-action-section': false,
  'filter-meeting-actions': false,
  'remove-default-section': false,
  'filter-goal-actions': false,
  'meeting-prep-section': false,
  'intercom-integration': false,
  'topic-emoji-reactions': false,
  'flip-comments': false,
  'prevent-old-goal-and-action-updates': false,
  'prioritize-goals': false,
  'record-loom-on-comments': false,
  'onboarding-content': false,
  'meeting-subtitle': false,
  'action-consolidated-metadata': false,
  'recurring-topics': false,
  'draft-topics': false,
  'curate-meeting-prewrite': false,
  'resolve-feedback': false,
  'create-recurring-topic': false,
  'mark-reviewed-on-open': false,
  'action-privacy': false,
  'show-privacy-in-table': false,
  settings: false,
  'write-update-reminder': false,
  'update-app-notifier': false,
  'warn-on-add-topic-without-loom': false,
  'archive-topic-manually': false,
  'action-due-today-notification': false,
  'meeting-prep-manual-reminder': false,
  'ips-warn-if-no-loom': false,
  'remove-issue-section': false,
  'home-insights': false,
}

export type FeatureFlagSet = typeof defaultFeatureFlagValues

export const bleedingEdgeFeatureFlagValues: FeatureFlagSet = {
  // backward-compatible feature flags
  'actions-waiting-for-unassigned-notifications': true,
  'prototype-home': true,
  'show-meeting-participants-first': true,
  'hyper-simple-meeting-page': true,
  'filter-meeting-participants-by-company-membership': true,
  'actions-dashboard-context': true,
  'prototype-meeting-category': true,
  'prototype-navigation-new-account-picker': true,
  'actions-dashboard-with-done-not-done': true,
  'simple-user-profile': true,
  'reorder-and-highlight-decision-comments': true,
  'highlight-to-make-decision': true,
  'not-done-row-in-action-status-menu': true,
  'dangerously-allow-for-clicking-on-context-in-action-table': true,
  'dangerously-serve-feedback-description-text': true,
  'matts-first-meeting-update': true,
  'record-loom-when-creating-decision': true,
  'viewers-of-comments-can-edit': true,
  'add-action-when-composing-comment': true,
  'actions-on-feedback-default-waiting-for-is-author': true,
  'user-department-and-manager': true,
  'decisions-comments-notifications': true,
  'matt-admin-dashboard-link-in-nav': true,
  'close-drawer-button': true,
  'meeting-section-categories': true,
  'goal-row-context': true,
  'metadata-summary': true,
  'comments-actions-in-drawers-collapsed-when-empty': true,
  'beta-users-invite-to-beta': true,
  'add-decision-in-table-header': true,
  'editor-image-upload': true,
  'meeting-description-as-tab': true,
  'force-user-select-menu-open-on-render': true,
  'can-create-on-behalf-of-other-users': true,
  'topic-row-context': true,
  'good-things-in-meeting-history': true,
  'curriculum-in-nav': true,
  'silent-not-done-flow-when-rescheduling-actions': true,
  'goal-update-in-meeting-review-goals': true,
  'good-things-item-has-actions-and-comments': true,
  'reordered-action-status-menu': true,
  'prevent-closing-quick-add-actions-when-dirty': true,
  'quick-create-topic': true,
  'quick-create-issue': true,
  'good-thing-actions': true,
  'meeting-actions-grouped-by-week': true,
  'simplified-first-meeting': true,
  'aws-scheduler': true,
  'prototype-feedback-tracker': true,
  'hide-users-from-custom-meeting-sections': true,
  'quick-decisions': true,
  'sign-in-with-google': true,
  'close-add-topic-row-when-topic-created': true,
  'prevent-closing-of-feedback-modal-when-dirty': true,
  'quick-meeting-invite': true,
  'redirect-root-to-meetings': true,
  'embedded-company-memberships': TrinaryFlagValue.QUERIES_AND_UPDATES,
  'constrained-meeting-container': true,
  'meetings-based-navigation': true,
  'contextual-drawer-navigation': true,
  'non-blocking-drawer': true,
  'actions-hide-waiting-for-column': true,
  'hide-table-headers': true,
  'description-actions': true,
  'confirm-before-delete': true,
  'non-collapsible-sub-groups': true,
  'flattened-meeting-sections': true,
  'move-goal-update-trigger-to-drawer': true,
  'move-action-not-done-to-drawer': true,
  'prototype-meeting-canvas': true,
  'remove-meetings-tabs': true,
  'auto-expand-sections': true,
  'convert-topic-to-issue': true,
  'filter-review-actions-and-goals': true,
  'recent-meetings-list': true,
  'table-row-details': true,
  'presentation-mode': true,
  'animate-actions-appearance': true,
  'meetings-archive': true,
  'prevent-not-done-submit-when-follow-up-dirty': true,
  'decision-parent-meeting': true,
  'action-done-modal': true,
  'meeting-update-show-all-celebrations': true,
  'row-group-menu': true,
  'drawer-view-actions-collapsible': true,
  'show-topics-updated-at': true,
  'reconfigure-drawer-views': true,
  'new-topic-drawer-view': true,
  'new-drawer-views': true,
  'prototype-feedback': true,
  'meeting-prep-notification-new-accountability': true,
  'link-calendar-search-meeting': true,
  'show-decision-created-at-update-at': true,
  'team-accountability-stats': true,
  'meeting-prep-reminder': true,
  'create-issue-default-private': true,
  'user-reviews': true,
  'record-loom-on-updates-and-topics': true,
  'topic-created-notification': true,
  'edit-updates': true,
  'hide-topic-sections': true,
  'meeting-prep-reminder-action': true,
  'predict-due-date-from-action-title': true,
  'notify-participants-on-publish': true,
  'meeting-prep-action-section': true,
  'topic-emoji-reactions': true,
  'onboarding-content': true,
  'meeting-subtitle': true,
  'resolve-feedback': true,
  settings: true,
  'write-update-reminder': true,
  'archive-topic-manually': true,
  'action-due-today-notification': true,
  'meeting-prep-manual-reminder': true,
  'action-consolidated-metadata': true,
  'update-action-context': true,
  'decision-box-in-drawer-view': true,

  // backward-incompatible feature flags
  'remove-issue-section': false,
  'action-privacy': false,
  'create-recurring-topic': false,
  'mark-reviewed-on-open': false,
  'draft-topics': false,
  'curate-meeting-prewrite': false,
  'show-privacy-in-table': false,
  'record-loom-on-comments': false,
  'recurring-topics': false,
  'meeting-prep-section': false,
  'intercom-integration': false,
  'filter-goal-actions': false,
  'remove-default-section': false,
  'filter-meeting-actions': false,
  'hide-meeting-template-option': false,
  'recurring-action': false,
  'public-goals-only': false,
  'edit-meeting-section-description': false,
  'meeting-update-section-filter': false,
  'meeting-prep-multiple-reminder': false,
  'create-meeting-default-private': false,
  'tweak-wording-in-cancel-action-not-done-flow': false,
  'feedback-open-in-drawer': false,
  'action-waiting-for': false,
  'row-more-menu': false,
  'filter-meeting-list': false,
  updates: false,
  'actions-on-goals-in-meeting': false,
  'red-nav-bubbles': false,
  'copy-plain-text-actions': false,
  prototypes: false,
  'toggle-open-actions-inline': false,
  'actions-editable-inline-with-persistent-expand': false,
  'topics-editable-inline-with-persistent-expand': false,
  'add-action-in-table-header': false,
  'good-things-open-in-drawer': false,
  'hide-action-status-toggle': false,
  'split-panel-meeting-page': false,
  'meeting-sections-add-row-on-bottom': false,
  'meeting-library-as-description': false,
  'meeting-prep': false,
  'action-filter-and-display-menu': false,
  'updates-in-main-app': false,
  'home-dashboard': false,
  'new-goal-dashboard-button': false,
  'flip-comments': false,
  'prevent-old-goal-and-action-updates': false,
  'prioritize-goals': false,
  'update-app-notifier': false,
  'warn-on-add-topic-without-loom': false,
  'ips-warn-if-no-loom': false,
  'home-insights': false,

  // this is a permanent flag to give users admin permissions
  admin: false,

  // do not enable
  'maintenance-mode': false,
}
