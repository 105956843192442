/* eslint-disable no-console */
import { UndoResult } from './types'

export const logger = (
  action: 'execute' | 'undo',
  command: string,
  history: Array<UndoResult<unknown>>,
  value?: unknown,
) => {
  if (process.env.NODE_ENV !== 'production' && process.env.JEST_WORKER_ID === undefined) {
    console.groupCollapsed(
      `%c${action} %c${command}`,
      'color: gray; font-weight: normal',
      'color: inherit; font-weight: bold',
    )
    if (value) {
      console.log('args    ', value)
    }
    console.log(
      'history ',
      history.map(({ origin }) => origin.id),
    )
    console.groupEnd()
  }
}
