import { Avatar, formatUserDisplayName, UserSelectItem, UserSelectList } from '@mm/company-ui'
import { ListKeyboardDelegate, useSelectableCollection } from '@react-aria/selection'
import { SuggestionKeyDownProps } from '@tiptap/suggestion'
import isHotkey from 'is-hotkey'
import _ from 'lodash'
import React, { Key, useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import { useFilter } from 'react-aria'
import { Item, useSingleSelectListState } from 'react-stately'
import { MentionListOptionDataFragment } from '../../../../../gen/graphql/documents'

export type MentionListOptionsProps = {
  editorViewRef: React.RefObject<HTMLElement>
  query: string
  items: Array<{ node: MentionListOptionDataFragment }>
  onItemSelected?: (item: MentionListOptionDataFragment) => void
  onDismiss?: () => void
}

export type MentionListOptionsRef = {
  onKeyDown: (event: SuggestionKeyDownProps) => void
}

export const MentionListOptions = React.forwardRef<MentionListOptionsRef, MentionListOptionsProps>(
  ({ editorViewRef, query, items, onItemSelected, onDismiss }, ref) => {
    const { contains } = useFilter({ sensitivity: 'base' })
    const state = useSingleSelectListState<UserSelectItem>({
      children: (item) => (
        <Item textValue={item.name}>
          {item.avatar}
          {item.name}
        </Item>
      ),
      items: _(items)
        .orderBy(({ node }) => (node.isMeetingParticipant ? 0 : 1)) // participants first
        .map(({ node }) => {
          return {
            id: node.id,
            name: formatUserDisplayName(node),
            detail: node.isMeetingParticipant === false ? 'Not a participant' : undefined,
            avatar: <Avatar name={formatUserDisplayName(node)} />,
          }
        })
        .filter(({ name }) => contains(name, query))
        .slice(0, 5)
        .value(),
      onSelectionChange: (key: Key) => {
        const item = items.find(({ node }) => node.id === key)

        if (!item) return

        onItemSelected?.(item.node)
      },
    })

    const listBoxRef = useRef<HTMLUListElement>(null)
    const delegate = useMemo(
      () => new ListKeyboardDelegate(state.collection, state.disabledKeys, listBoxRef),
      [state.collection, state.disabledKeys, listBoxRef],
    )
    const { collectionProps } = useSelectableCollection({
      selectionManager: state.selectionManager,
      keyboardDelegate: delegate,
      disallowTypeAhead: true,
      disallowEmptySelection: true,
      shouldFocusWrap: true,
      ref: editorViewRef,
      isVirtualized: true,
    })

    useEffect(() => {
      const focusedKey = state.selectionManager.focusedKey
      const firstKey = state.collection.getFirstKey()

      if ((!state.collection.getItem(focusedKey) || focusedKey !== firstKey) && firstKey) {
        state.selectionManager.setFocusedKey(firstKey)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items])

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (isHotkey('Enter', event)) {
          state.selectionManager.replaceSelection(state.selectionManager.focusedKey)
          return true
        }
        if (isHotkey('Escape', event)) {
          onDismiss?.()
          event.stopPropagation()
          return true
        }
        // TODO Dirty workaround. How to call react event handler with native event?
        collectionProps.onKeyDown?.(event as never)
        return false
      },
    }))

    return <UserSelectList shouldUseVirtualFocus listBoxRef={listBoxRef} state={state} aria-label="users" />
  },
)

MentionListOptions.displayName = 'MentionListOptions'
