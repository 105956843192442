import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { isValidMotionProp, motion } from 'framer-motion'
import _ from 'lodash'
import React from 'react'
import { css, StylePropertyValue } from 'theme-ui'

type FlexDirectionProps =
  | {
      column?: true
      row?: false
      reverse?: boolean
      direction?: undefined
    }
  | {
      row?: true
      column?: false
      reverse?: boolean
      direction?: undefined
    }
  | {
      direction?: StylePropertyValue<React.CSSProperties['flexDirection']>
      row?: undefined
      column?: undefined
      reverse?: undefined
    }

export type FlexOwnProps = FlexDirectionProps & {
  justify?: StylePropertyValue<React.CSSProperties['justifyContent']>
  align?: StylePropertyValue<React.CSSProperties['alignItems']>
  alignSelf?: StylePropertyValue<React.CSSProperties['alignSelf']>
  alignContent?: StylePropertyValue<React.CSSProperties['alignContent']>
  wrap?: StylePropertyValue<React.CSSProperties['flexWrap']> | boolean
  gap?: StylePropertyValue<number | `${number}${'px' | 'em' | 'rem'}`>
  grow?: StylePropertyValue<number> | boolean
  shrink?: StylePropertyValue<number> | boolean
  basis?: StylePropertyValue<
    number | 'auto' | 'content' | 'fit-content' | 'max-content' | 'min-content' | `${number}${'px' | 'em' | 'rem'}`
  >
  inline?: boolean
}

export type FlexProps = React.ComponentPropsWithRef<typeof Flex>

export const Flex = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop) && !_.includes(['wrap'], prop),
})<FlexOwnProps>(
  ({ column, reverse, direction, justify, align, alignSelf, alignContent, wrap, gap, grow, shrink, basis, inline }) =>
    css({
      display: inline ? 'inline-flex' : 'flex',
      flexDirection: direction ?? (column ? (reverse ? 'column-reverse' : 'column') : reverse ? 'row-reverse' : 'row'),
      justifyContent: justify,
      alignItems: align,
      alignSelf,
      alignContent,
      flexWrap: typeof wrap === 'boolean' ? (wrap ? 'wrap' : 'nowrap') : wrap,
      flexGrow: typeof grow === 'boolean' ? (grow ? 1 : 0) : grow,
      flexShrink: typeof shrink === 'boolean' ? (shrink ? 1 : 0) : shrink,
      flexBasis: basis,
      minWidth: shrink ? 0 : 'auto',
      gap,
    }),
)

export const MotionFlex = styled(motion.div, {
  shouldForwardProp: (prop) => isPropValid(prop) || isValidMotionProp(String(prop)),
})<FlexOwnProps>(
  ({ column, reverse, direction, justify, align, alignSelf, alignContent, wrap, gap, grow, shrink, basis }) =>
    css({
      display: 'flex',
      flexDirection: direction ?? (column ? (reverse ? 'column-reverse' : 'column') : reverse ? 'row-reverse' : 'row'),
      justifyContent: justify,
      alignItems: align,
      alignSelf,
      alignContent,
      flexWrap: typeof wrap === 'boolean' ? (wrap ? 'wrap' : 'nowrap') : wrap,
      flexGrow: typeof grow === 'boolean' ? (grow ? 1 : 0) : grow,
      flexShrink: typeof shrink === 'boolean' ? (shrink ? 1 : 0) : shrink,
      flexBasis: basis,
      gap,
    }),
)
