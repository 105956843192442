import {
  GlobalUpdatesSubscriptionDataFragment,
  GlobalUpdatesSubscriptionDocument,
} from '../../../gen/graphql/documents'
import { useSubscription } from '../../apollo'
import { defineEventBusTopic, useEmitEventBusEvent } from '../../eventbus'

export type UpdatesUpdatedEvent =
  | {
      type: 'deleted'
      id: string
    }
  | {
      type: 'upserted'
      update: GlobalUpdatesSubscriptionDataFragment
    }

export const UpdatesUpdatedTopic = defineEventBusTopic<UpdatesUpdatedEvent>('updatesUpdated')

export const GlobalUpdatesSubscription = () => {
  const publish = useEmitEventBusEvent(UpdatesUpdatedTopic)

  useSubscription(GlobalUpdatesSubscriptionDocument, {
    onSubscriptionData: ({ subscriptionData: { data }, client }) => {
      const evictFromCache = (id: string) => {
        publish({ type: 'deleted', id })

        client.cache.evict({
          id: client.cache.identify({ __typename: 'Update', id }),
        })
        client.cache.gc()
      }

      switch (data?.updatesUpdated?.__typename) {
        case 'UpdateDeleted':
          evictFromCache(data.updatesUpdated.deletedUpdateId)
          break
        case 'UpdateAccessRevoked':
          evictFromCache(data.updatesUpdated.revokedUpdateId)
          break
        case 'Update':
          publish({ type: 'upserted', update: data.updatesUpdated })
          break
      }
    },
  })

  return null
}
