import { useFeatureFlags } from '@mm/company-ui'
import React from 'react'
import { GlobalActionsSubscription } from '../modules/actions'
import { GlobalCommentsSubscription } from '../modules/comments'
import { GlobalDecisionsSubscription } from '../modules/decisions'
import { GlobalGoalsSubscription } from '../modules/goals'
import { GlobalGoodThingsSubscription } from '../modules/goodThing'
import { GlobalMeetingPrepSubscription } from '../modules/meetingPrep'
import { GlobalMeetingsSubscription } from '../modules/meetings'
import { GlobalFeedbacksSubscription } from '../modules/prototypeFeedback'
import { GlobalTopicsSubscription } from '../modules/topics'
import { GlobalUpdatesSubscription } from '../modules/updates'
import { useActiveUser } from '../modules/users'

export const GlobalSubscriptions = () => {
  const activeUser = useActiveUser()
  const { updates, meetingPrep } = useFeatureFlags()

  if (activeUser == null) return null

  return (
    <>
      <GlobalActionsSubscription />
      <GlobalCommentsSubscription />
      <GlobalDecisionsSubscription />
      <GlobalFeedbacksSubscription />
      <GlobalGoalsSubscription />
      <GlobalGoodThingsSubscription />
      {updates && <GlobalUpdatesSubscription />}
      {meetingPrep && <GlobalMeetingPrepSubscription />}
      <GlobalMeetingsSubscription />
      <GlobalTopicsSubscription />
    </>
  )
}
