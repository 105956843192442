import { useAuth0 } from '@auth0/auth0-react'
import { defaultFeatureFlagValues } from '@mm/common'
import { LDContext } from 'launchdarkly-js-sdk-common'
import { camelCaseKeys, LDProvider } from 'launchdarkly-react-client-sdk'
import { Provider as OfflineProvider, ReactSdkContext } from 'launchdarkly-react-client-sdk/lib/context'
import React, { useMemo, useState } from 'react'
import { useActiveCompanyIdOrNull } from '../companies'

export type FeatureFlagsProviderProps = {
  children?: React.ReactNode
}

const RealFeatureFlagsProvider = ({ children }: FeatureFlagsProviderProps) => {
  const { user, isLoading } = useAuth0()
  const { activeCompanyId, activeCompanyName } = useActiveCompanyIdOrNull()

  const context: LDContext | undefined = user?.sub
    ? {
        kind: 'multi',
        user: {
          anonymous: false,
          key: user.sub,
          name: user.name ?? user.preferred_username,
          email: user.email,
        },
        ...(activeCompanyId && {
          company: {
            key: activeCompanyId,
            name: activeCompanyName ?? undefined,
          },
        }),
      }
    : !isLoading
    ? {
        kind: 'user',
        anonymous: true,
      }
    : undefined

  return (
    <LDProvider
      key={`${user?.sub}::${activeCompanyId}`}
      clientSideID={process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID}
      options={{ bootstrap: 'localStorage' }}
      context={context}
      deferInitialization
      // request only the flags known by this version of the code
      // only the keys of the passed object are taken into account, values are ignored
      flags={defaultFeatureFlagValues}
    >
      {children}
    </LDProvider>
  )
}

const FakeFeatureFlagsProvider = ({ children }: FeatureFlagsProviderProps) => {
  const [featureFlagOverrides] = useState(() => localStorage.getItem('feature-flag-overrides'))
  const value = useMemo<ReactSdkContext | null>(() => {
    try {
      return featureFlagOverrides
        ? {
            flags: camelCaseKeys(JSON.parse(featureFlagOverrides) as Record<string, unknown>),
            flagKeyMap: {},
          }
        : null
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to parse feature flag overrides JSON', featureFlagOverrides, e)
      return null
    }
  }, [featureFlagOverrides])
  if (!value) {
    return <RealFeatureFlagsProvider>{children}</RealFeatureFlagsProvider>
  } else {
    return <OfflineProvider value={value}>{children}</OfflineProvider>
  }
}

export const FeatureFlagsProvider: typeof RealFeatureFlagsProvider =
  process.env.NEXT_PUBLIC_MAY_OVERRIDE_FEATURE_FLAGS === 'true' ? FakeFeatureFlagsProvider : RealFeatureFlagsProvider
