import EventEmitter from 'eventemitter3'
import React, { useCallback, useContext, useEffect, useState } from 'react'

const eventBusContext = React.createContext<EventEmitter | null>(null)

export const EventBusProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [emitter] = useState(() => new EventEmitter())

  return <eventBusContext.Provider value={emitter}>{children}</eventBusContext.Provider>
}

export type EventBusTopic<T> = string & { __hack?: () => T }

export const useOnEventBusEvent = <T,>(topic: EventBusTopic<T>, callback: (event: T) => void) => {
  const emitter = useContext(eventBusContext)

  useEffect(() => {
    if (emitter == null) return undefined

    emitter.on(topic, callback)
    return () => {
      emitter.off(topic, callback)
    }
  }, [emitter, topic, callback])
}

export const useEmitEventBusEvent = <T,>(topic: EventBusTopic<T>) => {
  const emitter = useContext(eventBusContext)

  return useCallback(
    (event: T) => {
      if (emitter == null) throw new Error('must be called by a descendant of EventBusProvider')

      emitter.emit(topic, event)
    },
    [emitter, topic],
  )
}

export const defineEventBusTopic = <T,>(name: string) => name as EventBusTopic<T>
