import {
  GlobalMeetingsSubscriptionDataFragment,
  GlobalMeetingsSubscriptionDocument,
} from '../../../gen/graphql/documents'
import { useSubscription } from '../../apollo'
import { defineEventBusTopic, useEmitEventBusEvent } from '../../eventbus'

export type MeetingsUpdatedEvent =
  | {
      type: 'deleted'
      id: string
    }
  | {
      type: 'upserted'
      meeting: GlobalMeetingsSubscriptionDataFragment
    }

export const MeetingsUpdatedTopic = defineEventBusTopic<MeetingsUpdatedEvent>('meetingsUpdated')

export const GlobalMeetingsSubscription = () => {
  const publish = useEmitEventBusEvent(MeetingsUpdatedTopic)

  useSubscription(GlobalMeetingsSubscriptionDocument, {
    onSubscriptionData: ({ subscriptionData: { data }, client }) => {
      const evictFromCache = (id: string) => {
        publish({ type: 'deleted', id })

        client.cache.evict({
          id: client.cache.identify({ __typename: 'Meeting', id }),
        })
        client.cache.gc()
      }

      switch (data?.meetingsUpdated?.__typename) {
        case 'MeetingDeleted':
          evictFromCache(data.meetingsUpdated.deletedMeetingId)
          break
        case 'MeetingAccessRevoked':
          evictFromCache(data.meetingsUpdated.revokedMeetingId)
          break
        case 'Meeting':
          publish({ type: 'upserted', meeting: data.meetingsUpdated })
          break
      }
    },
  })

  return null
}
