export type CommentFragment = {
  id: string
  createdAt: number
}

export type CommentsFragment = {
  edges: Array<{
    node: CommentFragment
  }>
}

export const addCommentToList = (comments: CommentsFragment | null | undefined, comment: CommentFragment) => {
  if (comments) {
    for (let i = 0; i < comments.edges.length; i++) {
      const edge = comments.edges[i]
      if (edge) {
        if (edge.node.id === comment.id) {
          return
        }
        if (edge.node.createdAt <= comment.createdAt) {
          comments.edges.splice(i, 0, {
            node: comment,
          })
          return
        }
      }
    }
    comments.edges.push({
      node: comment,
    })
  }
}
