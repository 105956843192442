import { createContext, useContext } from 'react'

export type ActiveResourceContextValue = {
  type: string
  id: string
  privacy: 'PUBLIC' | 'PRIVATE'
}

const activeResourceContext = createContext<ActiveResourceContextValue | null>(null)

export const ActiveResourceContextProvider = activeResourceContext.Provider

export const useActiveResource = () => {
  const contextValue = useContext(activeResourceContext)
  return contextValue
}
