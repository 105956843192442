import styled from '@emotion/styled'
import { CloseIcon } from '@mm/company-ui-icons'
import React from 'react'
import ReactModal from 'react-modal'
import { css } from 'theme-ui'
import { Button } from '../Button'
import { Card } from '../Card'
import { Flex } from '../Flex'
import { Heading } from '../Text'
import { ModalHeader } from './ModalHeader'
import { ModalOverlay } from './ModalOverlay'

export const MODAL_SECTION_PADDING = 2
export const ModalSection = styled(Flex)<{ border?: boolean }>(({ border = true }) =>
  css({
    padding: MODAL_SECTION_PADDING,
    borderTop: border ? '1px solid' : undefined,
    borderColor: 'border',
  }),
)

export type ModalProps = {
  variant?: 'default' | 'full'
  isOpen?: boolean
  children?: React.ReactNode
  className?: string
  title?: string
  showCloseIcon?: boolean
  overlayStyle?: React.CSSProperties
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void
  onAfterOpen?: ReactModal.OnAfterOpenCallback
  onAfterClose?: () => void
  shouldCloseOnOverlayClick?: boolean
  shouldCloseOnEsc?: boolean
}

export const BaseModal = styled(Card)<{ modalVariant: ModalProps['variant'] }>(({ modalVariant = 'default' }) =>
  css({
    maxWidth: modalVariant === 'default' ? '598px' : '968px',
    padding: 0,
    width: 'auto',
    margin: '0 auto',
    border: 'none',
    position: 'absolute',
    inset: '40px',
    background: 'white',
    overflow: 'auto',
    borderRadius: 'default',
    outline: 'none',
    height: 'max-content',
  }),
)

export const Modal = ({
  isOpen = false,
  title = '',
  variant = 'default',
  showCloseIcon = true,
  onRequestClose,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  onAfterOpen,
  onAfterClose,
  children,
  className,
  overlayStyle,
  ...props
}: ModalProps) => {
  return (
    <ReactModal
      {...props}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      isOpen={isOpen}
      ariaHideApp={false}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      onRequestClose={onRequestClose}
      className={className}
      overlayClassName="_"
      contentLabel={title}
      overlayElement={({ ref, ...props }, contentElement) => (
        <ModalOverlay ref={ref} {...props} style={overlayStyle}>
          {contentElement}
        </ModalOverlay>
      )}
      contentElement={({ ref, ...props }, children) => {
        const modalProps = Object.assign({}, props)
        delete modalProps['style']

        return (
          <BaseModal ref={ref} modalVariant={variant} {...modalProps}>
            {title || showCloseIcon ? (
              <ModalHeader>
                {title ? <Heading level={4}>{title}</Heading> : <div></div>}

                {showCloseIcon && onRequestClose && (
                  <Button startIcon={<CloseIcon />} onClick={onRequestClose} variant="muted" size="small" textHidden>
                    Close Modal
                  </Button>
                )}
              </ModalHeader>
            ) : null}
            {children}
          </BaseModal>
        )
      }}
    >
      {children}
    </ReactModal>
  )
}
