import * as Sentry from '@sentry/node'

export const initSentry = () => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
      defaultIntegrations: process.env.NODE_ENV === 'production' ? undefined : false,
      // enabled: process.env.NODE_ENV === 'production',
      environment: process.env['TARGET_ENV'],
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    })
  }
}

export * from './components/ErrorPage'
