import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { LoadingIcon } from '@mm/company-ui-icons'
import React from 'react'
import { css } from 'theme-ui'
import { variant } from '../../helpers'
import { FloatingTooltip } from '../FloatingUI'
import { PopupWrapper } from '../Popup'
import { Pressable } from '../Pressable'

export type ButtonProps = Omit<React.ComponentPropsWithoutRef<'button'>, keyof ButtonOwnProps> & ButtonOwnProps

type ButtonOwnProps = {
  variant?: 'accent' | 'default' | 'muted' | 'warning' | 'alert' | 'success' | 'accent-inverted'
  size?: 'small' | 'default'
  children: React.ReactText
  textHidden?: boolean
  startIcon?: React.ReactElement
  endIcon?: React.ReactElement
  disabled?: boolean
  loading?: boolean
  showTooltip?: boolean
}

type BaseButtonProps = Pick<ButtonOwnProps, 'variant' | 'startIcon' | 'endIcon' | 'disabled' | 'loading'> & {
  hasText?: boolean
  sizeVariant?: ButtonProps['size']
}

const BaseButton = styled(Pressable, {
  // Dont forward `loading` prop down to base HTML element.
  shouldForwardProp: (prop) => isPropValid(prop.toString()) && prop !== 'loading',
})<BaseButtonProps>(
  ({ loading, disabled, startIcon, endIcon }) =>
    css({
      fontFamily: 'body',
      fontSize: 1,
      fontWeight: 500,
      lineHeight: 1.5,
      display: 'flex',
      flexShrink: 0,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 1,
      opacity: loading ? 0.8 : 1,
      pointerEvents: loading || disabled ? 'none' : 'auto',
      position: 'relative',
      borderRadius: '4px',

      transition: '0.2s cubic-bezier(0.3, 0, 0.5, 1)',
      transitionProperty: 'opacity, color, background-color',
      boxShadow: `inset 0 0 0 0 transparent`,

      '.overlay': {
        position: 'absolute',
        left: '50%',
        marginLeft: '-10px',
        opacity: loading ? 1 : 0,
      },

      span: {
        opacity: !startIcon && !endIcon && loading ? 0 : 1,
      },
    }),
  variant({
    prop: 'variant',
    variants: {
      accent: {
        color: 'text-inverted',
        backgroundColor: 'accent-background',

        '&:hover': {
          backgroundColor: 'accent-background-hover',
        },
        '&:disabled': {
          backgroundColor: 'accent-background-medium',
        },
        '&:focus': {
          outlineStyle: 'solid',
          outlineWidth: '4px',
          outlineColor: 'accent-border-light',
        },
      },
      'accent-inverted': {
        color: 'accent-text',
        backgroundColor: 'accent-background-light',
        border: '1px solid',
        borderColor: 'accent-background-medium',
        '&:hover': {
          backgroundColor: 'accent-background-light-hover',
          color: 'text',
        },
        '&:disabled': {
          color: 'text-disabled',
        },
        '&:focus': {
          color: 'text',
          outlineStyle: 'solid',
          outlineWidth: '4px',
          outlineColor: 'accent-border-light',
        },
      },
      default: {
        color: 'text',
        backgroundColor: 'background',
        boxShadow: 'inset 0 0 0 1px #E4E5E7',
        '&:hover': {
          backgroundColor: 'background-light-alpha',
        },
        '&:disabled': {
          color: 'text-disabled-medium',
        },
        '&:focus': {
          outlineStyle: 'solid',
          outlineWidth: '4px',
          outlineColor: 'border',
        },
      },
      muted: {
        color: 'text-light',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'background-light-alpha',
          color: 'text',
        },
        '&:disabled': {
          color: 'text-disabled',
        },
        '&:focus': {
          color: 'text',
          outlineStyle: 'solid',
          outlineWidth: '4px',
          outlineColor: 'border',
        },
      },
      warning: {
        color: 'text-inverted',
        backgroundColor: 'system-background-warning-medium',
        '&:hover': {
          backgroundColor: 'system-background-warning',
        },
        '&:disabled': {
          backgroundColor: 'system-background-warning-light',
        },
        '&:focus': {
          outlineStyle: 'solid',
          outlineWidth: '4px',
          outlineColor: 'system-border-warning-light',
        },
      },
      alert: {
        color: 'system-text-alert',
        backgroundColor: 'system-background-alert',
        '&:hover': {
          backgroundColor: 'system-background-alert-medium',
        },
        '&:disabled': {
          backgroundColor: 'system-background-alert-light',
        },
        '&:focus': {
          outlineStyle: 'solid',
          outlineWidth: '4px',
          outlineColor: 'system-border-alert-medium',
        },
      },
      success: {
        color: 'system-text-success',
        backgroundColor: 'system-background-success',
        '&:hover': {
          backgroundColor: 'system-background-success-medium',
        },
        '&:disabled': {
          backgroundColor: 'system-background-success-light',
        },
        '&:focus': {
          outlineStyle: 'solid',
          outlineWidth: '4px',
          outlineColor: 'system-border-success-medium',
        },
      },
    },
  }),
  ({ hasText }) =>
    variant({
      prop: 'sizeVariant',
      variants: {
        default: {
          height: '36px',
          paddingLeft: hasText ? 2 : 1.25,
          paddingRight: hasText ? 2 : 1.25,
        },
        small: {
          height: '28px',
          paddingX: hasText ? 2 : 0.75,
          fontSize: 0,
        },
      },
    }),
)

const ICON_PROPS = {
  'aria-hidden': 'true',
  focusable: 'false',
  style: {
    fontSize: '16px',
    lineHeight: 1,
  },
} as const

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      textHidden,
      variant = 'default',
      loading = false,
      size = 'default',
      startIcon,
      endIcon,
      onClick,
      showTooltip = true,
      ...props
    }: ButtonProps,
    ref,
  ) => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (loading || props.disabled) {
        event.preventDefault()
        return
      }
      onClick?.(event)
    }

    const text = String(children)

    const startIconNode = startIcon ? React.cloneElement(loading ? <LoadingIcon /> : startIcon, ICON_PROPS) : null
    const endIconNode = endIcon ? React.cloneElement(loading ? <LoadingIcon /> : endIcon, ICON_PROPS) : null
    const isLabelWithNoIcons = !startIcon && !endIcon

    const renderButton = (
      getButtonProps: (props: React.ComponentPropsWithRef<'button'>) => React.ComponentPropsWithRef<'button'>,
    ) => (
      <BaseButton
        aria-label={text || undefined}
        hasText={!textHidden}
        startIcon={startIcon}
        endIcon={endIcon}
        sizeVariant={size}
        loading={loading}
        variant={variant}
        {...getButtonProps({
          ref,
          onClick: handleClick,
          ...props,
        })}
      >
        {startIconNode}
        {loading && isLabelWithNoIcons ? <LoadingIcon {...ICON_PROPS} className="overlay" /> : null}
        {!textHidden && <span>{text}</span>}
        {endIconNode}
      </BaseButton>
    )

    return textHidden && showTooltip ? (
      <FloatingTooltip
        tooltip={<PopupWrapper sx={{ fontSize: 0, color: 'text' }}>{text}</PopupWrapper>}
        placement="top"
        openDelayMs={800}
      >
        {({ getReferenceProps }) => renderButton(getReferenceProps)}
      </FloatingTooltip>
    ) : (
      renderButton((props) => props)
    )
  },
)

Button.displayName = 'Button'
