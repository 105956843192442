import { useFeatureFlags } from '@mm/company-ui'
import React from 'react'
import { MaintenanceModal } from './MaintenanceModal'

const context = React.createContext({})

type MaintenanceModeProviderProps = {
  children: React.ReactNode
}

export const MaintenanceModeProvider: React.FC<MaintenanceModeProviderProps> = ({ children }) => {
  const { maintenanceMode: value } = useFeatureFlags()

  return (
    <context.Provider value={value}>
      {children}
      {value && <MaintenanceModal />}
    </context.Provider>
  )
}
