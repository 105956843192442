/* eslint-disable */
import { gql } from '@apollo/client'

export const typeDefs = gql`
directive @connection(key: String!) on FIELD

input AcceptMeetingInviteInput {
  token: String!
}

union AcceptMeetingInviteResult = AccessDeniedError | Meeting | NotFoundError

type AccessDeniedError implements Error {
  message: String!
}

type AccessRevoked {
  message: String!
}

type Action implements CommentsContainer & Node {
  actionUpdates: [ActionUpdate]
  assignee: User!
  comments(after: String, authorId: ID, first: Int!): CommentsConnection
  company: Company!
  createdAt: Timestamp!
  dateCompleted: Timestamp
  dateRescheduled: Boolean!
  dateRescheduledAt: Timestamp
  decisions(after: String, first: Int!): DecisionsConnection
  description: Document!
  dueAt: Timestamp
  followUpActions(after: String, first: Int!): ActionConnection
  followUpTo: Action
  goals(after: String, first: Int!): GoalsConnection
  goodThings(after: String, first: Int!): GoodThingConnection
  id: ID!
  isPrivate: Boolean
  meetings(after: String, first: Int!): MeetingsConnection
  owner: User!
  parent: ActionParent

  """
  If the action was created during a meeting, this field references that meeting
  """
  parentMeeting: Meeting
  privacy: ActionPrivacyStatus!
  prototypeFeedback(after: String, first: Int!): PrototypeFeedbackConnection
  repeat: ActionRepeatType
  status: ActionStatus!
  statusUpdatedAt: Timestamp
  title: String!
  titleHtml: String!
  topics(after: String, first: Int!, status: TopicStatus): TopicsConnection
  updatedAt: Timestamp!
  updates(after: String, first: Int!, status: TopicStatus): UpdatesConnection
  waitingFor: User!
}

enum ActionAccess {
  READ_ONLY
  READ_WRITE
}

type ActionAccessRevoked {
  revokedActionId: ID!
}

type ActionConnection implements Connection {
  edges: [ActionEdge!]!
  pageInfo: PageInfo!
}

input ActionContextInput {
  ref: ID!
  type: ActionParentType!
}

type ActionDeleted {
  deletedActionId: ID!
}

type ActionEdge implements Edge {
  node: Action!
}

type ActionList {
  actions: [Action!]!
}

enum ActionOrderBy {
  CREATED_AT_ASC
  CREATED_AT_DESC
  DATE_COMPLETED_ASC
  DATE_COMPLETED_DESC
  DUE_AT_ASC
  DUE_AT_DESC
  UPDATED_AT_ASC
  UPDATED_AT_DESC
}

union ActionParent = Decision | Goal | GoodThing | Meeting | PrototypeFeedback | Topic | Update

enum ActionParentType {
  Decision
  Goal
  GoodThing
  Meeting
  Topic
  Update
}

enum ActionPrivacyStatus {
  PRIVATE
  PUBLIC
}

enum ActionRepeatEnd {
  AFTER_N_TIMES
  ENDLESSLY
  UNTIL_DATE
}

enum ActionRepeatType {
  DAILY
  MONTHLY
  NONE
  WEEKLY
  YEARLY
}

enum ActionStatus {
  ACTIVE
  CANCELLED
  DONE
  ON_HOLD
}

type ActionUpdate implements Node {
  action: Action!
  createdAt: Timestamp!
  done: Boolean
  id: ID!
  nextActions: [Action]
  nextHtml: String
  reasonHtml: String
  repeat: Boolean
  repeatAt: Timestamp
  source: ActionUpdateSource
  status: ActionStatus
  updatedAt: Timestamp!
}

union ActionUpdateSource = MeetingPrep | Update

interface ActionsContainer implements Node {
  actions(after: String, assignee: ID, companyId: ID, createdAfter: Timestamp, first: Int!, orderBy: ActionOrderBy, status: [ActionStatus!], updatedAfter: Timestamp, waitingFor: ID): ActionConnection
  id: ID!
}

union ActionsUpdatedEvent = Action | ActionAccessRevoked | ActionDeleted | Connected

union AddActionContextMutationResult = AccessDeniedError | Action | NotFoundError

input AddUserToCompanyInput {
  companyId: ID!
  role: CompanyRole!
  userId: ID!
}

union AddUserToCompanyMutationResult = AccessDeniedError | Company | NotFoundError

union ArchiveDecisionMutationResult = AccessDeniedError | NotFoundError | Success

union ArchiveMeetingMutationResult = AccessDeniedError | Meeting | NotFoundError

union ArchiveTopicMutationResult = AccessDeniedError | NotFoundError | Success

type ArchivedTopicsConnection implements Connection {
  edges: [ArchivedTopicsEdge!]!
  pageInfo: PageInfo!
}

type ArchivedTopicsEdge implements Edge {
  highlights: [Highlight!]
  node: Topic!

  """null if deleted"""
  section: MeetingTopicSection
}

scalar ByteArray

input CRUDEditUpdateAction {
  action: ID!
  nextActions: [ID]
  nextHtml: String
  reasonHtml: String
  repeat: Boolean
  repeatAt: Timestamp
  status: UpdateActionStatus
}

input CRUDEditUpdateGoal {
  goal: ID!
  nextActions: [ID]
  nextHtml: String
  reasonHtml: String
  status: UpdateGoalStatus
}

input CRUDUpdateAction {
  action: ID!
  nextActions: [ID]
  nextHtml: String
  reasonHtml: String
  repeat: Boolean
  repeatAt: Timestamp
  status: UpdateActionStatus!
}

input CRUDUpdateGoal {
  goal: ID!
  nextActions: [ID]
  nextHtml: String
  reasonHtml: String
  status: UpdateGoalStatus!
}

union CanLinkToMeeting = MustBeEventOrganizerError | Permitted

type CanvasesConnection implements Connection {
  edges: [CanvasesEdge!]!
  pageInfo: PageInfo!
}

type CanvasesEdge implements Edge {
  node: MeetingCanvas!
}

type Comment implements Node {
  author: User!
  canDelete: Boolean
  canEdit: Boolean
  canMarkOrUnmarkDecision: Boolean
  company: Company!
  createdAt: Timestamp!
  emojiReactions: [EmojiReaction!]
  htmlBody: String!
  id: ID!
  parent: CommentParent!
  replies(after: String, before: String, first: Int, last: Int): CommentsConnection
  replyTo: Comment
}

type CommentAccessRevoked {
  revokedCommentId: ID!
}

type CommentDeleted {
  deletedCommentId: ID!
}

union CommentParent = Action | Decision | Goal | GoodThing | MeetingPrep | PrototypeFeedback | Topic | Update

type CommentsConnection implements Connection {
  edges: [CommentsEdge!]!
  pageInfo: PageInfo!
}

interface CommentsContainer implements Node {
  comments(after: String, authorId: ID, first: Int!): CommentsConnection
  id: ID!
}

type CommentsEdge implements Edge {
  node: Comment!
}

union CommentsUpdatedEvent = Comment | CommentAccessRevoked | CommentDeleted | Connected

type Company implements Node {
  bio: String
  healthScores: CompanyHealthScores
  id: ID!
  members(after: String, first: Int!, userId: [ID!]): CompanyMembersConnection!
  name: String!
  reports: [FYIReport]
  settings: CompanySettings
  updates(after: String, assignee: [ID!], createdAfter: Timestamp, first: Int, orderBy: UpdateOrderBy): UpdateConnection
}

type CompanyConnections implements Connection {
  edges: [CompanyEdge!]!
  pageInfo: PageInfo!
}

type CompanyEdge implements Edge {
  node: Company!
}

type CompanyHealthScores {
  individualFeedback: Int
  meetingFeedback: Int
  regularUpdates: Int
  upToDateActions: Int
}

type CompanyMembersConnection implements Connection {
  edges: [CompanyMembersEdge!]!
  pageInfo: PageInfo!
}

type CompanyMembersEdge implements Edge {
  node: User!
  prototypeDepartment: String
  prototypeManager: User
  role: CompanyRole
}

type CompanyMembership {
  prototypeDepartment: String
  prototypeManager: User
  role: CompanyRole
}

enum CompanyRole {
  ADMIN
  MEMBER
}

type CompanySettings {
  updates: UpdatesSettings
}

type Connected {
  message: String!
}

interface Connection {
  edges: [Edge!]!
  pageInfo: PageInfo!
}

input CreateActionInput {
  assignee: ID
  dateRescheduled: Boolean
  description: String
  dueAt: Timestamp

  """
  If an action is created during a meeting, this should contain the meeting ID
  """
  parentMeetingId: ID
  privacy: ActionPrivacyStatus
  repeat: ActionRepeatType
  status: ActionStatus
  title: String!
  waitingFor: ID
}

union CreateActionMutationResult = AccessDeniedError | Action | NotFoundError | ValidationError

input CreateActionUpdateInput {
  done: Boolean
  nextActions: [ID]
  nextHtml: String
  reasonHtml: String
  repeat: Boolean
  repeatAt: Timestamp
  status: ActionStatus
}

input CreateActionUpdateInputWithRef {
  ref: ID!
  update: CreateActionUpdateInput!
}

union CreateActionUpdateMutationResult = AccessDeniedError | ActionUpdate | NotFoundError | ValidationError

input CreateCommentInput {
  author: ID
  htmlBody: String!
}

union CreateCommentMutationResult = AccessDeniedError | Comment | NotFoundError | ValidationError

input CreateCompanyInput {
  bio: String
  name: String!
}

union CreateCompanyMutationResult = AccessDeniedError | Company

input CreateDecisionMutationInput {
  author: ID
  commentsDueAt: Timestamp
  decisionMaker: ID
  description: String
  dueAt: Timestamp
  fromTopicId: ID
  meetingId: ID

  """
  If an issue is created during a meeting, this should contain the meeting ID
  """
  parentMeetingId: ID
  participants: [DecisionParticipantInput!]
  privacy: DecisionPrivacyStatus
  title: String!
  topicSectionId: ID
}

union CreateDecisionMutationResult = AccessDeniedError | Decision | NotFoundError

input CreateGoalDataInput {
  assigneeId: ID
  descriptionHtml: String
  dueAt: Timestamp
  parentMeetingId: ID
  privacy: GoalPrivacyStatus
  title: String!
}

union CreateGoalMutationResult = AccessDeniedError | Goal | NotFoundError | ValidationError

input CreateGoalUpdateInput {
  nextActions: [ID]
  nextHtml: String
  reasonHtml: String
  status: GoalStatus!
}

input CreateGoalUpdateInputWithRef {
  ref: ID!
  update: CreateGoalUpdateInput!
}

union CreateGoalUpdateMutationResult = AccessDeniedError | GoalUpdate | NotFoundError | ValidationError

input CreateGoodThingArgs {
  author: ID
  description: String
}

union CreateGoodThingMutationResult = AccessDeniedError | GoodThing | NotFoundError | ValidationError

input CreateMeetingCanvasInput {
  meetingId: ID!
}

union CreateMeetingCanvasResult = AccessDeniedError | MeetingCanvas | NotFoundError

input CreateMeetingInviteInput {
  meetingId: ID!
}

union CreateMeetingInviteResult = AccessDeniedError | MeetingInvite | NotFoundError

input CreateMeetingMutationInput {
  createFirstMeetingTopic: Boolean
  participantIds: [String!]
  privacyLevel: MeetingPrivacyLevel
  prototypeCategory: String
  sections: [NewMeetingSection!]
  title: String!
}

union CreateMeetingMutationResult = AccessDeniedError | Meeting

input CreateMeetingPrepInput {
  actionUpdates: [CreateActionUpdateInputWithRef!]
  goalUpdates: [CreateGoalUpdateInputWithRef!]
  goodThings: [ID!]
  topics: [ID!]
  usersToNotify: [ID]
}

union CreateMeetingPrepMutationResult = AccessDeniedError | MeetingPrep | NotFoundError | ValidationError

input CreateMeetingSectionMutationInput {
  meetingId: ID!
  title: String
  type: MeetingSectionType!
}

union CreateMeetingSectionMutationResult = AccessDeniedError | MeetingFeedbackSection | MeetingGoodThingsSection | MeetingPrepSection | MeetingReviewActionsSection | MeetingReviewDecisionsSection | MeetingReviewGoalsSection | MeetingTopicSection | MeetingUpdateListSection | NotFoundError

input CreatePrototypeFeedbackInput {
  authorId: ID
  htmlBody: String!
  score: Int
  type: PrototypeFeedbackType!
}

union CreatePrototypeFeedbackMutationResult = AccessDeniedError | NotFoundError | PrototypeFeedback | ValidationError

input CreateTopicMutationInput {
  description: String
  loomRecordingLink: String
  meetingTopicSectionId: ID!
  owner: ID
  recurringDate: Timestamp
  repeat: TopicRepeatType
  status: TopicStatus
  title: String
}

union CreateTopicMutationResult = AccessDeniedError | NotFoundError | Topic

input CreateUpdateInput {
  actions: [CRUDUpdateAction!]!
  goals: [CRUDUpdateGoal!]!
  goalsLoomLink: String
  privacy: UpdatePrivacyStatus
  usersToNotify: [ID]
}

union CreateUpdateMutationResult = AccessDeniedError | NotFoundError | Update | ValidationError

input CreateUserInputData {
  accessToBeta: Boolean
  email: String!
  firstName: String
  lastName: String
  role: UserRole!
  skipOnboarding: Boolean
  status: UserStatus
}

union CreateUserMutationResult = AccessDeniedError | User | ValidationError

type DecidedComment {
  comment: Comment!
  decidedAt: Timestamp!
  user: User!
}

type Decision implements ActionsContainer & CommentsContainer & Node {
  actions(after: String, assignee: ID, companyId: ID, createdAfter: Timestamp, first: Int!, orderBy: ActionOrderBy, status: [ActionStatus!], updatedAfter: Timestamp, waitingFor: ID): ActionConnection
  comments(after: String, authorId: ID, first: Int!): CommentsConnection
  commentsDueAt: Timestamp!
  company: Company!
  contributors(after: String, first: Int!): DecisionParticipantsConnection!
  createdAt: Timestamp!
  creator: User!
  dateRescheduled: Boolean!
  decidedComments: [DecidedComment!]!
  decisionDraft: Document!
  decisionMaker: User!
  description: Document!
  dueAt: Timestamp!
  id: ID!
  isPrivate: Boolean
  meetings(after: String, first: Int!, meetingPrivacy: MeetingPrivacyLevel): MeetingsConnection
  observers(after: String, first: Int!): DecisionParticipantsConnection!
  parent: DecisionParent

  """
  If an issue is created during a meeting, this should contain the meeting ID
  """
  parentMeeting: Meeting
  participatingIn(after: String, first: Int!): DecisionParticipantsConnection!
  privacy: DecisionPrivacyStatus!
  status: DecisionStatus!
  statusUpdatedAt: Timestamp
  title: String!
  titleHtml: String!
  updatedAt: Timestamp!
  userReviews: [UserReview!]
}

type DecisionAccessRevoked {
  revokedDecisionId: ID!
}

type DecisionDeleted {
  deletedDecisionId: ID!
}

union DecisionParent = Goal | Meeting

input DecisionParticipantInput {
  id: ID!
  role: DecisionParticipantRole!
}

enum DecisionParticipantRole {
  CONTRIBUTOR
  OBSERVER
}

type DecisionParticipantsConnection implements Connection {
  edges: [DecisionParticipantsEdge!]!
  pageInfo: PageInfo!
}

type DecisionParticipantsEdge implements Edge {
  node: User!
  role: DecisionParticipantRole!
}

enum DecisionPrivacyStatus {
  PRIVATE
  PUBLIC
}

enum DecisionStatus {
  CANCELLED
  IMPLEMENTING
  ON_HOLD
  RESOLVED
  WAITING_FOR_COMMENTS
  WAITING_FOR_DECISION
}

type DecisionsConnection implements Connection {
  edges: [DecisionsEdge!]!
  pageInfo: PageInfo!
}

interface DecisionsContainer implements Node {
  decisions(after: String, companyId: ID!, createdAfter: Timestamp, creator: ID, first: Int!, participatingIn: Boolean = true, status: [DecisionStatus!]): DecisionsConnection
  id: ID!
}

type DecisionsEdge implements Edge {
  node: Decision!
}

union DecisionsUpdatedEvent = Connected | Decision | DecisionAccessRevoked | DecisionDeleted

union DeleteActionMutationResult = AccessDeniedError | NotFoundError | Success

union DeleteCommentMutationResult = AccessDeniedError | NotFoundError | Success

union DeleteDecisionMutationResult = AccessDeniedError | NotFoundError | Success

union DeleteGoalMutationResult = AccessDeniedError | NotFoundError | Success

union DeleteGoodThingMutationResult = AccessDeniedError | NotFoundError | Success

union DeleteMeetingCanvasResult = AccessDeniedError | NotFoundError | Success

union DeleteMeetingMutationResult = AccessDeniedError | NotFoundError | Success

union DeleteMeetingSectionMutationResult = AccessDeniedError | Meeting | NotFoundError

union DeletePrototypeFeedbackMutationResult = AccessDeniedError | NotFoundError | Success

union DeleteTopicMutationResult = AccessDeniedError | NotFoundError | Success

union DeleteUpdateMutationResult = AccessDeniedError | NotFoundError | Success

type Deleted {
  message: String!
}

union DetachDecisionFromMeetingMutationResult = AccessDeniedError | NotFoundError | Success

union DetachTopicFromMeetingMutationResult = AccessDeniedError | NotFoundError | Success

type Document {
  byteContent: ByteArray!
  htmlContent: String!
  id: ID!
}

input DuplicateAndAssignActionInput {
  assignees: [ID!]!
}

union DuplicateAndAssignActionResult = AccessDeniedError | ActionList | NotFoundError

scalar Duration

interface Edge {
  node: Node!
}

type EmojiReaction {
  emoji: String!
  user: User!
}

interface Error {
  message: String!
}

type FYIMeetingFeedbackLow {
  feedback: PrototypeFeedback
  timestampAt: Timestamp
}

type FYIPastDueActions {
  actions: [Action]
  timestampAt: Timestamp
  user: User
}

union FYIReport = FYIMeetingFeedbackLow | FYIPastDueActions | FYIUpdateNotDone | FYIUpdateOffTrack | FYIUserFeedbackLow

type FYIUpdateNotDone {
  timestampAt: Timestamp
  update: Update
}

type FYIUpdateOffTrack {
  timestampAt: Timestamp
  update: Update
}

type FYIUserFeedbackLow {
  feedback: PrototypeFeedback
  timestampAt: Timestamp
}

type FeedbackAccessRevoked {
  revokedFeedbackId: ID!
}

type FeedbackDeleted {
  deletedFeedbackId: ID!
}

input FeedbackFeedFilter {
  authorId: ID
  companyId: ID!
  createdAfter: Timestamp
  parentId: ID
  toIds: [ID!]
  type: FeedbackTargetType
}

enum FeedbackStatus {
  DISCUSSED
  UNDISCUSSED
}

enum FeedbackTargetType {
  MEETING
  USER
}

union FeedbacksUpdatedEvent = Connected | FeedbackAccessRevoked | FeedbackDeleted | PrototypeFeedback

union GenericTopic = Decision | Topic

enum GenericTopicType {
  Decision
  Topic
}

type Goal implements ActionsContainer & CommentsContainer & DecisionsContainer & Node {
  actions(after: String, assignee: ID, companyId: ID, createdAfter: Timestamp, first: Int!, orderBy: ActionOrderBy, status: [ActionStatus!], updatedAfter: Timestamp, waitingFor: ID): ActionConnection
  assignee: User
  comments(after: String, authorId: ID, first: Int!): CommentsConnection
  company: Company!
  createdAt: Timestamp!
  creator: User
  decisions(after: String, companyId: ID!, createdAfter: Timestamp, creator: ID, first: Int!, participatingIn: Boolean = true, status: [DecisionStatus!]): DecisionsConnection!
  description: Document!
  dueAt: Timestamp!
  id: ID!
  isPrivate: Boolean
  parentMeeting: Meeting
  priority: Int
  privacy: GoalPrivacyStatus!
  status: GoalStatus!
  statusUpdatedAt: Timestamp
  title: String!
  titleHtml: String!
  updates: [GoalUpdate]
}

type GoalAccessRevoked {
  revokedGoalId: ID!
}

type GoalDeleted {
  deletedGoalId: ID!
}

enum GoalPrivacyStatus {
  PRIVATE
  PUBLIC
}

enum GoalStatus {
  AT_RISK
  BEHIND
  CANCELLED
  DONE
  ON_TRACK
}

type GoalUpdate implements Node {
  createdAt: Timestamp!
  goal: Goal!
  id: ID!
  nextActions: [Action]
  nextHtml: String
  reasonHtml: String
  source: GoalUpdateSource
  status: GoalStatus!
  updatedAt: Timestamp!
}

union GoalUpdateSource = MeetingPrep | Update

type GoalsConnection implements Connection {
  edges: [GoalsEdge!]!
  pageInfo: PageInfo!
}

type GoalsEdge implements Edge {
  node: Goal!
}

union GoalsUpdatedEvent = Connected | Goal | GoalAccessRevoked | GoalDeleted

type GoodThing implements ActionsContainer & CommentsContainer & Node {
  actions(after: String, assignee: ID, companyId: ID, createdAfter: Timestamp, first: Int!, orderBy: ActionOrderBy, status: [ActionStatus!], updatedAfter: Timestamp, waitingFor: ID): ActionConnection
  comments(after: String, authorId: ID, first: Int!): CommentsConnection
  company: Company!
  createdAt: Timestamp!
  description: Document!
  emojiReactions: [EmojiReaction!]
  id: ID!
  parent: Meeting
  title: String!
  user: User!
}

type GoodThingAccessRevoked {
  revokedGoodThingId: ID!
}

type GoodThingConnection implements Connection {
  edges: [GoodThingEdge!]!
  pageInfo: PageInfo!
}

type GoodThingDeleted {
  deletedGoodThingId: ID!
}

type GoodThingEdge implements Edge {
  node: GoodThing!
}

union GoodThingsUpdatedEvent = Connected | GoodThing | GoodThingAccessRevoked | GoodThingDeleted

type GoogleAccount {
  email: String
  sub: ID!
}

type GoogleCalendarEvent {
  canLinkToMeeting: CanLinkToMeeting!
  endTime: Timestamp!
  htmlLink: String!
  id: ID!
  linkedMeeting: Meeting
  organizer: GoogleCalendarEventOrganizer

  """RRule according to rfc5545"""
  recurrence: String
  startTime: Timestamp!
  timezone: String!
  title: String!
}

type GoogleCalendarEventOrganizer {
  displayName: String
  email: String
}

union HideUserFromSectionResult = AccessDeniedError | Meeting | NotFoundError

type Highlight {
  path: String!
  spans: [HighlightSpan!]!
}

type HighlightSpan {
  isHit: Boolean!
  text: String!
}

union ImportMeetingTopicSectionMutationResult = AccessDeniedError | MeetingTopicSection | NotFoundError | ValidationError

type Invite {
  companyId: String!
  companyName: String!
  senderName: String!
  user: User!
}

union LinkGoogleCalendarEventToMeetingResult = AccessDeniedError | GoogleCalendarEvent | MustBeEventOrganizerError | NotFoundError

union LinkUserGoogleAccountResult = AccessDeniedError | MustBeEventOrganizerError | User | ValidationError

type Meeting implements ActionsContainer & DecisionsContainer & Node {
  actions(after: String, assignee: ID, assignees: [ID!], companyId: ID, createdAfter: Timestamp, first: Int!, orderBy: ActionOrderBy, status: [ActionStatus!], updatedAfter: Timestamp, waitingFor: ID): ActionConnection
  archivedTopics(after: String, first: Int!, searchQuery: String): ArchivedTopicsConnection
  callLink: String
  canCreateMeetingInvite: Boolean
  canvases(after: String, first: Int!): CanvasesConnection
  company: Company!
  decisions(after: String, companyId: ID!, createdAfter: Timestamp, creator: ID, first: Int!, participatingIn: Boolean = true, status: [DecisionStatus!]): DecisionsConnection!
  defaultSection: MeetingTopicSection!
  description: String
  feedbacks(after: String, createdAfter: Timestamp, first: Int!): PrototypeFeedbackConnection
  goodThings(after: String, first: Int!, since: Timestamp): GoodThingConnection
  id: ID!
  isArchived: Boolean!
  isPrivate: Boolean
  meetingPreps(after: String, first: Int): MeetingPrepConnection
  moderator: User
  participants(after: String, first: Int!, participantIds: [ID!]): MeetingParticipantsConnection
  privacyLevel: MeetingPrivacyLevel
  prototypeCategory: String
  sections(includeHiddenSections: Boolean): [MeetingSection!]!
  subtitle: String
  title: String!
}

type MeetingAccessRevoked {
  revokedMeetingId: ID!
}

type MeetingCanvas implements Node {
  byteContent: ByteArray!
  htmlContent: String!
  id: ID!
  meeting: Meeting
  name: String!
}

type MeetingDeleted {
  deletedMeetingId: ID!
}

type MeetingFeedbackSection implements MeetingSection {
  description: String
  id: ID!
  isHidden: Boolean
  meeting: Meeting!
  prototypeCategory: String
  title: String
}

type MeetingGoodThingsSection implements MeetingSection {
  description: String
  id: ID!
  includedInPreWrite: Boolean
  isHidden: Boolean
  meeting: Meeting!
  preWriteDescription: String
  prototypeCategory: String
  title: String
}

type MeetingInvite {
  expiresAt: Timestamp!
  token: String!
}

type MeetingInviteTokenInfo {
  companyId: ID!
  companyName: String!
  meetingId: ID!
  meetingName: String!
}

enum MeetingParticipantRole {
  OWNER
  PARTICIPANT
}

type MeetingParticipantsConnection implements Connection {
  edges: [MeetingParticipantsEdge!]!
  pageInfo: PageInfo!
}

type MeetingParticipantsEdge implements Edge {
  node: User!
  role: MeetingParticipantRole!
}

type MeetingPrep implements CommentsContainer & Node {
  actionUpdates: [ActionUpdate!]
  author: User!
  comments(after: String, authorId: ID, first: Int!): CommentsConnection
  company: Company!
  createdAt: Timestamp!
  goalUpdates: [GoalUpdate!]
  goalsLoomLink: String
  goodThings: [GoodThing!]
  id: ID!
  meeting: Meeting!
  preReadAt: Timestamp
  title: String!
  topics: [Topic!]
  updatedAt: Timestamp!
}

type MeetingPrepAccessRevoked {
  revokedMeetingPrepId: ID!
}

type MeetingPrepConnection implements Connection {
  edges: [MeetingPrepEdge!]!
  pageInfo: PageInfo!
}

type MeetingPrepDeleted {
  deletedMeetingPrepId: ID!
}

type MeetingPrepEdge implements Edge {
  node: MeetingPrep!
}

type MeetingPrepFilter {
  authors: [ID!]
}

type MeetingPrepSection implements MeetingSection {
  description: String
  filter: MeetingPrepFilter
  id: ID!
  isHidden: Boolean
  meeting: Meeting!
  prototypeCategory: String
  title: String
}

enum MeetingPrepType {
  PRE_READ
  PRE_WRITE
}

union MeetingPrepUpdatedEvent = Connected | MeetingPrep | MeetingPrepAccessRevoked | MeetingPrepDeleted

enum MeetingPrivacyLevel {
  PRIVATE
  PUBLIC
}

type MeetingReviewActionsSection implements MeetingSection {
  description: String
  hiddenUserIds: [ID!]
  id: ID!
  includedInPreWrite: Boolean
  isHidden: Boolean
  meeting: Meeting!
  preWriteDescription: String
  prototypeCategory: String
  title: String
}

type MeetingReviewDecisionsSection implements MeetingSection {
  description: String
  id: ID!
  isHidden: Boolean
  meeting: Meeting!
  prototypeCategory: String
  title: String
}

type MeetingReviewGoalsSection implements MeetingSection {
  description: String
  hiddenUserIds: [ID!]
  id: ID!
  isHidden: Boolean
  meeting: Meeting!
  prototypeCategory: String
  title: String
}

interface MeetingSection {
  description: String
  id: ID!
  isHidden: Boolean
  meeting: Meeting!
  prototypeCategory: String
  title: String
}

enum MeetingSectionType {
  FEEDBACK
  GOODTHINGS
  MEETING_PREP
  REVIEW_ACTIONS
  REVIEW_DECISIONS
  REVIEW_GOALS
  TOPICS
  UPDATE_LIST
}

type MeetingTopicSection implements MeetingSection {
  description: String
  genericTopics: [GenericTopic!]!
  id: ID!
  includedInPreWrite: Boolean
  isHidden: Boolean
  meeting: Meeting!
  preWriteDescription: String
  prototypeCategory: String
  title: String
}

type MeetingUpdateListSection implements MeetingSection {
  description: String
  filter: UpdatesFilter
  id: ID!
  includedInPreWrite: Boolean
  isHidden: Boolean
  meeting: Meeting!
  preWriteDescription: String
  prototypeCategory: String
  title: String
}

union MeetingUpdatedEvent = AccessRevoked | Connected | Deleted | Meeting

type MeetingsConnection implements Connection {
  edges: [MeetingsEdge!]!
  pageInfo: PageInfo!
}

type MeetingsEdge implements Edge {
  node: Meeting!
}

union MeetingsUpdatedEvent = Connected | Meeting | MeetingAccessRevoked | MeetingDeleted

union MoveGenericTopicToSectionMutationResult = AccessDeniedError | MoveGenericTopicToSectionMutationResultData | NotFoundError

type MoveGenericTopicToSectionMutationResultData {
  destinationSection: MeetingTopicSection!
  sourceSection: MeetingTopicSection
}

union MoveMeetingSectionResult = AccessDeniedError | Meeting | NotFoundError

type MustBeEventOrganizerError implements Error {
  message: String!
}

type Mutation {
  _dummy: Boolean
  acceptMeetingInvite(input: AcceptMeetingInviteInput!): AcceptMeetingInviteResult!
  addActionContext(context: ActionContextInput!, id: ID!): AddActionContextMutationResult!
  addCommentEmojiReaction(commentId: ID!, emoji: String!): UpdateCommentMutationResult!
  addDecisionParticipant(decisionId: ID!, role: DecisionParticipantRole!, userId: ID!): UpdateDecisionMutationResult!
  addGoodThingEmojiReaction(emoji: String!, goodThingId: ID!): UpdateGoodThingMutationResult!
  addMeetingParticipant(meetingId: ID!, role: MeetingParticipantRole!, userId: ID!): UpdateMeetingMutationResult!
  addPrototypeFeedbackEmojiReaction(emoji: String!, prototypeFeedbackId: ID!): UpdatePrototypeFeedbackMutationResult!
  addTopicEmojiReaction(emoji: String!, topicId: ID!): UpdateTopicMutationResult!
  addUpdateEmojiReaction(emoji: String!, updateId: ID!): UpdateUpdateMutationResult!
  addUserToCompany(data: AddUserToCompanyInput!): AddUserToCompanyMutationResult!
  archiveDecision(id: ID!): ArchiveDecisionMutationResult!
  archiveMeeting(id: ID!): ArchiveMeetingMutationResult!
  archiveTopic(id: ID!): ArchiveTopicMutationResult!
  createAction(companyId: ID!, data: CreateActionInput!): CreateActionMutationResult!
  createActionOnDecision(data: CreateActionInput!, decisionId: ID!): CreateActionMutationResult!
  createActionOnGoal(data: CreateActionInput!, goalId: ID!): CreateActionMutationResult!
  createActionOnGoodThing(data: CreateActionInput!, goodThingId: ID!): CreateActionMutationResult!
  createActionOnMeeting(data: CreateActionInput!, meetingId: ID!): CreateActionMutationResult!
  createActionOnPrototypeFeedback(data: CreateActionInput!, prototypeFeedbackId: ID!): CreateActionMutationResult!
  createActionOnTopic(data: CreateActionInput!, topicId: ID!): CreateActionMutationResult!
  createActionOnUpdate(data: CreateActionInput!, updateId: ID!): CreateActionMutationResult!
  createActionUpdate(actionId: ID!, data: CreateActionUpdateInput!): CreateActionUpdateMutationResult!
  createCommentOnAction(actionId: ID!, data: CreateCommentInput!): CreateCommentMutationResult!
  createCommentOnDecision(data: CreateCommentInput!, decisionId: ID!): CreateCommentMutationResult!
  createCommentOnGoal(data: CreateCommentInput!, goalId: ID!): CreateCommentMutationResult!
  createCommentOnGoodThing(data: CreateCommentInput!, goodThingId: ID!): CreateCommentMutationResult!
  createCommentOnMeetingPrep(data: CreateCommentInput!, meetingPrepId: ID!): CreateCommentMutationResult!
  createCommentOnPrototypeFeedback(data: CreateCommentInput!, prototypeFeedbackId: ID!): CreateCommentMutationResult!
  createCommentOnTopic(data: CreateCommentInput!, topicId: ID!): CreateCommentMutationResult!
  createCommentOnUpdate(data: CreateCommentInput!, updateId: ID!): CreateCommentMutationResult!
  createCommentReply(commentId: ID!, data: CreateCommentInput!): CreateCommentMutationResult!
  createCompany(data: CreateCompanyInput!): CreateCompanyMutationResult!
  createDecision(companyId: ID!, data: CreateDecisionMutationInput!): CreateDecisionMutationResult!
  createDecisionOnGoal(data: CreateDecisionMutationInput!, goalId: ID!): CreateDecisionMutationResult!
  createDecisionOnMeeting(data: CreateDecisionMutationInput!, meetingId: ID!): CreateDecisionMutationResult!
  createFollowUpAction(actionId: ID!, data: CreateActionInput!): CreateActionMutationResult!
  createGoal(companyId: ID!, data: CreateGoalDataInput!): CreateGoalMutationResult!
  createGoalUpdate(data: CreateGoalUpdateInput!, goalId: ID!): CreateGoalUpdateMutationResult!
  createGoodThingOnMeeting(args: CreateGoodThingArgs!, meetingId: ID!): CreateGoodThingMutationResult!
  createGoodThingOnUser(args: CreateGoodThingArgs!, companyId: ID!): CreateGoodThingMutationResult!
  createMeeting(companyId: ID!, data: CreateMeetingMutationInput!): CreateMeetingMutationResult!
  createMeetingCanvas(input: CreateMeetingCanvasInput!): CreateMeetingCanvasResult!
  createMeetingInvite(input: CreateMeetingInviteInput!): CreateMeetingInviteResult!
  createMeetingPrep(data: CreateMeetingPrepInput!, meetingId: ID!, userId: ID!): CreateMeetingPrepMutationResult!
  createMeetingSection(data: CreateMeetingSectionMutationInput!): CreateMeetingSectionMutationResult!
  createPrototypeFeedbackOnMeeting(data: CreatePrototypeFeedbackInput!, meetingId: ID!): CreatePrototypeFeedbackMutationResult!
  createPrototypeFeedbackOnUser(companyId: ID!, data: CreatePrototypeFeedbackInput!, meetingId: ID, userId: ID!): CreatePrototypeFeedbackMutationResult!
  createTopic(data: CreateTopicMutationInput!): CreateTopicMutationResult!
  createUpdate(companyId: ID!, data: CreateUpdateInput!, userId: ID!): CreateUpdateMutationResult!
  createUser(data: CreateUserInputData!): CreateUserMutationResult!
  deleteAction(id: ID!): DeleteActionMutationResult!
  deleteComment(id: ID!): DeleteCommentMutationResult!
  deleteDecision(id: ID!): DeleteDecisionMutationResult!
  deleteGoal(id: ID!): DeleteGoalMutationResult!
  deleteGoodThing(id: ID!): DeleteGoodThingMutationResult!
  deleteMeeting(id: ID!): DeleteMeetingMutationResult!
  deleteMeetingCanvas(id: ID!): DeleteMeetingCanvasResult!
  deleteMeetingSection(id: ID!): DeleteMeetingSectionMutationResult!
  deletePrototypeFeedback(id: ID!): DeletePrototypeFeedbackMutationResult!
  deleteTopic(id: ID!): DeleteTopicMutationResult!
  deleteUpdate(id: ID!): DeleteUpdateMutationResult!
  detachDecisionFromMeeting(decisionId: ID!, meetingId: ID!): DetachDecisionFromMeetingMutationResult!
  detachTopicFromMeeting(meetingId: ID!, topicId: ID!): DetachTopicFromMeetingMutationResult!
  duplicateAndAssignAction(data: DuplicateAndAssignActionInput!, id: ID!): DuplicateAndAssignActionResult!
  hideUserFromSection(sectionId: ID!, userId: ID!): HideUserFromSectionResult!
  importGenericTopicToSection(id: ID!, importedTopicIds: [importedTopicInput!]!): ImportMeetingTopicSectionMutationResult!
  linkGoogleCalendarEventToMeeting(calendarId: ID!, eventId: ID!, meetingId: ID!, useNewAccountabilityReminder: Boolean): LinkGoogleCalendarEventToMeetingResult!
  markOrUnmarkDecision(commentId: ID!, decisionId: ID!): UpdateDecisionMutationResult!
  moveGenericTopicToSection(destinationSectionId: ID!, destinationSectionPosition: Int!, topicId: ID!, type: String!): MoveGenericTopicToSectionMutationResult!
  moveMeetingSection(destinationPosition: Int!, sectionId: ID!): MoveMeetingSectionResult!
  removeActionContext(contextRef: ID!, id: ID!): RemoveActionContextMutationResult!
  removeCommentEmojiReaction(allSkinTones: Boolean, commentId: ID!, emoji: String!): UpdateCommentMutationResult!
  removeCompanySettings(id: ID!): RemoveCompanySettingsMutationResult!
  removeDecisionParticipant(decisionId: ID!, userId: ID!): UpdateDecisionMutationResult!
  removeGoodThingEmojiReaction(allSkinTones: Boolean, emoji: String!, goodThingId: ID!): UpdateGoodThingMutationResult!
  removeMeetingParticipant(meetingId: ID!, userId: ID!): UpdateMeetingMutationResult!
  removePrototypeFeedbackEmojiReaction(allSkinTones: Boolean, emoji: String!, prototypeFeedbackId: ID!): UpdatePrototypeFeedbackMutationResult!
  removeTopicEmojiReaction(allSkinTones: Boolean, emoji: String!, topicId: ID!): UpdateTopicMutationResult!
  removeUpdateEmojiReaction(allSkinTones: Boolean, emoji: String!, updateId: ID!): UpdateUpdateMutationResult!
  removeUserFromCompany(data: RemoveUserFromCompanyInput!): RemoveUserFromCompanyMutationResult!
  sendInvite(data: SendInviteInput!): SendInviteMutationResult
  sendMeetingPrepReminder(emailContent: String!, inAppAndSlackContent: String!, meetingId: ID!, prepType: MeetingPrepType!, usersToNotify: [ID!]!): SendMeetingPrepReminderMutationResult!
  setGoalPriority(id: ID!, priority: Int!): SetPriorityMutationResult!
  showUserFromSection(sectionId: ID!, userId: ID!): HideUserFromSectionResult!
  showUsersFromSection(sectionId: ID!): HideUserFromSectionResult!
  signUpWithMeetingInvite(input: SignUpWithMeetingInviteInput!): SignUpWithMeetingInviteResult!
  submitUpdate(meetingId: ID!): UpdateMeetingMutationResult!
  toggleDecisionUserReview(decisionId: ID!): UpdateDecisionMutationResult!
  toggleMeetingSection(hide: Boolean!, id: ID!): ToggleMeetingSectionMutationResult!
  toggleTopicUserReview(topicId: ID!): UpdateTopicMutationResult!
  toggleUpdateUserReview(updateId: ID!): UpdateUpdateMutationResult!
  unarchiveMeeting(id: ID!): UnarchiveMeetingMutationResult!
  unlinkGoogleCalendarEventFromMeeting(calendarId: ID!, eventId: ID!): UnlinkGoogleCalendarEventToMeetingResult!
  updateAction(data: UpdateActionInput!, id: ID!): UpdateActionMutationResult!
  updateActionUpdate(data: UpdateActionUpdateInput!, id: ID!): UpdateActionMutationResult!
  updateComment(data: UpdateCommentInput!, id: ID!): UpdateCommentMutationResult!
  updateCompany(data: UpdateCompanyInput!, id: ID!): UpdateCompanyMutationResult!
  updateCompanyMembership(companyId: ID!, data: UpdateCompanyMembershipInput!, userId: ID!): UpdateCompanyMembershipMutationResult!
  updateCompanySettings(data: UpdateCompanySettingsInput!, id: ID!): UpdateCompanySettingsMutationResult!
  updateDecision(data: UpdateDecisionMutationInput!, id: ID!): UpdateDecisionMutationResult!
  updateDocument(id: ID!, patch: ByteArray!): UpdateDocumentMutationResult!
  updateGoal(data: UpdateGoalDataInput!, id: ID!): UpdateGoalMutationResult!
  updateGoalUpdate(data: UpdateGoalUpdateInput!, id: ID!): UpdateGoalMutationResult!
  updateGoodThing(data: UpdateGoodThingArgs!, id: ID!): UpdateGoodThingMutationResult!
  updateMeeting(data: UpdateMeetingMutationInput!, id: ID!): UpdateMeetingMutationResult!
  updateMeetingCanvas(input: UpdateMeetingCanvasInput!): UpdateMeetingCanvasResult!
  updateMeetingPrep(data: UpdateMeetingPrepInput!, id: ID!): UpdateMeetingPrepMutationResult!
  updateMeetingSection(data: UpdateMeetingSectionMutationInput!, id: ID!): UpdateMeetingSectionMutationResult!
  updatePrototypeFeedback(data: UpdatePrototypeFeedbackInput!, id: ID!): UpdatePrototypeFeedbackMutationResult!
  updateTopic(data: UpdateTopicMutationInput!, id: ID!): UpdateTopicMutationResult!
  updateUpdate(data: UpdateUpdateInput!, id: ID!): UpdateUpdateMutationResult!
  updateUser(data: UpdateUserInputData!, id: ID!): UpdateUserMutationResult!
}

input NewMeetingSection {
  title: String!
  type: MeetingSectionType!
}

interface Node {
  id: ID!
}

type NotFoundError implements Error {
  message: String!
}

type PageInfo {
  endCursor: String!
  hasNextPage: Boolean!
  hasPreviousPage: Boolean!
  startCursor: String!
}

type Permitted {
  message: String
}

type PrototypeFeedback implements ActionsContainer & CommentsContainer & Node {
  actions(after: String, assignee: ID, companyId: ID, createdAfter: Timestamp, first: Int!, orderBy: ActionOrderBy, status: [ActionStatus!], updatedAfter: Timestamp, waitingFor: ID): ActionConnection
  author: User
  comments(after: String, authorId: ID, first: Int!): CommentsConnection
  company: Company!
  createdAt: Timestamp!
  description: Document
  descriptionText: String
  emojiReactions: [EmojiReaction!]
  id: ID!
  isPrivate: Boolean
  parent: PrototypeFeedbackParent
  privacy: PrototypeFeedbackPrivacyStatus!
  score: Int
  status: FeedbackStatus
  statusUpdatedAt: Timestamp
  title: String!
  to: PrototypeFeedbackTo
  type: PrototypeFeedbackType!
}

type PrototypeFeedbackConnection implements Connection {
  edges: [PrototypeFeedbackEdge!]!
  pageInfo: PageInfo!
}

type PrototypeFeedbackEdge implements Edge {
  node: PrototypeFeedback!
}

union PrototypeFeedbackParent = Meeting | User

enum PrototypeFeedbackPrivacyStatus {
  PRIVATE
  PUBLIC
}

type PrototypeFeedbackReport implements Node {
  averageScore: Float
  id: ID!
  lastGiven: Timestamp
  lastReceived: Timestamp
  to: PrototypeFeedbackTo!
}

type PrototypeFeedbackReportConnection implements Connection {
  edges: [PrototypeFeedbackReportEdge!]!
  pageInfo: PageInfo!
}

type PrototypeFeedbackReportEdge implements Edge {
  node: PrototypeFeedbackReport!
}

union PrototypeFeedbackTo = Meeting | User

enum PrototypeFeedbackType {
  FEEDBACK
}

type Query {
  _dummy: Boolean
  action(id: ID!): Action
  actions(after: String, assignee: [ID!], companyId: ID!, first: Int!, orderBy: ActionOrderBy, status: [ActionStatus!], waitingFor: [ID!]): ActionConnection
  comment(id: ID!): Comment
  companies(after: String, first: Int!): CompanyConnections
  company(id: ID!): Company
  decision(id: ID!): Decision
  document(id: ID!): Document
  goal(id: ID!): Goal
  goodThing(id: ID!): GoodThing
  googleCalendarEvent(calendarId: ID!, eventId: ID!): GoogleCalendarEvent
  me: User
  meeting(id: ID!): Meeting
  meetingCanvas(id: ID!): MeetingCanvas
  meetingPrep(id: ID!): MeetingPrep
  meetingSection(id: ID!): MeetingSection
  prototypeFeedback(id: ID!): PrototypeFeedback
  prototypeFeedbackFeed(after: String, filter: FeedbackFeedFilter!, first: Int!): PrototypeFeedbackConnection
  prototypeFeedbackReport(after: String, companyId: ID!, createdAfter: Timestamp, first: Int!, type: FeedbackTargetType!): PrototypeFeedbackReportConnection
  search(companyId: String!, first: Int!, query: String, searchIn: [SearchableEntity!]): SearchResult
  searchTopics(companyId: ID!, first: Int!, userInput: String): TopicsConnection
  topic(id: ID!): Topic
  update(id: ID!): Update
  updates(after: String, companyId: ID!, first: Int): UpdateConnection
  user(id: ID!): User
  users(after: String, first: Int!): UsersConnection
  verifyInviteToken(token: String!): Invite
  verifyMeetingInviteToken(token: String!): MeetingInviteTokenInfo
}

union RemoveActionContextMutationResult = AccessDeniedError | Action | NotFoundError

union RemoveCompanySettingsMutationResult = AccessDeniedError | Company | NotFoundError

input RemoveUserFromCompanyInput {
  companyId: ID!
  userId: ID!
}

union RemoveUserFromCompanyMutationResult = AccessDeniedError | Company | NotFoundError

type SearchResult {
  edges: [SearchResultEdge!]!
}

type SearchResultEdge {
  highlights: [Highlight!]
  node: SearchResultNode!
  score: Float
}

union SearchResultNode = Action | Decision | Goal | Meeting | Topic

enum SearchableEntity {
  ACTION
  DECISION
  GOAL
  MEETING
  TOPIC
}

input SendInviteInput {
  accessToBeta: Boolean!
  companyId: ID!
  email: String!
  role: CompanyRole!
}

union SendInviteMutationResult = AccessDeniedError | NotFoundError | User | ValidationError

union SendMeetingPrepReminderMutationResult = AccessDeniedError | NotFoundError | Success | ValidationError

type SetPriorityMutationResult {
  goals: [Goal]!
}

type SharedWith {
  access: ActionAccess!
  user: User!
}

input SharedWithInput {
  access: ActionAccess!
  userId: ID!
}

input SignUpWithMeetingInviteInput {
  email: String!
  firstName: String!
  lastName: String!
  password: String!
  token: String!
}

union SignUpWithMeetingInviteResult = NotFoundError | Success | ValidationError

type Subscription {
  _dummy: Boolean
  actionsUpdated: ActionsUpdatedEvent!
  commentsUpdated: CommentsUpdatedEvent!
  decisionsUpdated: DecisionsUpdatedEvent!
  feedbacksUpdated: FeedbacksUpdatedEvent!
  goalsUpdated: GoalsUpdatedEvent!
  goodThingsUpdated: GoodThingsUpdatedEvent!
  meetingPrepUpdated: MeetingPrepUpdatedEvent!
  meetingUpdated(id: ID!): MeetingUpdatedEvent!
  meetingsUpdated: MeetingsUpdatedEvent!
  topicsUpdated: TopicsUpdatedEvent!
  updatesUpdated: UpdatesUpdatedEvent!
  userAddedToCompany(companyId: ID!): UserAddedToCompanyEvent!
  userRemovedFromCompany(companyId: ID!): UserRemovedFromCompanyEvent!
  userUpdated(id: ID!): UserUpdatedEvent!
}

type Success {
  message: String!
}

scalar Timestamp

union ToggleMeetingSectionMutationResult = AccessDeniedError | Meeting | NotFoundError

type Topic implements ActionsContainer & CommentsContainer & Node {
  actions(after: String, assignee: ID, companyId: ID, createdAfter: Timestamp, first: Int!, orderBy: ActionOrderBy, status: [ActionStatus!], updatedAfter: Timestamp, waitingFor: ID): ActionConnection
  comments(after: String, authorId: ID, first: Int!): CommentsConnection
  company: Company!
  createdAt: Timestamp!
  description: Document!
  emojiReactions: [EmojiReaction!]
  id: ID!
  isArchived: Boolean
  isPrivate: Boolean
  loomRecordingLink: String
  meetings(after: String, first: Int!, meetingPrivacy: MeetingPrivacyLevel): MeetingsConnection
  owner: User
  parent: Meeting
  recurringDate: Timestamp
  repeat: TopicRepeatType
  status: TopicStatus!
  statusUpdatedAt: Timestamp
  title: String!
  titleHtml: String!
  updatedAt: Timestamp!
  userReviews: [UserReview!]
}

type TopicAccessRevoked {
  revokedTopicId: ID!
}

type TopicDeleted {
  deletedTopicId: ID!
}

enum TopicRepeatType {
  BIWEEKLY
  MONTHLY
  NONE
  QUARTERLY
  WEEKLY
}

enum TopicStatus {
  DISCUSSED
  UNDISCUSSED
}

type TopicsConnection implements Connection {
  edges: [TopicsEdge!]!
  pageInfo: PageInfo!
}

type TopicsEdge implements Edge {
  node: Topic!
}

union TopicsUpdatedEvent = Connected | Topic | TopicAccessRevoked | TopicDeleted

union UnarchiveMeetingMutationResult = AccessDeniedError | Meeting | NotFoundError

union UnlinkGoogleCalendarEventToMeetingResult = AccessDeniedError | GoogleCalendarEvent | MustBeEventOrganizerError | NotFoundError

type Update implements ActionsContainer & CommentsContainer & Node {
  actionUpdates: [UpdateAction!]!
  actions(after: String, assignee: ID, companyId: ID, createdAfter: Timestamp, first: Int!, orderBy: ActionOrderBy, status: [ActionStatus!], updatedAfter: Timestamp, waitingFor: ID): ActionConnection
  author: User!
  comments(after: String, authorId: ID, first: Int!): CommentsConnection
  company: Company!
  createdAt: Timestamp!
  emojiReactions: [EmojiReaction!]
  goals: [UpdateGoal!]!
  goalsLoomLink: String
  id: ID!
  privacy: UpdatePrivacyStatus!
  reportAt: Timestamp!
  title: String!
  updatedAt: Timestamp!
  userReviews: [UserReview!]
}

type UpdateAccessRevoked {
  revokedUpdateId: ID!
}

type UpdateAction {
  action: Action!
  nextActions: [Action]
  nextHtml: String
  reasonHtml: String
  repeat: Boolean
  status: UpdateActionStatus!
}

input UpdateActionInput {
  assignee: ID
  dueAt: Timestamp
  privacy: ActionPrivacyStatus
  repeat: ActionRepeatType
  sharedWith: [SharedWithInput]
  status: ActionStatus
  title: String
  waitingFor: ID
}

union UpdateActionMutationResult = AccessDeniedError | Action | ActionUpdate | NotFoundError | ValidationError

enum UpdateActionStatus {
  ACTIVE
  CANCELLED
  DONE
  NOT_DONE
}

input UpdateActionUpdateInput {
  done: Boolean
  nextActions: [ID]
  nextHtml: String
  reasonHtml: String
  repeat: Boolean
  repeatAt: Timestamp
  status: ActionStatus
}

input UpdateCommentInput {
  author: ID
  htmlBody: String
}

union UpdateCommentMutationResult = AccessDeniedError | Comment | NotFoundError | ValidationError

input UpdateCompanyInput {
  bio: String
  name: String
}

input UpdateCompanyMembershipInput {
  prototypeDepartment: String
  prototypeManager: String
}

union UpdateCompanyMembershipMutationResult = AccessDeniedError | NotFoundError | User

union UpdateCompanyMutationResult = AccessDeniedError | Company | NotFoundError

input UpdateCompanySettingsInput {
  updates: UpdatesSettingsInput
}

union UpdateCompanySettingsMutationResult = AccessDeniedError | Company | NotFoundError

type UpdateConnection implements Connection {
  edges: [UpdateEdge!]!
  pageInfo: PageInfo!
}

input UpdateDecisionMutationInput {
  commentsDueAt: Timestamp
  creator: ID
  decisionMaker: ID
  dueAt: Timestamp
  privacy: DecisionPrivacyStatus
  status: DecisionStatus
  title: String
}

union UpdateDecisionMutationResult = AccessDeniedError | Decision | NotFoundError

type UpdateDeleted {
  deletedUpdateId: ID!
}

union UpdateDocumentMutationResult = AccessDeniedError | Document | NotFoundError

type UpdateEdge implements Edge {
  node: Update!
}

type UpdateGoal {
  goal: Goal!
  nextActions: [Action]
  nextHtml: String
  reasonHtml: String
  status: UpdateGoalStatus!
}

input UpdateGoalDataInput {
  assigneeId: ID
  dueAt: Timestamp
  privacy: GoalPrivacyStatus
  status: GoalStatus
  title: String
}

union UpdateGoalMutationResult = AccessDeniedError | Goal | GoalUpdate | NotFoundError | ValidationError

enum UpdateGoalStatus {
  AT_RISK
  BEHIND
  CANCELLED
  DONE
  ON_TRACK
}

input UpdateGoalUpdateInput {
  nextActions: [ID]
  nextHtml: String
  reasonHtml: String
  status: GoalStatus
}

input UpdateGoodThingArgs {
  author: ID
}

union UpdateGoodThingMutationResult = AccessDeniedError | GoodThing | NotFoundError | ValidationError

input UpdateMeetingCanvasInput {
  byteContent: ByteArray
  id: ID!
  name: String
}

union UpdateMeetingCanvasResult = AccessDeniedError | MeetingCanvas | NotFoundError

input UpdateMeetingMutationInput {
  callLink: String
  description: String
  moderatorId: String
  privacyLevel: MeetingPrivacyLevel
  prototypeCategory: String
  subtitle: String
  title: String
}

union UpdateMeetingMutationResult = AccessDeniedError | Meeting | NotFoundError

input UpdateMeetingPrepInput {
  preReadAt: Timestamp
}

union UpdateMeetingPrepMutationResult = AccessDeniedError | MeetingPrep | NotFoundError | ValidationError

input UpdateMeetingSectionMutationInput {
  description: String
  filter: UpdatesFilterInput
  includedInPreWrite: Boolean
  preWriteDescription: String
  prototypeCategory: String
  title: String
}

union UpdateMeetingSectionMutationResult = AccessDeniedError | MeetingFeedbackSection | MeetingGoodThingsSection | MeetingPrepSection | MeetingReviewActionsSection | MeetingReviewDecisionsSection | MeetingReviewGoalsSection | MeetingTopicSection | MeetingUpdateListSection | NotFoundError | ValidationError

enum UpdateOrderBy {
  CREATED_AT_ASC
  CREATED_AT_DESC
  REPORT_AT_ASC
  REPORT_AT_DESC
}

enum UpdatePrivacyStatus {
  PRIVATE
  PUBLIC
}

input UpdatePrototypeFeedbackInput {
  fromId: ID
  privacy: PrototypeFeedbackPrivacyStatus
  score: Int
  status: FeedbackStatus
  toId: ID
}

union UpdatePrototypeFeedbackMutationResult = AccessDeniedError | NotFoundError | PrototypeFeedback | ValidationError

input UpdateTopicMutationInput {
  loomRecordingLink: String
  ownerId: ID
  recurringDate: Timestamp
  repeat: TopicRepeatType
  status: TopicStatus
  title: String
}

union UpdateTopicMutationResult = AccessDeniedError | NotFoundError | Topic

input UpdateUpdateInput {
  actions: [CRUDEditUpdateAction!]
  goals: [CRUDEditUpdateGoal!]
  goalsLoomLink: String
  privacy: UpdatePrivacyStatus
}

union UpdateUpdateMutationResult = AccessDeniedError | NotFoundError | Update | ValidationError

input UpdateUserInputData {
  accessToBeta: Boolean
  bioHtml: String
  emailVerified: Boolean
  firstName: String
  lastName: String
  password: String
  role: UserRole
  status: UserStatus
  token: String
}

union UpdateUserMutationResult = AccessDeniedError | NotFoundError | User

type UpdatesConnection implements Connection {
  edges: [UpdatesEdge!]!
  pageInfo: PageInfo!
}

type UpdatesEdge implements Edge {
  node: Update!
}

type UpdatesFilter {
  authors: [ID!]
}

input UpdatesFilterInput {
  authors: [ID!]
}

enum UpdatesRepeatType {
  BIWEEKLY
  NONE
  WEEKLY
}

type UpdatesSettings {
  dueDate: Timestamp
  repeat: UpdatesRepeatType
}

input UpdatesSettingsInput {
  dueDate: Timestamp
  repeat: UpdatesRepeatType
}

union UpdatesUpdatedEvent = Connected | Update | UpdateAccessRevoked | UpdateDeleted

type User implements ActionsContainer & DecisionsContainer & Node {
  accessToBeta: Boolean
  actions(after: String, assignee: ID, companyId: ID, createdAfter: Timestamp, first: Int!, orderBy: ActionOrderBy, status: [ActionStatus!], updatedAfter: Timestamp, waitingFor: ID): ActionConnection
  averageFeedbackScore(companyId: ID!): Float
  bioHtml: String
  canEditName: Boolean!
  canEditRole: Boolean!
  companies(after: String, first: Int!): UserCompaniesConnection
  companyMembership(companyId: ID!): CompanyMembership
  decisions(after: String, companyId: ID!, createdAfter: Timestamp, creator: ID, first: Int!, participatingIn: Boolean = true, status: [DecisionStatus!]): DecisionsConnection!
  email: String!
  feedbacks(after: String, companyId: ID!, createdAfter: Timestamp, first: Int!): PrototypeFeedbackConnection
  firstName: String
  goals(after: String, companyId: ID!, first: Int!, meetingId: ID, status: [GoalStatus!]): GoalsConnection
  goodThings(after: String, companyId: ID!, first: Int!, since: Timestamp): GoodThingConnection
  googleAccount: GoogleAccount
  id: ID!
  isMeetingParticipant(meetingId: ID!): Boolean
  lastName: String
  magicBellKey: String
  meetingPreps(after: String, companyId: ID!, first: Int, meetingId: ID): MeetingPrepConnection
  meetings(after: String, companyId: ID, first: Int!, participatingIn: Boolean = true): MeetingsConnection
  role: UserRole
  status: UserStatus
  updates(after: String, companyId: ID!, first: Int, orderBy: UpdateOrderBy): UpdateConnection
}

union UserAddedToCompanyEvent = CompanyMembersEdge | Connected

type UserCompaniesConnection implements Connection {
  edges: [UserCompaniesEdge!]!
  pageInfo: PageInfo!
}

type UserCompaniesEdge implements Edge {
  node: Company!
  role: CompanyRole!
}

union UserRemovedFromCompanyEvent = CompanyMembersEdge | Connected

type UserReview {
  reviewedAt: Timestamp!
  user: User!
}

enum UserRole {
  ADMIN
  USER
}

enum UserStatus {
  ACTIVE
  PENDING
}

union UserUpdatedEvent = Connected | Deleted | User

type UsersConnection implements Connection {
  edges: [UsersEdge!]!
  pageInfo: PageInfo!
}

type UsersEdge implements Edge {
  node: User!
}

type ValidationError implements Error {
  message: String!
}

input importedTopicInput {
  id: String!
  type: GenericTopicType!
}

`;
