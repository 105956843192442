import { EditorEvents, NodeViewRendererProps } from '@tiptap/core'
import { useEffect, useState } from 'react'

type Options = Pick<NodeViewRendererProps, 'node' | 'editor' | 'getPos'>
export const resolveNodeState = ({ node, editor, getPos }: Options) => {
  if (typeof getPos !== 'function') return undefined
  const from = getPos()
  const to = from + node.nodeSize
  const highlighted = editor.state.selection.ranges.some((range) => range.$from.pos <= from && range.$to.pos >= to)
  return { from, to, highlighted }
}

export const useIsNodeHighlighted = ({ editor, getPos, node }: Options) => {
  const [highlighted, setHighlighted] = useState(false)
  useEffect(() => {
    const callback = ({ editor }: EditorEvents['selectionUpdate']) => {
      const state = resolveNodeState({ editor, node, getPos })
      if (state) {
        setHighlighted(state.highlighted)
      }
    }
    editor.on('selectionUpdate', callback)
    return () => {
      editor.off('selectionUpdate', callback)
    }
  }, [editor, getPos, node])

  return highlighted
}
