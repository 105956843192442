import styled from '@emotion/styled'
import { css } from 'theme-ui'

/**
 * An (almost) completely unstyled component intended to be used as a starting point to implement custom buttons.
 * Has ARIA role=button by default.
 */
export const Pressable = styled.button(
  css({
    color: 'inherit',
    background: 'unset',
    padding: 'unset',
    border: 'unset',
    textAlign: 'unset',
    '&:enabled': {
      cursor: 'pointer',
    },
  }),
)

Pressable.defaultProps = {
  type: 'button', // to make it not submit a parent form by default
}
