import { ReactRenderer } from '@tiptap/react'
import { SuggestionOptions } from '@tiptap/suggestion'
import { MentionList, MentionListRef } from './components/MentionList'

export const renderMentionList: SuggestionOptions['render'] = () => {
  let component: ReactRenderer<MentionListRef>

  return {
    onStart: (props) => {
      component = new ReactRenderer(MentionList, {
        props,
        editor: props.editor,
      })
    },

    onUpdate(props) {
      component.updateProps(props)
    },

    onKeyDown(props) {
      return component.ref?.onKeyDown?.(props) ?? false
    },

    onExit() {
      component.destroy()
    },
  }
}
