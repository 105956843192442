import { Global } from '@emotion/react'
import emotionNormalize from 'emotion-normalize'
import React from 'react'
import { css } from 'theme-ui'

export const NormalizeGlobal = () => (
  <Global
    styles={[
      emotionNormalize,
      css({
        'body,html,#__next': {
          height: '100%',

          fontFamily: 'body',
          fontWeight: 'body',
          fontSize: 1,
          color: 'text',
        },

        '*, *::after, *::before': {
          boxSizing: 'border-box',
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          fontSmoothing: 'antialiased',
        },

        'h1,h2,h3,h4': {
          margin: 0,
        },
      }),
    ]}
  />
)
