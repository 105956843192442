import { InMemoryCache, InMemoryCacheConfig } from '@apollo/client'
import { buildASTSchema } from 'graphql'
import _ from 'lodash'
import introspectionResult from '../gen/graphql/introspection-result.json'
import { StrictTypedTypePolicies } from '../gen/graphql/type-policies'
import { typeDefs } from '../gen/graphql/typeDefs'
import { inferTypePolicies } from './inferTypePolicies'

const inferredTypePolicies = inferTypePolicies(buildASTSchema(typeDefs))

const customTypePolicies: StrictTypedTypePolicies = {
  Comment: {
    fields: {
      emojiReactions: {
        merge: false,
      },
    },
  },
}

const typePolicies = _.merge({}, inferredTypePolicies, customTypePolicies)

export const createCache = (options?: InMemoryCacheConfig) =>
  new InMemoryCache({
    ...options,
    possibleTypes: introspectionResult.possibleTypes,
    typePolicies,
  })
