import {
  GlobalCommentsSubscriptionDataFragment,
  GlobalCommentsSubscriptionDocument,
} from '../../../gen/graphql/documents'
import { useSubscription } from '../../apollo'
import { defineEventBusTopic, useEmitEventBusEvent } from '../../eventbus'
import { addCommentToCache } from '../utils/addCommentToCache'

export type CommentsUpdatedEvent =
  | {
      type: 'deleted'
      id: string
    }
  | {
      type: 'upserted'
      comment: GlobalCommentsSubscriptionDataFragment
    }

export const CommentsUpdatedTopic = defineEventBusTopic<CommentsUpdatedEvent>('commentsUpdated')

export const GlobalCommentsSubscription = () => {
  const publish = useEmitEventBusEvent(CommentsUpdatedTopic)

  useSubscription(GlobalCommentsSubscriptionDocument, {
    onSubscriptionData: ({ subscriptionData: { data }, client }) => {
      const evictFromCache = (id: string) => {
        publish({ type: 'deleted', id })

        client.cache.evict({
          id: client.cache.identify({ __typename: 'Comment', id }),
        })
        client.cache.gc()
      }

      switch (data?.commentsUpdated?.__typename) {
        case 'CommentDeleted':
          evictFromCache(data.commentsUpdated.deletedCommentId)
          break
        case 'CommentAccessRevoked':
          evictFromCache(data.commentsUpdated.revokedCommentId)
          break
        case 'Comment':
          addCommentToCache(client.cache, data.commentsUpdated)
          publish({ type: 'upserted', comment: data.commentsUpdated })
          break
      }
    },
  })

  return null
}
