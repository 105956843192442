import styled from '@emotion/styled'
import React, { useContext } from 'react'
import { css } from 'theme-ui'
import { variant } from '../../helpers'

export type TextProps = React.ComponentPropsWithRef<typeof Text>

const TextContext = React.createContext(false)

export type TextOwnProps = {
  variant?: 'jumbo' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'body' | 'small'
  bold?: boolean
  underline?: boolean
  color?: string
  uppercase?: boolean
  overflow?: 'default' | 'clip' | 'ellipsis'

  // To handle edge case with as="a" links.
  target?: string
  href?: string
}

const BaseText = styled.span<TextOwnProps>(
  ({ bold, underline, uppercase, color }) =>
    css({
      fontWeight: bold && 'bold',
      textDecoration: underline && 'underline',
      textTransform: uppercase && 'uppercase',
      color,
    }),
  variant({
    prop: 'variant',
    variants: {
      jumbo: {
        fontSize: 6,
        fontWeight: 500,
        lineHeight: 1.2,
        fontFamily: 'heading',
      },
      h1: {
        fontSize: 5,
        fontWeight: 'bold',
        lineHeight: 1.3,
        fontFamily: 'heading',
      },
      h2: {
        fontSize: 4,
        fontWeight: 500,
        lineHeight: 1.3,
        fontFamily: 'heading',
      },
      h3: {
        fontSize: 3,
        fontWeight: 500,
        lineHeight: 1.4,
        fontFamily: 'heading',
      },
      h4: {
        fontSize: 2,
        fontWeight: 500,
        lineHeight: 1.4,
        fontFamily: 'heading',
      },
      h5: {
        fontSize: '17px' /* Override for H5 that doesnt not exist in our type scale yet */,
        fontWeight: 500,
        lineHeight: 1.4,
        fontFamily: 'heading',
      },
      body: {
        fontSize: 1,
        lineHeight: 1.5,
        fontFamily: 'body',
      },
      small: {
        fontSize: 0,
        lineHeight: 1.4,
        fontFamily: 'body',
      },
    },
  }),
  variant({
    prop: 'overflow',
    variants: {
      default: {},
      clip: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'clip',
      },
      ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
  }),
)

export const Text = (props: React.ComponentPropsWithoutRef<typeof BaseText>) => {
  // use default variant only if not nested
  const withinText = useContext(TextContext)
  return (
    <TextContext.Provider value={true}>
      <BaseText variant={withinText ? undefined : 'body'} {...props} />
    </TextContext.Provider>
  )
}

export type HeadingProps = TextProps & {
  level: 1 | 2 | 3 | 4 | 5
}

export const Heading = ({ level = 1, ...restProps }: HeadingProps) => {
  const levelMap = {
    1: {
      variant: 'h1',
      element: 'h1',
    },
    2: {
      variant: 'h2',
      element: 'h2',
    },
    3: {
      variant: 'h3',
      element: 'h3',
    },
    4: {
      variant: 'h4',
      element: 'h4',
    },
    5: {
      variant: 'h5',
      element: 'h5',
    },
  } as const
  const selectedLevel = levelMap[level]
  return <Text {...restProps} variant={selectedLevel.variant} as={selectedLevel.element} />
}
