import { Suspender } from '@mm/company-ui'
import dynamic from 'next/dynamic'
import React, { useMemo } from 'react'
import { useLocalStorage } from 'react-use'
import { ActiveCompanyProviderDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { activeCompanyContext, ActiveCompanyContextValue } from '../context/activeCompanyContext'

export const localStorageKey = 'active-company'

// ActiveCompanyProviderImpl is optimistic and will return the active company from local storage
// 1. We will assume that if there is an active company in local storage, it is an active company
// 2. Will will simultaneously fetch company data from the server to try to invalidate the local storage value
//    a. If the stored value is invalid, the server will switch the active company to the first company in the list
//    b. If there are no company memberships, we will finally return active company as null
// That way the UI rendering is not blocked by the server request.

export const ActiveCompanyProviderImpl: React.FC = ({ children }) => {
  const [activeCompanyId, setActiveCompanyId] = useLocalStorage<string>(localStorageKey, undefined, { raw: true })

  const { data, loading } = useQuery(ActiveCompanyProviderDocument, {
    fetchPolicy: 'cache-first',
    variables: {
      companyId: activeCompanyId ?? '',
      includeCompany: !!activeCompanyId,
    },
    onCompleted: (data) => {
      if (data.company == null) {
        const nextPossibleCompanyId = data.me?.companies?.edges[0]?.node.id

        // set it or clear it, depending on if there is a next possible company
        setActiveCompanyId(nextPossibleCompanyId)
      }
    },
  })

  const isOptimistic = data == null && loading
  const nextPossibleCompany = data?.me?.companies?.edges[0]?.node
  let effectiveCompanyId: string | null
  let activeCompanyName: string | null
  if (isOptimistic) {
    effectiveCompanyId = activeCompanyId ?? null
    activeCompanyName = null
  } else if (data?.company) {
    effectiveCompanyId = data.company.id
    activeCompanyName = data.company.name
  } else if (nextPossibleCompany) {
    effectiveCompanyId = nextPossibleCompany.id
    activeCompanyName = nextPossibleCompany.name
  } else {
    effectiveCompanyId = null
    activeCompanyName = null
  }

  const value = useMemo<ActiveCompanyContextValue>(
    () => ({
      activeCompanyId: effectiveCompanyId ?? null,
      activeCompanyName,
      setActiveCompanyId,
      isOptimistic,
    }),
    [effectiveCompanyId, activeCompanyName, setActiveCompanyId, isOptimistic],
  )

  // Only return suspender if we have not resolved company id yet, and we are currently fetching the active company
  if (effectiveCompanyId == null && loading) {
    return <Suspender />
  }

  return <activeCompanyContext.Provider value={value}>{children}</activeCompanyContext.Provider>
}

// TODO localStorage is not available on the server. If we want to support SSR we need to either use cookies, or
//   dynamic path/query params
export const ActiveCompanyProvider = dynamic(() => Promise.resolve(ActiveCompanyProviderImpl), {
  ssr: false,
})
