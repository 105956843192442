import { keyframes } from '@emotion/react'

export type theme = typeof visualRefreshTheme
declare module '@emotion/react' {
  export interface Theme extends theme {}
}

// 2 breaking points: mobile and desktop
const breakpoints = ['976px']

const base = {
  blues: {
    default: '#4543B9',
    50: '#ECECF8',
    100: '#DADAF1',
    200: '#B5B4E4',
    300: '#908FD6',
    400: '#6B69C9',
    500: '#4543B9',
    600: '#383696',
    700: '#2A2970',
    800: '#1C1B4B',
    900: '#0E0E25',
    950: '#020F18',
    1000: '#383696',
  },
  reds: {
    default: '#DC2222',
    50: '#FCE9E9',
    100: '#F8D3D3',
    200: '#F1A7A7',
    300: '#EA7B7B',
    400: '#E44E4E',
    500: '#DC2222',
    600: '#B11B1B',
    700: '#841515',
    800: '#580E0E',
    900: '#2C0707',
    950: '#160303',
  },
  greens: {
    default: '#2CD296',
    50: '#EAFBF4',
    100: '#D5F6EA',
    200: '#ABEDD5',
    300: '#80E5C0',
    400: '#56DCAB',
    500: '#2CD295',
    600: '#23A978',
    700: '#1A7F5A',
    800: '#12543C',
    900: '#092A1E',
    950: '#04150F',
  },
  yellows: {
    default: 'FFE47C',
    50: '#FFFAE5',
    100: '#FFF5CC',
    200: '#FFEB99',
    300: '#FFE066',
    400: '#FFD633',
    500: '#FFE57C',
    600: '#EBD168',
    700: '#CDB34A',
    800: '#91770E',
    900: '#5F450A',
  },
  grays: {
    default: '#71757D',
    25: '#FAFAFA',
    50: '#F2F2F3',
    100: '#E4E5E7',
    200: '#DADCE0',
    300: '#AFB1B6',
    400: '#94979E',
    500: '#71757D',
    600: '#61646B',
    700: '#494B50',
    800: '#303236',
    900: '#18191B',
    950: '#0C0D0D',
  },
  alphaGrays: {
    default: 'rgba(0, 0, 0, 0.5)',
    5: 'rgba(0, 0, 0, 0.05)',
    10: 'rgba(0, 0, 0, 0.1)',
    20: 'rgba(0, 0, 0, 0.2)',
    30: 'rgba(0, 0, 0, 0.3)',
    40: 'rgba(0, 0, 0, 0.4)',
    50: 'rgba(0, 0, 0, 0.5)',
    60: 'rgba(0, 0, 0, 0.6)',
    70: 'rgba(0, 0, 0, 0.7)',
    80: 'rgba(0, 0, 0, 0.8)',
  },
}

export const visualRefreshTheme = {
  breakpoints,
  mq: breakpoints.map((bp) => `@media (min-width: ${bp})`),
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
  sizes: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
  fonts: {
    body: 'Inter, Arial, sans-serif',
    heading: 'Inter, Arial, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 20, 24, 28, 32, 40],
  fontWeights: {
    body: 400,
    bold: 600,
    heading: 700,
    extrabold: 900,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    // Tokens
    border: base.grays[100],
    'border-medium': base.grays[200],
    'border-strong': base.grays[300],
    'system-border-warning': base.reds[500],
    'system-border-warning-light': base.reds[200],
    'system-border-alert': base.yellows[600],
    'system-border-alert-medium': base.yellows[700],

    text: base.grays[900],
    'text-light': base.grays[500],
    'text-medium': base.grays[600],
    'text-disabled': base.grays[200],
    'text-disabled-medium': base.grays[300],
    'text-inverted': 'white',

    'system-text-success': base.greens[600],
    'system-text-success-light': base.greens[400],
    'system-text-warning': base.reds[400],
    'system-text-alert': '#5F450A',
    'system-text-alert-light': '#91770E',

    background: 'white',
    'background-weak': base.grays[25],
    'background-light': base.grays[50],
    'background-medium': base.grays[100],
    'background-strong': base.grays[200],
    'background-heavy': base.grays[600],
    'background-light-alpha': base.alphaGrays[5],
    'background-medium-alpha': base.alphaGrays[10],
    'background-heavy-alpha': base.alphaGrays[20],
    'background-strong-alpha': base.alphaGrays[30],
    'background-hover': base.alphaGrays[10],

    'accent-text': base.blues[500],
    'accent-text-medium': base.blues[1000],

    'accent-background': base.blues[400],
    'accent-background-light': base.blues[50],
    'accent-background-light-hover': base.blues[100],
    'accent-background-medium': base.blues[200],
    'accent-background-hover': base.blues[300],
    'accent-background-medium-light': base.blues[100],

    'accent-border': base.blues[500],
    'accent-border-light': base.blues[200],

    'system-background-alert': base.yellows[200],
    'system-background-warning': base.reds[500],
    'system-background-success': base.greens[400],
    'system-background-success-light': base.greens[100],
    'system-background-success-medium': base.greens[200],
    'system-background-alert-light': base.yellows[200],
    'system-background-alert-medium': base.yellows[300],
    'system-background-alert-strong': base.yellows[400],
    'system-background-warning-light': base.reds[50],
    'system-background-warning-medium': base.reds[400],

    // Deprecated
    lightBlue: {
      100: '#ECF3F7',
      200: '#9DD1F4',
      300: '#67B8EE',
      400: '#3AA4EC',
      default: '#1392EA',
    },
    darkBlue: {
      70: '#EAEDF1',
      100: '#D2D7DD',
      200: '#A6B0BA',
      300: '#79899A',
      400: '#4D6278',
      default: '#1F3B55',
    },
    white: '#FFFFFF',
    black: '#31353D',
    greyscale: {
      white: '#FFFFFF',
      50: '#FAFAFA',
      100: '#F2F2F2',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#CDCDCD',
      500: '#9196A1',
      700: '#60646B',
      black: '#31353D',
    },
    transparentBlack: {
      5: 'rgba(0,0,0,0.05)',
      10: 'rgba(0,0,0,0.10)',
      20: 'rgba(0,0,0,0.20)',
      30: 'rgba(0,0,0,0.30)',
      40: 'rgba(0,0,0,0.40)',
      50: 'rgba(0,0,0,0.50)',
    },
    orange: {
      50: '#FFF3E0',
      300: '#FFB74D',
      default: '#FF9800',
      700: '#F57C00',
    },
    system: {
      red: '#FA4040',
      green: '#36DCA0',
    },
  },
  shadows: {
    default: '0px 12px 40px rgba(0, 0, 0, 0.1)',
    defaultTransparent: '0px 12px 40px rgba(0, 0, 0, 0)',
    none: 'none',
  },
  radii: {
    none: '0px',
    default: '4px',
    medium: '8px',
    topMedium: '8px 8px 0 0',
    bottomMedium: '0 0 8px 8px',
    large: '12px',
    xl: '20px',
    full: '9999px',
  },
  transition: {
    none: 'none',
    default: '0.3s ease',
    long: '0.6s ease',
  },
  animation: {
    none: 'none',
    default: keyframes({ from: { opacity: 0 }, to: { opacity: 1 } }),
    bounce: keyframes`
    from, 20%, 53%, 80%, to {
      transform: translate3d(0,0,0);
    }
  
    40%, 43% {
      transform: translate3d(0, -2px, 0);
    }
  
    70% {
      transform: translate3d(0, -2px, 0);
    }
  
    90% {
      transform: translate3d(0,-2px,0);
    }
  `,
  },
}

visualRefreshTheme.space[0.25] = 2
visualRefreshTheme.space[0.5] = 4
visualRefreshTheme.space[0.75] = 6
visualRefreshTheme.space[1.25] = 10
visualRefreshTheme.space[1.5] = 12
visualRefreshTheme.space[1.75] = 14
visualRefreshTheme.space[2.5] = 20

visualRefreshTheme.sizes[0.5] = 4
visualRefreshTheme.sizes[1.5] = 12
visualRefreshTheme.sizes[2.5] = 20
