import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const CheckIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="check" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7353 4.2742C19.0881 4.63982 19.0881 5.23261 18.7353 5.59823L8.059 16.7258C7.88563 16.9055 7.64924 17.0044 7.40409 16.9998C7.15894 16.9953 6.92611 16.8877 6.75903 16.7017L2.24209 11.6738C1.90211 11.2954 1.92252 10.703 2.28768 10.3506C2.65284 9.9983 3.22447 10.0195 3.56445 10.3979L7.44343 14.7157L17.4577 4.2742C17.8105 3.90858 18.3825 3.90858 18.7353 4.2742Z"
    />
  </IconBase>
)
