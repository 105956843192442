import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { Suspender } from '@mm/company-ui'
import Router from 'next/router'
import React from 'react'

// must be a stable reference, otherwise Auth0 goes crazy
const handleRedirectCallback = (appState: AppState) => {
  void Router.replace(appState?.returnTo || '/')
}

export const AuthenticationProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_ISSUER_DOMAIN}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
      audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
      redirectUri={typeof window === 'undefined' ? '' : `${window.location.origin}/auth0-redirect`}
      onRedirectCallback={handleRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <SuspendWhileLoadingAuthInfo>{children}</SuspendWhileLoadingAuthInfo>
    </Auth0Provider>
  )
}

const SuspendWhileLoadingAuthInfo: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { isLoading } = useAuth0()
  return isLoading ? <Suspender /> : <>{children}</>
}
