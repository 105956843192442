import styled from '@emotion/styled'
import { Avatar, Flex, FloatingTooltip, formatUserDisplayName, PopupWrapper, Text } from '@mm/company-ui'
import { NodeViewWrapper } from '@tiptap/react'
import Link from 'next/link'
import React from 'react'
import { css } from 'theme-ui'
import { MentionPillDocument } from '../../../../../gen/graphql/documents'
import { useQuery } from '../../../../apollo'
import { useActiveUser } from '../../../../users'

export type MentionPillProps = {
  userId: string
  onClick?: React.MouseEventHandler
  highlighted?: boolean
}

const BaseMentionPill = styled.a<{ isSelf?: boolean; highlighted?: boolean }>(
  css({
    position: 'relative',
    textDecoration: 'none !important',
    color: 'inherit !important',
    paddingLeft: ({ space }) => `calc(${space?.[1] ?? 8}px + 1em)`,
    paddingRight: 0.5,
    paddingY: 0.25,
    borderRadius: 8,
    transition: '150ms background-color ease-in-out',
    whiteSpace: 'nowrap',
    userSelect: 'none',
  }),
  ({ isSelf, highlighted }) =>
    css(
      isSelf
        ? {
            backgroundColor: highlighted ? 'system-background-alert-medium' : 'system-background-alert-light',
            '&:hover': {
              backgroundColor: highlighted ? 'system-background-alert-strong' : 'system-background-alert-medium',
            },
          }
        : {
            backgroundColor: highlighted ? 'accent-background-medium-light' : 'background-light-alpha',
            '&:hover': {
              backgroundColor: highlighted ? 'accent-background-medium' : 'background-medium-alpha',
            },
          },
    ),
)

export const MentionPill = ({ userId, onClick, highlighted }: MentionPillProps) => {
  const activeUser = useActiveUser()
  const { data, loading } = useQuery(MentionPillDocument, {
    variables: { userId },
  })

  const user = data?.user
  if (user == null) {
    if (loading) {
      return <></>
    }
    return (
      <BaseMentionPill highlighted={highlighted} data-highlighted={highlighted}>
        <Avatar
          size="adaptive"
          name="?"
          sx={{ position: 'absolute', top: '50%', left: 0.5, transform: 'translateY(-50%)' }}
        />
        Unknown
      </BaseMentionPill>
    )
  }

  const name = formatUserDisplayName(user)

  return (
    <NodeViewWrapper as="span">
      <FloatingTooltip
        placement="top-start"
        tooltip={
          <PopupWrapper sx={{ padding: 2 }} data-testid="MentionPill-Tooltip">
            <Flex column gap={1}>
              <Avatar haveTooltip={false} size="large" name={name} />
              <Text>
                <Text bold>{name}</Text>
                <br />
                {user.email}
              </Text>
            </Flex>
          </PopupWrapper>
        }
      >
        {({ getReferenceProps }) => (
          <Link href={{ pathname: '/profile/[id]', query: { id: user.id } }} passHref>
            <BaseMentionPill
              isSelf={user.id === activeUser?.id}
              highlighted={highlighted}
              data-highlighted={highlighted}
              {...getReferenceProps({ onClick })}
            >
              <Avatar
                haveTooltip={false}
                size="adaptive"
                name={name}
                sx={{ position: 'absolute', top: '50%', left: 0.5, transform: 'translateY(-50%)' }}
              />
              {name}
            </BaseMentionPill>
          </Link>
        )}
      </FloatingTooltip>
    </NodeViewWrapper>
  )
}
