export type DisplayNameSchemes = 'FULL_NAME_WITH_FALLBACK' | 'FIRST_NAME_WITH_FALLBACK' | 'FULL_NAME' | 'FIRST_NAME'
export type UserDisplay = { firstName?: string | null; lastName?: string | null; email: string; id: string }

export const formatUserDisplayName = (user: UserDisplay, scheme: DisplayNameSchemes = 'FULL_NAME_WITH_FALLBACK') => {
  switch (scheme) {
    case 'FULL_NAME_WITH_FALLBACK': {
      if (user.firstName || user.lastName) {
        return [user.firstName, user.lastName].filter(Boolean).join(' ')
      }

      return user.email
    }
    case 'FIRST_NAME_WITH_FALLBACK': {
      if (user.firstName) {
        return user.firstName
      }

      return user.email
    }
    case 'FULL_NAME': {
      return [user.firstName, user.lastName].filter(Boolean).join(' ')
    }
    case 'FIRST_NAME': {
      if (user.firstName) {
        return user.firstName
      }
      return ''
    }
  }
}
