import { keyframes } from '@emotion/react'
import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

const spin = keyframes({
  '100%': {
    transform: 'rotate(360deg)',
  },
})

export const LoadingIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="loading" viewBox="0 0 20 20" {...props} data-testid="loading-icon">
    <path
      d="M 10 2
           A 8 8 0 0 1 10 18"
      strokeWidth={2}
      fill="none"
      sx={{
        animation: `${spin} 1s linear infinite`,
        transformOrigin: 'center',
      }}
    />
  </IconBase>
)
