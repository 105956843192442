import React from 'react'

export type OnboardingTrigger = {
  meetingSectionDismissed?: boolean
}

type OnboardingTriggerValue = [OnboardingTrigger, (key: keyof OnboardingTrigger) => void]

export const OnboardingTriggerContext = React.createContext<OnboardingTriggerValue>([
  {},
  () => {
    //
  },
])

type OnboardingTriggerProviderProps = {
  state?: OnboardingTrigger
  children: React.ReactNode
}

export const OnboardingTriggerProvider: React.FC<OnboardingTriggerProviderProps> = ({ children, state }) => {
  const [value, setValue] = React.useState<OnboardingTrigger>(state || {})

  const dispatch = (key: keyof OnboardingTrigger) => {
    setValue({ ...value, [key]: true })
  }

  return <OnboardingTriggerContext.Provider value={[value, dispatch]}>{children}</OnboardingTriggerContext.Provider>
}
