import { ThemeProvider } from '@emotion/react'
import {
  EditablePluginProvider,
  ErrorBoundary,
  Flex,
  InternalErrorBanner,
  ModalProvider,
  NormalizeGlobal,
  ToastContainer,
  visualRefreshTheme,
} from '@mm/company-ui'
import { CompanyOSLogoIcon, LoadingIcon } from '@mm/company-ui-icons'
import type { AppProps } from 'next/app'
import Router from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import React, { Suspense } from 'react'
import { AnalyticsProvider } from '../modules/analytics'
import { AuthenticatedApolloProvider } from '../modules/apollo'
import { AuthenticationProvider } from '../modules/auth'
import { CapabilitiesProvider } from '../modules/capabilities'
import { CommandCenterProvider } from '../modules/commands'
import { ActiveCompanyProvider } from '../modules/companies'
import { initDatadogRum } from '../modules/datadog'
import { editablePlugins } from '../modules/editor'
import { EventBusProvider } from '../modules/eventbus'
import { FeatureFlagsProvider } from '../modules/featureFlags'
import { IntercomProvider } from '../modules/intercom/components/IntercomProvider'
import { MaintenanceModeProvider } from '../modules/maintenance'
import { OnboardingTriggerProvider } from '../modules/onboarding'
import { initSentry } from '../modules/sentry'
import { ActiveUserProvider } from '../modules/users'
import { AppVersionUpdateNotifier } from './AppVersionUpdateNotifier'
import { GlobalSubscriptions } from './GlobalSubscriptions'

const version =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ||
  (process.env.NODE_ENV !== 'production' ? process.env.NODE_ENV || 'development' : 'unknown')

initDatadogRum({ version })
initSentry()

NProgress.configure({ showSpinner: false })

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

type Props = AppProps & {
  err: Error
}

export default function CompanyOSApp({ Component, pageProps, err }: Props) {
  return (
    <EventBusProvider>
      <ThemeProvider theme={visualRefreshTheme}>
        <NormalizeGlobal />
        <ErrorBoundary fallback={<InternalErrorBanner />}>
          <ClientSideSuspense>
            <AuthenticationProvider>
              <AuthenticatedApolloProvider
                appName="companyos"
                appVersion={version}
                getAppPath={() => Router.asPath}
                hasPendingOperationsLink
              >
                <ActiveUserProvider>
                  <ActiveCompanyProvider>
                    <FeatureFlagsProvider>
                      <CommandCenterProvider>
                        <CapabilitiesProvider>
                          <AnalyticsProvider appVersion={version}>
                            <IntercomProvider>
                              <ModalProvider>
                                <OnboardingTriggerProvider>
                                  <MaintenanceModeProvider>
                                    <ToastContainer />
                                    <AppVersionUpdateNotifier appVersion={version} />
                                    <EditablePluginProvider plugins={editablePlugins}>
                                      <GlobalSubscriptions />
                                      <Component {...pageProps} err={err} />
                                    </EditablePluginProvider>
                                  </MaintenanceModeProvider>
                                </OnboardingTriggerProvider>
                              </ModalProvider>
                            </IntercomProvider>
                          </AnalyticsProvider>
                        </CapabilitiesProvider>
                      </CommandCenterProvider>
                    </FeatureFlagsProvider>
                  </ActiveCompanyProvider>
                </ActiveUserProvider>
              </AuthenticatedApolloProvider>
            </AuthenticationProvider>
          </ClientSideSuspense>
        </ErrorBoundary>
      </ThemeProvider>
    </EventBusProvider>
  )
}

// Suspense is still experimental in Next. The component will throw an error if rendered via SSR.
const ClientSideSuspense = ({ children }: { children: React.ReactNode }) =>
  process.browser ? (
    <Suspense
      fallback={
        <Flex column gap={2} align="center" justify="center" sx={{ height: '100%' }} data-testid="app-suspended">
          <CompanyOSLogoIcon width={48} height={48} color="accent-background" />
          <LoadingIcon width={20} height={20} color="text-disabled" />
        </Flex>
      }
    >
      {children}
    </Suspense>
  ) : (
    <></>
  )
