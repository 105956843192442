import { GlobalGoalsSubscriptionDataFragment, GlobalGoalsSubscriptionDocument } from '../../../gen/graphql/documents'
import { useSubscription } from '../../apollo'
import { defineEventBusTopic, useEmitEventBusEvent } from '../../eventbus'
import { useAddGoalToCache } from '../hooks/useAddGoalToCache'

export type GoalsUpdatedEvent =
  | {
      type: 'deleted'
      id: string
    }
  | {
      type: 'upserted'
      goal: GlobalGoalsSubscriptionDataFragment
    }

export const GoalsUpdatedTopic = defineEventBusTopic<GoalsUpdatedEvent>('goalsUpdated')

export const GlobalGoalsSubscription = () => {
  const addGoalToCache = useAddGoalToCache()
  const publish = useEmitEventBusEvent(GoalsUpdatedTopic)

  useSubscription(GlobalGoalsSubscriptionDocument, {
    onSubscriptionData: ({ subscriptionData: { data }, client }) => {
      const evictFromCache = (id: string) => {
        publish({ type: 'deleted', id })

        client.cache.evict({
          id: client.cache.identify({ __typename: 'Goal', id }),
        })
        client.cache.gc()
      }

      switch (data?.goalsUpdated?.__typename) {
        case 'GoalDeleted':
          evictFromCache(data.goalsUpdated.deletedGoalId)
          break
        case 'GoalAccessRevoked':
          evictFromCache(data.goalsUpdated.revokedGoalId)
          break
        case 'Goal':
          addGoalToCache(client.cache, data.goalsUpdated)
          publish({ type: 'upserted', goal: data.goalsUpdated })
          break
      }
    },
  })

  return null
}
