/**
 * Returns `true` is the value is neither `null` nor `undefined`, `false` otherwise
 */
export const isNonNil = <T>(value: T): value is NonNullable<T> => value != null

/**
 * Returns `true` if the list includes the passed value
 */
export function isOneOf<T>(list: readonly T[], value: unknown): value is T
/**
 * Returns a predicate function to check whether the given value exists in the given array
 */
export function isOneOf<T>(list: readonly T[]): (value: unknown) => value is T
export function isOneOf<T>(list: readonly T[], value?: unknown) {
  return arguments.length < 2 ? (value: unknown) => list.includes(value as T) : list.includes(value as T)
}

/**
 * Returns true if the list is not empty (length > 0)
 */
export function isNonEmptyArray<T>(list: T[]): list is [T, ...T[]]
/**
 * Returns true if the list is not empty (length > 0)
 */
export function isNonEmptyArray<T>(list: readonly T[]): list is readonly [T, ...T[]]
export function isNonEmptyArray(list: readonly any[]) {
  return list.length > 0
}

/**
 * Returns first element of the non-empty array
 */
export function head<T>(list: readonly [T, ...T[]]): T
/**
 * Returns first element of the array, or `undefined` if empty
 */
export function head<T>(list: readonly T[]): T | undefined
export function head(list: readonly any[]) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return list.length > 0 ? list[0] : undefined
}

/**
 * Returns last element of the non-empty array
 */
export function last<T>(list: readonly [T, ...T[]]): T
/**
 * Returns last element of the array, or `undefined` if empty
 */
export function last<T>(list: readonly T[]): T | undefined
export function last(list: readonly any[]) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return list.length > 0 ? list[list.length - 1] : undefined
}

/**
 * Returns all but the last elements of the array, or `[]` if empty
 */
export function init<T>(list: readonly T[]): T[] {
  return list.slice(0, list.length - 1)
}

/**
 * Returns all but the first elements of the array, or `[]` if empty
 */
export function tail<T>(list: readonly T[]): T[] {
  return list.slice(1)
}
