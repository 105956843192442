import React from 'react'

export type ActiveCompanyContextValue = {
  activeCompanyId: string | null
  activeCompanyName?: string | null
  setActiveCompanyId: (activeCompanyId: string) => void
  isOptimistic: boolean
}

export const activeCompanyContext = React.createContext<ActiveCompanyContextValue | null>(null)
