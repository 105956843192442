// Colors extracted from the Avvvatars project: https://github.com/nusu/avvvatars
export const DEFAULT_COLORS = [
  { backgroundColor: '#F7F9FC', textColor: '#060A23' },
  { backgroundColor: '#EEEDFD', textColor: '#4409B9' },
  { backgroundColor: '#FFEBEE', textColor: '#BD0F2C' },
  { backgroundColor: '#FDEFE2', textColor: '#C56511' },
  { backgroundColor: '#E7F9F3', textColor: '#216E55' },
  { backgroundColor: '#EDEEFD', textColor: '#05128A' },
  { backgroundColor: '#ECFAFE', textColor: '#1F84A3' },
  { backgroundColor: '#F2FFD1', textColor: '#526E0C' },
  { backgroundColor: '#FFF7E0', textColor: '#935F10' },
  { backgroundColor: '#FDF1F7', textColor: '#973562' },
  { backgroundColor: '#EAEFE6', textColor: '#69785E' },
  { backgroundColor: '#E0E6EB', textColor: '#2D3A46' },
  { backgroundColor: '#E4E2F3', textColor: '#280F6D' },
  { backgroundColor: '#E6DFEC', textColor: '#37364F' },
  { backgroundColor: '#E2F4E8', textColor: '#363548' },
  { backgroundColor: '#E6EBEF', textColor: '#4D176E' },
  { backgroundColor: '#EBE6EF', textColor: '#AB133E' },
  { backgroundColor: '#E8DEF6', textColor: '#420790' },
  { backgroundColor: '#D8E8F3', textColor: '#222A54' },
  { backgroundColor: '#ECE1FE', textColor: '#192251' },
]
