import { CompanyOSKitOptions } from '@mm/tiptap-extension'
import { NodeViewRendererProps } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import React, { useCallback } from 'react'
import { resolveNodeState, useIsNodeHighlighted } from '../../hooks/useIsNodeHighlighted'
import { MentionPill } from './components/MentionPill'
import { renderMentionList } from './renderMentionList'

const MentionNodeView = ({ node, editor, getPos }: NodeViewRendererProps) => {
  const highlighted = useIsNodeHighlighted({ node, editor, getPos })
  const handleClick = useCallback<React.MouseEventHandler>(
    (event) => {
      if (editor.isEditable) {
        const state = resolveNodeState({ node, editor, getPos })
        if (state && !state.highlighted) {
          editor.chain().focus().setTextSelection({ from: state.from, to: state.to }).run()
          event.preventDefault()
        }
      }
    },
    [editor, getPos, node],
  )
  return <MentionPill userId={node.attrs['id'] as string} onClick={handleClick} highlighted={highlighted} />
}

export const useMentionsConfig = (): CompanyOSKitOptions['mentions'] => {
  return {
    readonly: false,
    nodeView: ReactNodeViewRenderer(MentionNodeView),
    suggestion: {
      render: renderMentionList,
    },
  }
}
