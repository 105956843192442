import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useFeatureFlags } from '../../helpers'

type PopoverInterceptorContextValue = {
  interceptors: Array<() => boolean>
  registerInterceptor: (cb: () => boolean) => () => void
}
const PopoverInterceptorContext = createContext<PopoverInterceptorContextValue>({
  interceptors: [],
  registerInterceptor: () => () => {
    // do nothing
  },
})

const PopoverInterceptorProviderImpl = ({ children }: { children?: React.ReactNode }) => {
  const [interceptors, setInterceptors] = useState<Array<() => boolean>>([])
  const registerInterceptor = useCallback((cb: () => boolean) => {
    // create a clone to ensure uniqueness
    const uniqueCb = () => cb()
    setInterceptors((interceptors) => [...interceptors, uniqueCb])
    return () => {
      setInterceptors((interceptors) => interceptors.filter((interceptor) => interceptor !== uniqueCb))
    }
  }, [])

  const value = useMemo<PopoverInterceptorContextValue>(
    () => ({
      interceptors,
      registerInterceptor,
    }),
    [interceptors, registerInterceptor],
  )

  return <PopoverInterceptorContext.Provider value={value}>{children}</PopoverInterceptorContext.Provider>
}

export const PopoverInterceptorProvider = ({ children }: { children?: React.ReactNode }) => {
  const { preventClosingQuickAddActionsWhenDirty } = useFeatureFlags()
  if (!preventClosingQuickAddActionsWhenDirty) return <>{children}</>

  return <PopoverInterceptorProviderImpl>{children}</PopoverInterceptorProviderImpl>
}

export const usePopoverInterceptor = () => {
  return useContext(PopoverInterceptorContext)
}
