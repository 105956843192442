import { produce } from 'immer'
import invariant from 'invariant'
import {
  AddCommentToCacheDataFragmentDoc,
  AddCommentToCacheRepliesDataFragmentDoc,
  AddFeedbackCommentToCacheDataFragmentDoc,
  AddGoodThingCommentToCacheDataFragmentDoc,
  CommentViewerDataFragment,
  CommentsForTotalsFragmentFragmentDoc,
} from '../../../gen/graphql/documents'
import { ApolloCache } from '../../apollo'
import { addCommentToList } from './addCommentToList'

export const addCommentToCache = (cache: ApolloCache<any>, comment: CommentViewerDataFragment) => {
  if (comment.replyTo != null) {
    addCommentReplyToCache(cache, comment)
  } else {
    addRootCommentToCache(cache, comment)
  }
}

//TODO add other @connection based fragments T-2895
const fragmentMap = {
  Action: [AddCommentToCacheDataFragmentDoc, CommentsForTotalsFragmentFragmentDoc],
  User: [AddCommentToCacheDataFragmentDoc],
  Meeting: [AddCommentToCacheDataFragmentDoc],
  Topic: [AddCommentToCacheDataFragmentDoc, CommentsForTotalsFragmentFragmentDoc],
  Goal: [AddCommentToCacheDataFragmentDoc, CommentsForTotalsFragmentFragmentDoc],
  Decision: [AddCommentToCacheDataFragmentDoc, CommentsForTotalsFragmentFragmentDoc],
  PrototypeFeedback: [
    AddCommentToCacheDataFragmentDoc,
    AddFeedbackCommentToCacheDataFragmentDoc,
    CommentsForTotalsFragmentFragmentDoc,
  ],
  GoodThing: [
    AddCommentToCacheDataFragmentDoc,
    AddGoodThingCommentToCacheDataFragmentDoc,
    CommentsForTotalsFragmentFragmentDoc,
  ],
  Update: [AddCommentToCacheDataFragmentDoc, CommentsForTotalsFragmentFragmentDoc],
  MeetingPrep: [AddCommentToCacheDataFragmentDoc, CommentsForTotalsFragmentFragmentDoc],
}
interface UpdateFragmentCacheArgs {
  fragments: (typeof fragmentMap)[keyof typeof fragmentMap]
}

export const addRootCommentToCache = (cache: ApolloCache<any>, comment: CommentViewerDataFragment) => {
  const id = cache.identify({
    __typename: comment.parent.__typename,
    id: comment.parent.id,
  })
  const updateFragmentCache = ({ fragments }: UpdateFragmentCacheArgs) => {
    fragments.forEach((fragment) => {
      const fragmentData = cache.readFragment({
        fragment,
        id,
      })
      const updated = produce(fragmentData, (draft) => {
        addCommentToList(draft?.comments, comment)
      })
      if (updated !== fragmentData) {
        cache.writeFragment({
          fragment,
          id,
          data: updated,
        })
      }
    })
  }

  updateFragmentCache({ fragments: fragmentMap[comment.parent.__typename] })
}

export const addCommentReplyToCache = (cache: ApolloCache<any>, comment: CommentViewerDataFragment) => {
  invariant(comment.replyTo != null, 'must be a reply')

  const id = cache.identify({
    __typename: 'Comment',
    id: comment.replyTo.id,
  })

  const fragmentData = cache.readFragment({
    fragment: AddCommentToCacheRepliesDataFragmentDoc,
    id,
  })
  const updated = produce(fragmentData, (draft) => {
    addCommentToList(draft?.replies, comment)
  })
  if (updated !== fragmentData) {
    cache.writeFragment({
      fragment: AddCommentToCacheRepliesDataFragmentDoc,
      id,
      data: updated,
    })
  }
}
