// eslint-disable-next-line no-restricted-imports
import { ApolloError, useApolloClient, useQuery as useApolloQuery } from '@apollo/client'
import { useInternalState } from '@apollo/client/react/hooks/useQuery'
import { getMainDefinition } from '@apollo/client/utilities'
import { useCallback, useLayoutEffect, useMemo } from 'react'

export const useQuery: typeof useApolloQuery = (query, options = {}) => {
  const state = useInternalState(useApolloClient(options.client), query)
  useLayoutEffect(
    () => () => {
      // apollo has a bug when it updates state of an unmounted component sometimes
      state.forceUpdate = () => {
        // so we ignore updates on unmounted components
      }
    },
    [state],
  )
  const result = state.useQuery(options)
  const realSubscribeToMore = result.subscribeToMore
  const subscribeToMore = useCallback<typeof result.subscribeToMore>(
    (options) => {
      let closeCallback: (() => void) | undefined = undefined
      let closed = false
      const resubscribe = () => {
        if (closed) return
        closeCallback?.()
        closeCallback = realSubscribeToMore({
          ...options,
          onError: (error) => {
            options.onError?.(error)
            resubscribe()
          },
        })
      }
      resubscribe()
      return () => {
        closed = true
        closeCallback?.()
      }
    },
    [realSubscribeToMore],
  )

  const queryName = getMainDefinition(query).name?.value ?? '???'
  const { data, loading, error } = result
  const { fetchPolicy } = options ?? {}

  const cacheOnlyNoData = data == null && !loading && fetchPolicy === 'cache-only'
  const effectiveError = useMemo(() => {
    if (cacheOnlyNoData && error == null) {
      const message = `[${queryName}] Cache Miss. Make sure parent component includes the fragment in its query`
      // eslint-disable-next-line no-console
      console.warn(message)

      return new ApolloError({
        clientErrors: [new Error(message)],
      })
    } else {
      return error
    }
  }, [queryName, cacheOnlyNoData, error])

  return {
    ...result,
    subscribeToMore,
    error: effectiveError,
  }
}
