import { NextRouter } from 'next/router'
import { Route } from 'nextjs-routes'

type Trick<T extends Route['pathname']> = T

type RouterByPrefix = {
  [K in Route['pathname']]: Extract<Route['pathname'], `${K}${string}`> extends Trick<infer P> ? NextRouter<P> : never
}

export const isNestedRoute = <T extends Route['pathname']>(
  router: NextRouter<any>,
  prefix: T,
): router is RouterByPrefix[T] => router.pathname.startsWith(prefix)
