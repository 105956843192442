import { useCallback, useEffect, useRef, useState } from 'react'
import { useMountedState } from 'react-use'

export type UseOpenStateProps = {
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export type UseOpenState = [open: boolean, setOpen: (open: boolean) => void]

export const useOpenState = ({ open, onOpenChange }: UseOpenStateProps = {}): UseOpenState => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState<boolean>()
  const isMounted = useMountedState()

  const latestControlledOpen = useLatestDefined(open)

  const effectiveOpen = open ?? uncontrolledOpen ?? latestControlledOpen ?? false
  const uncontrolled = open == null

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!isMounted()) return
      if (uncontrolled) {
        setUncontrolledOpen(open)
      }
      onOpenChange?.(open)
    },
    [isMounted, uncontrolled, onOpenChange],
  )

  useEffect(() => {
    if (!uncontrolled) {
      setUncontrolledOpen(undefined)
    }
  }, [uncontrolled])

  return [effectiveOpen, handleOpenChange]
}

const useLatestDefined = <T>(value: T | undefined): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    if (value != null) {
      ref.current = value
    }
  }, [value])
  return value ?? ref.current
}
