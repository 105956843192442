import { ApolloLink } from '@apollo/client/core'
import { ErrorLink } from '@apollo/client/link/error'

export const errorLink = new ErrorLink(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions?.['expected']) {
        return
      }
      // eslint-disable-next-line no-console
      console.error(`[GraphQL error]: Message: ${message}, Location:`, locations, 'Path:', path)
    })
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.error('[Network error]:', networkError)
  }
})

// To debug GraphQL operations under the hood, add this to the `ApolloLink.from` array below
export const loggerLink = new ApolloLink((operation, forward) => {
  const startTime = new Date().getTime()

  return forward(operation).map((result) => {
    const [firstDefinition] = operation.query.definitions
    const operationType = firstDefinition?.kind === 'OperationDefinition' ? firstDefinition.operation : ''
    const ellapsed = new Date().getTime() - startTime

    // eslint-disable-next-line no-console
    console.log(`${operationType} ${operation.operationName} (in ${ellapsed} ms)`, operation, result)
    return result
  })
})
