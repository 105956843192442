import { Suspender } from '@mm/company-ui'
import React, { useContext } from 'react'
import { ActiveUserDocument, DraftUserProfileDataFragment } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'

export type ActiveUserData = DraftUserProfileDataFragment

const context = React.createContext<ActiveUserData | null>(null)

export const ActiveUserProvider: React.FC = ({ children }) => {
  const { data, loading } = useQuery(ActiveUserDocument, {
    fetchPolicy: 'cache-first',
  })

  const user = data?.me ?? null
  if (user == null && loading) {
    return <Suspender />
  }

  return <context.Provider value={user}>{children}</context.Provider>
}

export const useActiveUser = (): ActiveUserData | null => {
  return useContext(context)
}

export const useActiveUserRequired = (): ActiveUserData => {
  const user = useActiveUser()
  if (user == null) {
    throw new Error('Not authenticated')
  }
  return user
}
