import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Capability } from './Capability'

// TODO: Maybe combine with Commands?
export type Capabilities = {
  parent: Capabilities | null
  capabilities: Record<Capability<any, any>, (...args: any[]) => any>
  registerCapability: <A extends any[], T>(capability: Capability<A, T>, cb: (...args: A) => T) => () => void
}

export const CapabilitiesContext = createContext<Capabilities | null>(null)

type CapabilitiesProviderProps = {
  children?: React.ReactNode
  isolated?: boolean
}

export const CapabilitiesProvider = ({ children, isolated }: CapabilitiesProviderProps) => {
  const parent = useContext(CapabilitiesContext)
  const [capabilities, setCapabilities] = useState<Capabilities['capabilities']>({})

  const registerCapability = useCallback<Capabilities['registerCapability']>((capability, cb) => {
    setCapabilities((current) => {
      if (current[capability] != null) {
        // not throwing an error will result in subtle bugs
        // see https://github.com/Mochary-Method/mochary-method/pull/865#discussion_r1185181172
        throw new Error(`Capability ${capability} already registered`)
      }
      return {
        ...current,
        [capability]: cb,
      }
    })

    return () => {
      setCapabilities(({ [capability]: _, ...rest }) => rest)
    }
  }, [])

  const value = useMemo(
    () => ({
      parent: isolated ? null : parent,
      capabilities,
      registerCapability,
    }),
    [parent, capabilities, registerCapability, isolated],
  )

  return <CapabilitiesContext.Provider value={value}>{children}</CapabilitiesContext.Provider>
}

export const useCapabilities = () => {
  const ctx = useContext(CapabilitiesContext)

  const execute = useCallback(
    <A extends any[], T>(capability: Capability<A, T>, ...args: A) => {
      for (let node = ctx; node != null; node = node.parent) {
        const cb = node.capabilities[capability] as ((...args: A) => T) | undefined
        if (cb != null) {
          return cb(...args)
        }
      }
      throw new Error('capability not registered')
    },
    [ctx],
  )
  const isRegistered = useCallback(
    (capability: Capability<any, any>) => {
      for (let node = ctx; node != null; node = node.parent) {
        if (node.capabilities[capability] != null) {
          return true
        }
      }
      return false
    },
    [ctx],
  )

  return { isRegistered, execute }
}

/**
 * cb parameter MUST be memoized, otherwise an infinite loop will occur
 */
export function useRegisterCapability<A extends any[], T>(
  capability: Capability<A, T>,
  cb: (...args: A) => T,
  skip?: boolean,
) {
  const ctx = useContext(CapabilitiesContext)
  const registerCapability = ctx?.registerCapability

  useEffect(() => {
    if (skip || registerCapability == null) return
    return registerCapability(capability, cb)
  }, [capability, cb, skip, registerCapability])
}
