import { FieldPolicy } from '@apollo/client/cache'
import { relayStylePagination as baseRelayStylePagination } from '@apollo/client/utilities'

type KeyArgs = FieldPolicy['keyArgs']

/**
 * Specifying keyArgs disables @connection directive
 * (see https://www.apollographql.com/docs/react/caching/advanced-topics/#the-connection-directive
 * and https://www.apollographql.com/docs/react/pagination/key-args/#the-connection-directive)
 *
 * This higher order function makes your keyArgs respect the @connection directive key value specified by the query.
 */
const enhanceKeyArgsWithConnectionDirective =
  (keyArgs?: KeyArgs): KeyArgs =>
  (args, context) => {
    const specifier = typeof keyArgs === 'function' ? keyArgs(args, context) : keyArgs
    if (typeof specifier === 'string') {
      const connectionDirective = context.field?.directives?.find(({ name }) => name.value === 'connection')
      const connectionKey = connectionDirective?.arguments?.find(({ name }) => name.value === 'key')
      if (connectionKey == null) {
        return specifier
      }
      if (connectionKey.value.kind !== 'StringValue') {
        throw new Error('@connection key must be a string literal')
      }
      return `${connectionKey.value.value}:${specifier}`
    } else {
      return [...(specifier || []), '@connection', ['key']]
    }
  }

export const relayStylePagination = (keyArgsOverride?: KeyArgs): FieldPolicy => {
  // relayStylePagination merge function transforms nulls to empty pages which
  // leads to unexpected behaviour when page data is selected with fragments
  //
  // the issue is that the empty page object relayStylePagination provides do not contain __typename field
  // which in turn makes apollo return empty objects for the connection objects when they are selected with fragments
  // since fragments semantics are "return these fields as long as the type of the things is X" instead of
  // just "return these fields" when no fragment is used. And since these empty pages do not contain any type,
  // apollo chooses to return an empty object.
  const { merge, keyArgs, ...rest } = baseRelayStylePagination(keyArgsOverride)
  return {
    ...rest,
    keyArgs: enhanceKeyArgsWithConnectionDirective(keyArgs),
    merge:
      typeof merge === 'function'
        ? (existing, incoming, options) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            if (incoming == null) return existing ?? incoming
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            return merge(existing, incoming, options)
          }
        : merge,
  }
}
