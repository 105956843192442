import { Error as GraphQLError } from '../gen/graphql/graphql'
import introspectionResult from '../gen/graphql/introspection-result.json'

const { possibleTypes } = introspectionResult

export function isGraphQLError(node: unknown): node is Partial<GraphQLError> {
  if (typeof node !== 'object' || node == null || !('__typename' in node)) return false
  const __typename = node['__typename']
  return typeof __typename === 'string' && possibleTypes.Error.includes(__typename)
}
