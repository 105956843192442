import { posthog, Properties } from 'posthog-js'

/* For naming events, use the "Object Action" framework with all capitals:
 * https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/ */

type ObjectType =
  | 'Action'
  | 'Comment'
  | 'Company'
  | 'Decision'
  | 'Goal'
  | 'Meeting'
  | 'Meeting Update'
  | 'GoodThing'
  | 'Feedback'
  | 'Topic'
  | 'User'
  | 'Search'
  | 'Search Item'
  | 'Action Not Done'
  | 'Goal Not Done'
  | 'Editor Toolbar Item'
  | 'Shortcut'
  | 'Drawer'
  | 'More Menu'
  | 'App Version'
type ActionType =
  | 'Created'
  | 'Updated'
  | 'Deleted'
  | 'Selected'
  | 'Submitted'
  | 'Opened'
  | 'Closed'
  | 'Dismissed'
  | 'Navigation'

type EventName = `${ObjectType} ${ActionType}`

export function capture(eventName: EventName, properties: Properties) {
  posthog.capture(eventName, properties)
}

export function groupCompany(companyId: string, properties: { name: string }) {
  posthog.group('company', companyId, properties)
}
