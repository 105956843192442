import styled from '@emotion/styled'
import { css } from 'theme-ui'

export const ModalHeader = styled.div(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingY: 2,
    paddingLeft: 2.5,
    paddingRight: 1.5,
    '& > .close': {
      cursor: 'pointer',
    },
  }),
)
