import { NotFoundBanner } from '@mm/company-ui'
import React from 'react'

export function MaintenanceModal() {
  return (
    <div sx={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, background: 'white', zIndex: 99 }}>
      <NotFoundBanner
        title="CompanyOS is temporarily unavailable"
        description="We are currently working to resolve this issue. Please come back later."
      />
    </div>
  )
}
