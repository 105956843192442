import { GlobalTopicsSubscriptionDataFragment, GlobalTopicsSubscriptionDocument } from '../../../gen/graphql/documents'
import { useSubscription } from '../../apollo'
import { defineEventBusTopic, useEmitEventBusEvent } from '../../eventbus'

export type TopicsUpdatedEvent =
  | {
      type: 'deleted'
      id: string
    }
  | {
      type: 'upserted'
      topic: GlobalTopicsSubscriptionDataFragment
    }

export const TopicsUpdatedTopic = defineEventBusTopic<TopicsUpdatedEvent>('topicsUpdated')

export const GlobalTopicsSubscription = () => {
  const publish = useEmitEventBusEvent(TopicsUpdatedTopic)

  useSubscription(GlobalTopicsSubscriptionDocument, {
    onSubscriptionData: ({ subscriptionData: { data }, client }) => {
      const evictFromCache = (id: string) => {
        publish({ type: 'deleted', id })

        client.cache.evict({
          id: client.cache.identify({ __typename: 'Topic', id }),
        })
        client.cache.gc()
      }

      switch (data?.topicsUpdated?.__typename) {
        case 'TopicDeleted':
          evictFromCache(data.topicsUpdated.deletedTopicId)
          break
        case 'TopicAccessRevoked':
          evictFromCache(data.topicsUpdated.revokedTopicId)
          break
        case 'Topic':
          publish({ type: 'upserted', topic: data.topicsUpdated })
          break
      }
    },
  })

  return null
}
